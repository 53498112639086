import React, { useRef, useState, useEffect } from "react";
import SignaturePad from "react-signature-canvas";
import { toast } from "react-toastify";
import { BoardingModal } from "../comon/BoardingModal";

export default function SignComponent({ nextPage, title, sign, imgDoc, pdfDoc, fileName, addToDocName }) {
  const [isSignModalOpen, setIsSignModalOpen] = useState(false)

  // Function will execute on click of button
  const downloadDoc = () => {
    // using Java Script method to get PDF file
    const linkSource = pdfDoc
    let alink = document.createElement("a");
    alink.href = `data:image/pdf;base64,${linkSource}`;
    alink.download = fileName + `_${addToDocName}.pdf`;
    alink.click();
  };

  const sigCanvas = useRef({});
  /* a function that usew the canvas ref to clear the canvas 
  via a method given by react-signature-canvas*/
  const clear = () => sigCanvas.current.clear();

  const submit = () => {
    if (sign) {
      nextPage();
      setIsSignModalOpen(false)
      return;
    } else if (sigCanvas.current.isEmpty()) {
      toast.error("לא נוצרה חתימה", {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
        autoClose: 3000,
      });
      return;
    }
    const imageURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    nextPage(imageURL);
    setIsSignModalOpen(false)
  }

  return (
    <div className="sign-component">
      {/* <div className="frameText"> */}
      {isSignModalOpen &&
        <BoardingModal
          close={() => setIsSignModalOpen(false)}>
          <div className="sign-popup-title flex auto-center">
            <h1>{title}</h1>
          </div>
          {sign ? (
            <img
              className="signatureCanvas"
              style={{ minHeight: "300px" }}
              src={sign}
            />
          ) : (
            <SignaturePad
              ref={sigCanvas}
              canvasProps={{
                className: "signatureCanvas",
                style: { minHeight: "300px" },
              }}
            />
          )}
          <div className="signButtons flex auto-center">
            {!sign && (
              <button className="to-erase-btn" onClick={clear}>
                מחיקה
              </button>
            )}
            <button
              className="to-sign-btn"
              onClick={submit}
            >
              אישור
            </button>
          </div>
        </BoardingModal>}
      <div className="frame-sign-div-third-sign flex column auto-center">
        <div className="image-container">
          <img
            className="pdf-img"
            title="pdfCanvas"
            src={
              `data:image/jpeg;base64,${imgDoc}`
            }
            alt="כרגע לא ניתן להציג מסמך זה"
          />
        </div>
        <button className="to-sign-btn"  onClick={() => setIsSignModalOpen(true)}>
          לחתימה
        </button>
        <button className="download-sign-btn" onClick={downloadDoc}>
          להורדת המסמך
        </button>
      </div>
    </div>
  );
}
