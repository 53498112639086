import Axios from "axios";
import { ENV_DEV, ENV_PRODUCTION, ENV_SATGE, getEnv } from "./external.service";

// @PRODUCTION CHANGE before production (in production swich to "https://admin.taxon.co.il/") in stage swich to "http://app-stage.taxon.co.il/"

 const BASE_URL = "https://admin.taxon.co.il/";
//  const BASE_URL = "https://app-stage.taxon.co.il/";
// const BASE_URL = "http://localhost/my-taxon/my-taxon-api/";
// const BASE_URL = "https://my-taxon.localhost/";
// const BASE_URL = _getBaseUrl();

var axios = Axios.create({
  withCredentials: false,
});

export const httpService = {
  get(endpoint, data) {
    return ajax(endpoint, "GET", data);
  },
  post(endpoint, data, timeout = 0) {
    return ajax(endpoint, "POST", data, timeout);
  },
  put(endpoint, data) {

    return ajax(endpoint, "PUT", data);
  },
  delete(endpoint, data) {
    return ajax(endpoint, "DELETE", data);
  },
  patch(endpoint, data) {
    return ajax(endpoint, "PATCH", data);
  },
  async blob(endpoint, data) {
    const res = await axios({
    url: `${BASE_URL}${endpoint}`,
    method : 'POST',
    data,
    responseType: 'blob'
  });
    return res.data;
  }

};

async function ajax(endpoint, method = "GET", data = null, timeout, hearders) {
  try {
    const res = await axios({
      url: `${BASE_URL}${endpoint}`,
      method,
      data,
      timeout,
      params: method === "GET" ? data : null,
      hearders
    });
    return res.data;
  } catch (err) {
    // console.log(
    //   `Had Issues ${method}ing to the backend, endpoint: ${endpoint}, with data:`,
    //   data
    // );
    // return err;
    if (err.response && err.response.status === 401) {
      // Depends on routing startegy - hash or history
      // window.location.assign('/#/login')
      // window.location.assign('/login')
    }
    throw err;
  }
}

function _getBaseUrl() {
  switch (getEnv()) {
    case ENV_DEV:
    // return 'http://localhost/my-taxon/my-taxon-api/';
    case ENV_SATGE:
      return 'http://app-stage.taxon.co.il/';
    case ENV_PRODUCTION:
    // return 'https://admin.taxon.co.il/';
    default:
      return 'https://admin.taxon.co.il/';
  }
}