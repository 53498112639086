import React from 'react'
import { useEffect } from 'react';
import { useAccordion } from '../../hooks/useAccordion';
import { useEffectUpdate } from '../../hooks/useEffectUpdate';

export const Accordion = ({ children, onCollapsed, autoCollapsed = true, disableToggel, autoCollapsedDependency = [], contentHeightResizeDependecy = [] }) => {
    const [accoContentRef, isCollapsed, toggelCollapse, contentHeight, setIsCollapsed, setHeight] = useAccordion(autoCollapsed, contentHeightResizeDependecy);

    useEffectUpdate(() => {
        if (onCollapsed)
            onCollapsed(isCollapsed)
    }, [isCollapsed])
    useEffectUpdate(() => {
        setIsCollapsed(autoCollapsed)
    }, [...autoCollapsedDependency])

    useEffectUpdate(() => {
        // the setTimeout is because the accordion has transition time of 0.07s 
        // TODO: find better way to handle this
        setTimeout(() => {
            setHeight()
        }, 70)

    }, [...contentHeightResizeDependecy])

    return (<>
        <div className='accordin-toggel' onClick={disableToggel ? () => { return; } : toggelCollapse}>
            {children.summary}
        </div >
        <div className="accordion" style={{ maxHeight: contentHeight }}>
            <div ref={accoContentRef}>
                {children.details}
            </div>
        </div>
    </>
    )
}