import React, { useState, useEffect } from "react";
import Head from "../../comon/header";
import ProgressBar from "../../comon/ProgressBar";
import { IntroPersonalSurvey } from "./IntroPersonalSurvey";
import { quests } from "../../../data/quests";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updatePresonalQuiz } from "../../../store/actions/accountActions";
import { Quest } from "./Quest";

export const PersonalSurvey = ({
  startIdx,
  info = {},
  connectedUser,
  createdAt,
  isViewMode,
  availableProducts
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [questNo, setQuestNo] = useState(-1);

  useEffect(() => {
    // start from the same quest the user stoped
    if (startIdx) setQuestNo(startIdx);
  }, []);

  const movePage = (dir) => {
    if (questNo + dir >= quests.length) {
      navigate("/dashboard/criteria");
    } else {
      setQuestNo((prevQuestNo) => prevQuestNo + dir);
    }
  };

  const sendAns = (questKey, ans) => {
    dispatch(updatePresonalQuiz(questKey, ans));
    movePage(1)
  };

  // ansVal - use for marking user's ans if he already answered the quest
  // If the user answered the quest? ansVal = answer val alse ansVal = undefined
  const ansVal = info[quests[questNo]?.name]?.isSelected !== undefined ? info[quests[questNo]?.name]?.isSelected : undefined;
  const lables = quests.map(() => '')

  // const years = Object.keys(info.metaData || {})
  const years = Object.keys(availableProducts || {})
  const activeYears = years.reduce((acc, year) => {
    if (availableProducts[year].users.length) acc = [...acc, year]
    return acc
  }, [])

  // returns the range of years the user can get our service in string ex: 2021-2016
  const getTime = () => {
    const today = new Date();
    let year = today.getFullYear();

    //checks if the user register after 15.11 of this year 
    if (new Date(`${today.getFullYear()}-11-15`) <= today) {
      return `${year - 5}-${year - 1}`
    }

    // check if today is after 1.3
    if (today.getMonth() >= 2) return `${year - 6}-${year - 1}`


    return `${year - 6}-${year - 2}`
  }

  const titleDates = () => {
    if (activeYears.length && activeYears.length < 4) {
      //build string like that:
      //2016, 2017 ו2018
      var str = activeYears[0];
      for (let i = 1; i < activeYears.length; i++) {
        if (i === activeYears.length - 1) {
          str += ` ו-${activeYears[i]}`
        } else {
          str += `, ${activeYears[i]}`
        }
      }

      return str;
    }
    //else returns string like that 2021-2017
    return getTime()
  }

  return (
    <section className="personal-survey">
      {questNo < 0 && (
        <IntroPersonalSurvey
          startSurvey={setQuestNo}
          userName={`${connectedUser.firstName}`}
          createdAt={createdAt}
          years={activeYears}
          isViewMode={isViewMode}
        />
      )}
      {questNo >= 0 && (
        <>
          <section className=" personal-survey-layout">
            {<div className="quest-emphasize">לידיעתך: השאלון נוגע לשנים {titleDates()}</div>}
            <br />
          </section>
          <ProgressBar stepNo={questNo} stepsLength={quests.length} lables={lables} />
          <section className=" personal-survey-layout">
            <Quest
              quest={quests[questNo]}
              questNo={questNo}
              movePage={movePage}
              sendAns={sendAns}
              ansVal={ansVal}
              years={activeYears}
              isViewMode={isViewMode}
            />
          </section>
        </>
      )}
    </section>
  );
};
