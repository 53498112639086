import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import {
  UserCart,
  Dashboard,
  DashboardNav,
  DashboardHeader,
  Criteria,
} from "../components/userDashboard";
import { OpenMenuContext } from "../context/OpenMenuContext";
import { PersonalSurvey } from "../components/userDashboard/personalSurvey/PersonalSurvey";
import { quests } from "../data/quests";
import { UserDetails } from "../components/userDashboard/PersonalDetails/UserDetails";
import { AppFooter } from "../components/comon/AppFooter";
import { useIdleTimer } from "../hooks/useIdleTimer";
import { useEffect } from "react";
import { setIsShowWorningLogout } from "../store/reducers/siteReducer";
import { Zugi } from "../components/comon/Zugi";
import { Login } from "./Login";
import { authService } from "../services/auth.service";
import { Products } from "../components/userDashboard/product/Products";
import { CreditCard } from "../components/userDashboard/cc/CreditCard";
import ErrorPage from "./ErrorPage";
import { ArchiveDoc } from "../components/userDashboard/archive/ArchiveDoc";
import { Signatures } from "./Signatures";
import { ENV_DEV, getEnv } from "../services/external.service";
export const UserDashboard = () => {
  const idleTime = 1000 * 60 * 5
  const isIdle = useIdleTimer(idleTime)
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false);
  const value = useMemo(() => ({ isOpen, setIsOpen }), [isOpen]);
  const loggedinAccount = useSelector((state) => state.accountModule);
  const authenticated = useMemo(() => loggedinAccount.authenticated, [loggedinAccount.authenticated])

  // @PRODUCTION CHANGE before production (uncomment in production)

  useEffect(() => {
    let pingInterval;
    if (!isIdle) {
      pingInterval = setInterval(() => {
        authService.ping()
      }, idleTime)
    } else {
      dispatch(setIsShowWorningLogout(true))
    }
    return () => {
      clearInterval(pingInterval);
    }
  }, [isIdle])






  // const loggedinAccount = useSelector((state) => state.accountModule);


  //TODO: move to redux

  const HomeLocation = window.startLocation;
  window.startLocation = null;

  if (!loggedinAccount.accountId) return <Login />;

  const questIdx = quests.findIndex((quest) => !loggedinAccount.info[quest.name]);
  const { info, users, viewOnly, availableProducts, personalFiles } = loggedinAccount;
  const connectedUser = users[0]
  const accountTofes106 = info.tofes106.data.docs

  // It's view mode if metadata has no keys
  const isViewMode = viewOnly
  // complete all characterization_confirmed 
  const isCompleteCrit = !!info.characterization_confirmed.isSelected

  return (

    <OpenMenuContext.Provider value={value}>
      <section className="user-dashboard flex">
        <DashboardNav users={users} info={info} />
        <section className="content">
          <DashboardHeader />
          <section className="page">
            <section className={`screen ${!isOpen && "hidden"}`}></section>
            <Routes>
              <Route path="/*" element={<ErrorPage></ErrorPage>} ></Route>
              {/*TODO: check the json for the rout  */}

              {/* <Route path="/" exact element={HomeLocation == null
                ? <Navigate to="/dashboard/products" />
                : <Navigate to="/dashboard/criteria" />} >
              </Route> */}
              <Route path="/" exact element={
                <Navigate to="/dashboard/archive" />} >
              </Route>

              <Route path="/criteria" element=
                {questIdx !== - 1
                  ? (<PersonalSurvey
                    startIdx={questIdx}
                    connectedUser={connectedUser}
                    info={info}
                    availableProducts={availableProducts}
                    createdAt={loggedinAccount.createdAt}
                    isViewMode={isViewMode}
                  />)
                  : (<Criteria info={info}
                    connectedUser={connectedUser}
                    partner={users[1]}
                    isViewMode={isViewMode}
                    availableProducts={availableProducts}
                    personalFiles={personalFiles}
                  />)} />

              <Route path="/products" element={
                (viewOnly && !isCompleteCrit) || (loggedinAccount.poa.shaam.isPending)
                  ? <Dashboard loggedinAccount={loggedinAccount} />
                  : <Products availableProducts={availableProducts}
                    accountUsers={users}
                    isViewMode={!info.characterization_confirmed.isSelected}
                    isCompleteCrit={isCompleteCrit}
                    accountTofes106={accountTofes106}
                  />

              }
              />
              <Route path="/cart" element={<UserCart loggedinAccount={loggedinAccount} />} />
              <Route path="/userDetails" element={<UserDetails loggedinAccount={loggedinAccount} isViewMode={true} />}>
                <Route path=":data" element={<UserDetails loggedinAccount={loggedinAccount} />} />
              </Route>
              <Route path="/signatures" element={<Signatures />} />
              <Route path="/archive" element={<ArchiveDoc users={users} availableProducts={availableProducts} />} />
              {/* <Route path="/completion" element={<CreditCard users={loggedinAccount.users} />} /> */}
            </Routes>
          </section>
          <Zugi />
          <AppFooter />
        </section>
      </section>
    </OpenMenuContext.Provider>
  );
};