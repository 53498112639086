import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DeleteIcon, DocIcon, CircledPlus, CircledMinus } from "../../../assets/icons";
import { AppartmentRentFields } from "./AppartmentRentFields";
import { ChildrenFields } from "./ChildrenFields";
import { UploadBox } from "./UploadBox";
import { ConfirmModal } from "../../comon/ConfirmModal";
import { toast } from "react-toastify";
import { MiscFields } from "./MiscFields";
import { AlertModal } from "../../comon/AlertModal";
import { addCrit, addDocToInfo, cancelCrit, clientProductIntent, getDocUrl, removeDocFromInfo, submitCriteria } from "../../../store/actions/accountActions";
import { accountService } from "../../../services/account.service";
import { MainFields } from "./MainFields";
import { MsgCrit } from "./MsgCrit";
import { useEffectUpdate } from "../../../hooks/useEffectUpdate";
import { useAccordion } from "../../../hooks/useAccordion";
import { NOT_RELEVANT, RELEVANT_AND_HAS_DOC, RELEVANT } from '../../../services/account.service'
import { SecondaryFields } from "./SecondaryFields";
import { SpousalSupportFields } from "./SpousalSupportFields";
import { BeneficiarySettlementFields } from "./BeneficiarySettlementFields";
import { utilService } from "../../../services/util.service";
const SYSTEM_BLOCK = 3

export const CriteriaDetails = ({ criteriaData, onClose, cirtData, criteriaKey, isViewMode, metaData, connectedUser, personalFiles,
  partner, cirtMetaData, validateCrit }) => {
  const dispatch = useDispatch();
  const { title, details, fields, description, docUrl, fieldDesc, fieldDesc_UBZ } = criteriaData;
  const [formData, setFormData] = useState({ docs: [] });
  const [docView, setDocView] = useState(null)
  const [removeWarning, setRemoveWarning] = useState(null)
  const [removeChildWarning, setRemoveChildWarning] = useState(null)
  const [removeCritWarning, setRemoveCritWarning] = useState(null)
  const [removeYearWarning, setRemoveYearWarning] = useState(null)
  const [popupModal, setPopupModal] = useState(null)

  const [userListDocsRef, isUserListDocsCollapsed, toggelUserCollapse, userListDocsHeight] = useAccordion();
  const [accoContentRef, isCollapsed, toggelCollapse, contentHeight] = useAccordion();
  const userFullName = connectedUser.firstName + " " + connectedUser.lastName
  const pratnerFullName = partner?.firstName + " " + partner?.lastName
  const isMarried = connectedUser.maritalStatus === "married"
  const isMobile = window.innerWidth < 650
  const userListIcon = isUserListDocsCollapsed ? <CircledPlus /> : <CircledMinus />
  const partnerListIcon = isCollapsed ? <CircledPlus /> : <CircledMinus />

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (cirtData) setFormData(cirtData);
  }, [])

  useEffectUpdate(() => {
    if (cirtData) setFormData(prevForm => ({ ...prevForm, docs: cirtData.docs }));
  }, [cirtData?.docs])


  const addFile = async (file) => {
    if (criteriaKey === 'spousalSupport') {
      file.fieldName = "spousalSupportContract"
      file.docName = "הסכם גירושין"

    }
    dispatch(addDocToInfo(criteriaKey, file))
  }

  /**
 * 
 * @param {*} onSubmit 
 * @param {*} body 
 * @param {*} cancelText 
 * @param {*} submitText 
 */
  const openWarningModal = ({ onSubmit, body, cancelText, submitText }) => {
    setPopupModal(() => ({ onSubmit, body, cancelText, submitText }))
  }

  const openDeleteWarning = (idx, docId) => {
    const removeFile = () => () => {
      dispatch(removeDocFromInfo(docId, criteriaKey, idx))
    }
    setRemoveWarning(removeFile)
  }

  const openRemoveYearWarning = (productId, year) => {
    const giveUpYear = () => () => {
      dispatch(clientProductIntent(productId, year))
    }
    setRemoveYearWarning(giveUpYear)
  }

  const openRemoveCritWarning = () => {
    const removeFile = () => async () => {
      // console.log("removeFile");
      // await dispatch(cancelCrit(criteriaKey))
      // onClose()
    }
    setRemoveCritWarning(removeFile)
  }

  const cancelCriteria = async () => {
    if (criteriaKey === 'children') {
      const deletedChildren = formData.deletedChildren || []
      await dispatch(submitCriteria(criteriaKey, [...formData.children, ...deletedChildren]))
    }
    if (formData.docs?.length) {
      await openRemoveCritWarning()
    } else {
      await dispatch(cancelCrit(criteriaKey))
      onClose()
    }
  }

  const addCriteria = async () => {
    await dispatch(addCrit(criteriaKey))
    onClose()
  }

  const submit = async () => {
    if (isViewMode) {
      if (criteriaKey === 'tofes106' || criteriaKey === 'BTL' || criteriaKey === 'bankAccount' || criteriaKey === 'misc') return onClose()

      await addCriteria()
      return

    } else {
      if (criteriaKey == 'misc' || criteriaKey == 'appartmentRent' || validateCrit(criteriaKey, formData)) {
        switch (criteriaKey) {
          case "children":
            const deletedChildren = formData.deletedChildren || []
            await dispatch(submitCriteria(criteriaKey, [...formData.children, ...deletedChildren]))
            break;
          case "appartmentRent":
            if (Object.keys(formData.years).length) {
              await dispatch(submitCriteria(criteriaKey, formData.years))
            }
            break;
          case "bankAccount":
          case "tofes106":
          case "BTL":
          case "misc":
            onClose()
            break;
          default:
            // return onClose()
            await addCriteria()
            break;
        }
        onClose()
      } else {
        if (criteriaKey === "children") {
          toast.error("חסרים פרטים", {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
          });
          throw "not good"
        } else {
          onClose()
          // toast.error("לא העלית מסמך מתאים, לכן לא ניתן ללחוץ \"אישור\".  לסגירת החלון, נא ללחוץ על ה-X", {
          //   position: toast.POSITION.TOP_CENTER,
          //   theme: "colored",
          // });
          // // throw "not good"
          // throw 'no files'
        }
      }
    }

  }



  const closeRemoveWarning = () => {
    setRemoveWarning(null)
  }

  const openDocView = async ({ docName, docId, extension }) => {
    try {
      const { file, mimeType } = await dispatch(getDocUrl(docId))
      if (extension === "pdf") {
        utilService.donwloadBase64(file, docName, mimeType);
      } else {
        const url = `data:${mimeType};base64,${file}`
        setDocView({ url, docName })
      }
    } catch (error) {
      toast.error('משהו השתבש...', {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored"
      })
    }
  }

  const closeDocView = () => {
    setDocView(null)
  }

  const removeChild = (child, idx) => {
    if (child.id) {
      if (!formData.deletedChildren) {
        setFormData(prevForm => ({
          ...prevForm, deletedChildren: []
        }))
      }
      child.deleted = true
      setFormData(prevForm => ({
        ...prevForm, deletedChildren: [...prevForm.deletedChildren, child]
      }))
    }
    setFormData(prevForm => ({
      ...prevForm,
      children: prevForm.children.filter((child, index) => idx !== index)
    }))

  }

  const openRemoveWarning = (child, idx) => {
    const remove = () => {
      removeChild(child, idx)
    }
    setRemoveChildWarning({ remove, childName: child.name })
  }

  // build the yearMet looks like it :
  //   [
  //     {
  //         "userId1": 2,
  //         "userId2": 3,
  //         "year": "2020",
  //         "yearId": 5
  //     },
  //     {
  //         "userId1": 1,
  //         "userId2": 3,
  //         "year": "2017",
  //         "yearId": 2
  //     }
  // ]
  let yearsMat = Object.keys(metaData).reduce((acc, year) => {
    // if (!metaData[year].users.length ) return acc
    if (!metaData[year].users.length || !metaData[year].isPurchasable || metaData[year].proStatus.id !== 1) return acc

    const meta = {
      //if the user id not found in the users initial it as SYSTEM_BLOCK(3)
      [connectedUser.userId]: metaData[year].users.includes(connectedUser.userId) ? cirtMetaData?.[year][connectedUser.userId] : SYSTEM_BLOCK,
      [partner?.userId]: metaData[year].users.includes(partner?.userId) ? cirtMetaData?.[year][partner?.userId] : SYSTEM_BLOCK,
      year,
      yearId: metaData[year].productId
    }

    if (criteriaKey === "appartmentRent") {
      meta.relevant = formData?.docs.some(doc => doc.year === year)
    }
    else if (criteriaKey !== "children" && criteriaKey !== "appartmentRent") {
      if (meta[connectedUser.userId] !== SYSTEM_BLOCK && meta[connectedUser.userId] !== NOT_RELEVANT) {
        // if this cirt is relevat if has docs initial RELEVANT_AND_HAS_DOC(2) otherwise RELEVANT(1)
        meta[connectedUser.userId] = formData?.docs.some(doc => doc.forUser === connectedUser.userId && doc.year === year)
          ? RELEVANT_AND_HAS_DOC : RELEVANT;
      }

      if (meta[partner?.userId] !== SYSTEM_BLOCK && meta[partner?.userId] !== NOT_RELEVANT) {
        meta[partner?.userId] = formData?.docs.some(doc => doc.forUser === partner?.userId && doc.year === year)
          ? RELEVANT_AND_HAS_DOC : RELEVANT;
      }

      if (criteriaKey === "beneficiarySettlement") {
        meta.proof = formData?.docs.some(doc => doc.fieldName === "beneficiarySettlementProof" && doc.year === year) ? RELEVANT_AND_HAS_DOC : RELEVANT;
      }
    }

    return [...acc, meta]
  }, []).sort((a, b) => b.year - a.year)


  const { userDocs, partnerDocs } = formData.docs
    ? formData?.docs?.reduce((acc, doc) => {
      if (doc.forUser === connectedUser.userId || !doc.forUser) {
        return ({ ...acc, userDocs: [...acc.userDocs, doc] })
      }
      return ({ ...acc, partnerDocs: [...acc.partnerDocs, doc] })
    }, { userDocs: [], partnerDocs: [] }) : { userDocs: [], partnerDocs: [] }


  const cancelable = (criteriaKey !== "bankAccount" && criteriaKey !== "BTL" && criteriaKey !== "tofes106" && criteriaKey !== "misc")

  if (popupModal) return <ConfirmModal onClose={() => setPopupModal(null)} onSubmit={popupModal.onSubmit}
    cancelText={popupModal.cancelText} submitText={popupModal.submitText} >
    {{
      body: <>
        {popupModal.body}
      </>
    }}
  </ConfirmModal>

  if (docView) return <AlertModal closeModal={closeDocView} title={docView.docName} >
    {{
      body: <div className="img-container" style={{ padding: "10px", overflowY: "auto", height: '65vh' }}>
        <img
          src={docView.url}
          alt="כרגע לא ניתן להציג מסמך זה"
        />
      </div>
    }}
  </AlertModal>

  if (removeWarning) return <ConfirmModal onClose={closeRemoveWarning} onSubmit={removeWarning} >
    {{
      body: <>
        <div >
          ברצונך למחוק את המסמך הזה?
        </div>
      </>
    }}
  </ConfirmModal>

  if (removeCritWarning) return <ConfirmModal cancelText='לא' submitText='כן'
    onClose={() => setRemoveCritWarning(null)} onSubmit={removeCritWarning} >
    {{
      body: <>
        <div>
          כדי למחוק את הקטגוריה עליך למחוק קודם את כל הקבצים שהעלת עבורה.
          <br />
          לאחר מכך עליך ללחוץ על הכפתור "כן".
        </div>
      </>
    }}
  </ConfirmModal>

  if (removeYearWarning) return <ConfirmModal cancelText='לא' submitText='כן'
    onClose={() => setRemoveYearWarning(null)} onSubmit={removeYearWarning} >
    {{
      body: <>
        <div >
          <p className="bold">מחיקת טופס 106 מונעת מאיתנו לבדוק את השנה הזו.</p>
          <br />
          {/* <p>לא ניתן לבצע בדיקת זכאות להחזר מס עבור שנה שבה אין לנו את כל מסמכי ה-106 שלך.</p>
          <br /> */}
          <p>
            לא ניתן לבצע בדיקת זכאות להחזר מס עבור שנה שבה אין לנו את כל מסמכי ה-106 שלך. באפשרותך לדלג על שנות מס בבדיקה, אך לא מומלץ לעשות זאת: ייתכן שקיים חוב בחלק מהשנים, כך שהבדיקה תציג תמונה חלקית בלבד. וגם אם יוחלט לוותר על בדיקת חלק מהשנים, רשות המיסים עלולה לבקש ממך להגיש אותן. לכן, אנו ממליצים לעשות מאמץ לאתר את מסמכי 106 החסרים, ולבדוק את כל השנים הרלוונטיות.          </p>
          <br />
          <p>
            <b>לידיעתך: </b>
            גם אם החלטת לוותר בשלב זה על בדיקת שנה ספציפית, אפשר בכל עת ללחוץ על טפסי 106 החסרים של אותה שנה, להעלות אותם, ולהעביר גם אותה לבדיקת זכאות.
            אם החלטת לוותר על שנה ספציפית ולבדוק אותה בשלב מאוחר יותר, ואחרי שביצענו עבורך בדיקת זכאות לשנים אחרות, מחירה של בדיקת אותה שנה יעמוד על 50 ש"ח ליחיד או 100 ש"ח לזוג נשוי, בנוסף למחיר השירות הרגיל.
          </p>
        </div>
      </>
    }}
  </ConfirmModal>

  if (removeChildWarning) {
    return <ConfirmModal onClose={() => setRemoveChildWarning(null)} onSubmit={removeChildWarning.remove}
      cancelText='לא' submitText='כן'
    >
      {{
        body: <>
          <div>
            למחוק את {removeChildWarning.childName} מרשימת הילדים?
          </div>
        </>
      }}
    </ConfirmModal>
  }

  return <section className="criteria-details">
    <ConfirmModal cancelable={cancelable}
      onSubmit={submit}
      onCancel={cancelCriteria}
      onClose={onClose}
      cancelText={"אין לי"}
      autoClose={false}
    >
      {{
        title: <>
          <DeleteIcon className="x-icon pointer" onClick={onClose} />
          <div className="criteria-modal-title flex space-between">
            <div>
              <h2>{title}</h2>
              {!!details && <span>{details}</span>}
            </div>
            {docUrl && <a href={docUrl} rel="noopener noreferrer" target="_blank">
              <button className={'add-doc'}>למסמך לדוגמה</button>
            </a>}
          </div>
        </>,
        body: <>
          {isViewMode &&
            < div className="description">
              {description}
            </div>
          }

          < div className="criteria-details-content">
            {!isViewMode &&
              <>
                {fields.map((field) => (
                  <div className="inputs" key={field}>
                    <DynamicCmp
                      key={field}
                      type={field}
                      addFile={addFile}
                      openRemoveWarning={openRemoveWarning}
                      openDeleteWarning={openDeleteWarning}
                      openRemoveYearWarning={openRemoveYearWarning}
                      openWarningModal={openWarningModal}
                      form={formData}
                      setForm={setFormData}
                      criteriaKey={criteriaKey}
                      cirtMetaData={cirtMetaData}
                      years={yearsMat}
                      msg={fieldDesc}
                      UBZ={fieldDesc_UBZ}
                      metaData={metaData}
                      connectedUser={connectedUser}
                      personalFiles={personalFiles}
                      partner={partner}
                    />
                  </div>
                ))}

                {(criteriaKey !== 'children' && fields[0] !== 'secondaryFields') && <>
                  <div className="bold item-perview">המסמכים שעלו למערכת:</div>
                  {fields[0] !== 'mainFields' || !isMarried
                    ? <div className="item-list">
                      {formData.docs?.length ? <>
                        {formData.docs?.map((data, idx) => (
                          <div className="item-perview flex align-center" key={idx}>
                            <div
                              className="flex ellipsis align-center"
                              onClick={() => openDocView(data)}>
                              <DocIcon style={{ flex: "0 0 auto" }} />
                              {!!data?.docName &&
                                <span className="bold ellipsis"
                                >
                                  {data?.docName}</span>}
                            </div>

                            <button
                              className="delete-btn flex auto-center"
                              onClick={() => openDeleteWarning(idx, data.docId)}
                            >
                              <DeleteIcon className="delete-icon" />
                              <span className="bold">הסר</span>
                            </button>
                          </div>
                        ))}</>
                        : <div className="item-perview flex align-center bold ellipsis ">
                          אין קבצים
                          <DocIcon style={{ opacity: "0", margin: "0.4px 0" }} />
                        </div>
                      }
                    </div>

                    : <div className=" docs-display">
                      <div className="item-list ">
                        <div className="item-perview bold flex align-center gap-5" onClick={toggelUserCollapse}>
                          {isMobile && <span className="flex align-center">
                            {userListIcon}
                          </span>}
                          <span>
                            {userFullName}
                          </span>
                        </div>
                        <div className="accordion" style={{ maxHeight: isMobile ? userListDocsHeight : 'initial' }}>
                          <div ref={userListDocsRef}>
                            {userDocs.length ?
                              <> {userDocs?.map((doc, idx) => (
                                <div className="item-perview flex align-center" key={doc.docId}>
                                  <div
                                    className="flex ellipsis align-center"
                                    onClick={() => openDocView(doc)}>
                                    <DocIcon style={{ flex: "0 0 auto" }} />
                                    {!!doc?.docName &&
                                      <span className="bold ellipsis"
                                      >
                                        {doc?.docName}</span>}
                                  </div>

                                  <button
                                    className="delete-btn flex auto-center"
                                    onClick={() => openDeleteWarning(idx, doc.docId)}
                                  >
                                    <DeleteIcon className="delete-icon" />
                                    <span className="bold">מחיקה</span>
                                  </button>
                                </div>
                              ))}
                              </>
                              : <div className="item-perview flex align-center bold ellipsis ">
                                אין קבצים
                                <DocIcon style={{ opacity: "0", margin: "0.4px 0" }} />
                              </div>
                            }
                          </div></div>
                      </div>

                      {isMarried && <div className="item-list">
                        <div className="item-perview bold flex align-center gap-5" onClick={toggelCollapse}>
                          {isMobile && <span className="flex align-center">
                            {partnerListIcon}
                          </span>}
                          <span>
                            {pratnerFullName}
                          </span>
                        </div>

                        <div className="accordion" style={{ maxHeight: isMobile ? contentHeight : 'initial' }}>
                          <div ref={accoContentRef}>

                            {partnerDocs.length ? <>  {partnerDocs?.map((doc, idx) => (
                              <div className="item-perview flex align-center" key={doc.docId}>
                                <div
                                  className="flex ellipsis align-center"
                                  onClick={() => openDocView(doc)}>
                                  <DocIcon style={{ flex: "0 0 auto" }} />
                                  {!!doc?.docName &&
                                    <span className="bold ellipsis"
                                    >
                                      {doc?.docName}</span>}
                                </div>

                                <button
                                  className="delete-btn flex auto-center"
                                  onClick={() => openDeleteWarning(idx, doc.docId)}
                                >
                                  <DeleteIcon className="delete-icon" />
                                  <span className="bold">מחיקה</span>
                                </button>
                              </div>
                            ))}</>
                              : <div className="item-perview flex align-center bold ellipsis ">
                                אין קבצים
                                <DocIcon style={{ opacity: "0", margin: "0.4px 0" }} />
                              </div>
                            }


                          </div></div>
                      </div>}

                    </div>}
                </>
                }
              </>
            }

          </div>
        </>
      }}
    </ConfirmModal>
  </section >
};


const DynamicCmp = (props) => {
  switch (props.type) {
    case "uploadBox":
      const uploadBoxClassName = props.form.docs.length ? 'add-doc Uploaded' : "add-doc"
      const uploadBoxText = props.form.docs.length ? 'הוסף קובץ נוסף' : ''
      return <UploadBox {...props}
        className={uploadBoxClassName}
        txt={uploadBoxText}
      />;
    case "spousalSupportFields":
      return <SpousalSupportFields {...props} />;
    case "BeneficiarySettlementFields":
      return <BeneficiarySettlementFields {...props} />;
    case "mainFields":
      return <MainFields {...props} />;
    case "secondaryFields":
      return <SecondaryFields {...props} />;
    case "childrenFields":
      return <ChildrenFields {...props} />;
    case "appartmentRent":
      return <AppartmentRentFields {...props} />;
    case "miscFields":
      return <MiscFields {...props} />
    case "msgCrit":
      return <MsgCrit {...props} />
  }
};