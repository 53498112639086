import React, { useEffect } from 'react'
import { ZugiX } from '../../assets/svg-img/zugi';

export const BoardingModal = ({ children, close }) => {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);
    return (
        <div className='boarding-modal'>
            <div className='boarding-modal-content'>
                <ZugiX className="x-icon pointer" onClick={close} />
                {children}
            </div>
        </div>
    )
}
