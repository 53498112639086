import { storageService } from "./asyncStorage.service";
import { httpService } from "./http.service";
import { RELEVANT, NOT_RELEVANT, RELEVANT_AND_HAS_DOC } from "./account.service";
import { ENV_DEV, ENV_PRODUCTION, getEnv } from "./external.service";

const STORAGE_KEY_LOGGEDIN = "SKL";
const STORAGE_KEY = "account";

// if ((getEnv() === ENV_DEV)) {
//     _createAccounts();
// }



export const authService = {
    login,
    getLoggedinAccount,
    sendOtp,
    logout,
    ping,
    getLocalAccount
};

//AUTH
async function login(data) {
    try {
        let account = await httpService.post("login", data);
        // if (getEnv() === ENV_DEV) {
        //     // account = await devLogin();
        // }
        const accountToSave = {
            jwt: account.jwt,
            userId: account.users[0].userId
        }
        saveLocalAccount(accountToSave);
        return _createAccount(account)
    } catch (err) {
        throw err;
    }
}

async function sendOtp(data) {
    try {
        const code = await httpService.post("create-otp", data);
        return code;
    } catch (error) {
        throw error;
    }
}

function saveLocalAccount(userData) {
    sessionStorage.setItem(STORAGE_KEY_LOGGEDIN, JSON.stringify(userData));
    return userData;
}

async function getLoggedinAccount(params) {
    var userData = getLocalAccount() || params;
    if (!userData) return
    let account = await httpService.post("dashboard/get-user-data", userData);    
    const accountToSave = {
        jwt: account.jwt,
        userId: account.users[0].userId
    }
    saveLocalAccount(accountToSave);

    //TODO: move to redux

    // window.startLocation = account.home;
    window.startLocation = 'archive';


    return _createAccount(account);
}


async function logout() {
    sessionStorage.removeItem(STORAGE_KEY_LOGGEDIN)
}

async function ping() {
    var userData = getLocalAccount();
    const { jwt } = await httpService.post("dashboard/ping", userData);
    userData.jwt = jwt
    saveLocalAccount(userData);
}

function getLocalAccount() {
    return JSON.parse(sessionStorage.getItem(STORAGE_KEY_LOGGEDIN));
}

//PRIVATE METHODS
function _createInfo(bankData, info, metaData) {

    return {
        ...info,
        bankAccount: {
            data: {
                docs: bankData ?
                    bankData
                    : []
            }
        },
        misc: info.misc || {},
        children: info.children || {}
    }
}

function _createAccount({ accountId, maritalStatus, users, address, bank, confirmedCharacterization, info, ...rest }) {
    return {
        accountId: accountId || '',
        maritalStatus: maritalStatus || '',
        users: _getUsersForDisplay(users),
        address: address || '',
        bank: bank || '',
        confirmedCharacterization: confirmedCharacterization || '',
        info: _createInfo(bank?.data.docs, info || {}),
        ...rest
    }
}

function _getUsersForDisplay(users) {
    if (!users) return []
    const usersForDisplay = users.map(user => ({ ...user, birthdate: user.birthdateString }))
    return usersForDisplay
}

function _makeItMetaData(availableProducts) {
    const metaData = {}
    for (const year in availableProducts) {
        metaData[year] = availableProducts[year].users
    }

    return metaData
}


//FOR DEV ONLY
async function devLogin() {
    try {
        const accounts = await storageService.query(STORAGE_KEY);
        const account = accounts[1];
        // const account1 = _createAccount(account);
        // console.log(account1);
        return saveLocalAccount(account);
    } catch (err) {
        throw err;
    }
}

async function _createAccounts() {
    try {
        var accounts = await storageService.query(STORAGE_KEY);
        if (!accounts || !accounts.length) {
            accounts = [
                {
                    "accountId": "A101",
                    "jwt": '123',
                    "maritalStatus": "married",
                    "users": [
                        {
                            "userId": "u101",
                            "nationalId": "987654321",
                            "firstName": "ישראל",
                            "lastName": "ישראלי",
                            "imgUrl": "",
                            "gender": "male",
                            "birthdate": "1999-02-02",
                            "email": "tax@on.co.il",
                            "tel": "0500000000",
                            "license": "1234567",
                            "maritalStatus": "married"
                        },
                        {
                            "accountId": 885,
                            "userId": 946,
                            "nationalId": "88888888",
                            "firstName": "ספיר",
                            "lastName": "חחח",
                            "gender": "female",
                            "birthdate": {
                                "date": "1999-09-05 00:00:00.000000",
                                "timezone_type": 3,
                                "timezone": "UTC"
                            },
                            "mobile": "0505955574",
                            "email": "SAPIRHIKI@GMAIL.COM",
                            "emailId": 1132,
                            "maritalStatus": "married"
                        },
                        {
                            "id": "u102",
                            "nationalId": "12345987",
                            "firstName": "ישראלה",
                            "lastName": "ישראלי",
                            "imgUrl": "",
                            "gender": "female",
                            "birthdate": "1999-01-02",
                            "email": "tax@on.co.il",
                            "tel": "0500000000",
                            "license": "1234567"
                        }
                    ],
                    "address": {
                        "street": "",
                        "city": "",
                        "houseNo": ""
                    },
                    "bank": {
                        "bankName": "",
                        "accountNo": "",
                        "accountName": "",
                        "branchNo": "",
                        img: {
                            "docName": "file.name",
                            "file": "aseb64"
                        },
                    },
                    "info": {
                        metaData: {
                            2021: ['u101', 946],
                            2016: [946, 'u101']
                        },
                        "tofes106": {
                            "data": {
                                "docs": [
                                    {
                                        "docName": "file.name",
                                        "file": "base64",
                                        year: 2016,
                                        userId: 'u101'
                                    }
                                ]
                            },
                            metaData: {
                                2021: { 'u101': NOT_RELEVANT, 946: RELEVANT },
                                2016: { 946: RELEVANT_AND_HAS_DOC }
                            }
                        },
                        "BTL": {
                            "data": {
                                "docs": [
                                    {
                                        "docName": "file.name",
                                        "file": "base64"
                                    }
                                ]
                            }
                        },
                        "bankAccount": {
                            "data": {
                                "docs": [
                                    {
                                        "docName": "file.name",
                                        "file": "base64"
                                    }
                                ]
                            }
                        },
                        "children": {
                            "isSelected": "1",
                            "data": {
                                "children": [
                                    {
                                        "name": "מיני",
                                        "brithdate": "2020-05-09",
                                        "gender": "female",
                                        "ownership": "",
                                        "isSingleParent": true,
                                        "isAccountPossession": true
                                    }
                                ]
                            }
                        },
                        "privateInsurance": {
                            "isSelected": "0",
                            "data": {
                                "docs": [
                                    {
                                        "docName": "file.name",
                                        "file": "base64"
                                    },
                                    {
                                        "docName": "file.name",
                                        "file": "base64"
                                    }
                                ]
                            }
                        },
                        "providentFund": {
                            isSelected: "0",
                        },
                        "diploma": {
                            isSelected: "0",
                        },
                        "capitalMarket": {
                            isSelected: "0",
                        },
                        "withdrawal": {
                            isSelected: "0",
                        },
                        "donations": {
                            isSelected: "0",
                        },
                        "gambling": {
                            isSelected: "0",
                        },
                        "militaryService": {
                            isSelected: "0",
                        },
                        "spousalSupport": {
                            isSelected: "0",
                        },
                        "newImmigrants": {
                            isSelected: "1",
                        },
                        "beneficiarySettlement": {
                            isSelected: "0",
                        },
                        "appartmentRent": {
                            "isSelected": "0",
                            "data": {
                                "docs": [
                                    {
                                        "docName": "file.name",
                                        "file": "base64"
                                    },
                                    {
                                        "docName": "file.name",
                                        "file": "base64"
                                    }
                                ],
                                "years": {
                                    "2017": 50000,
                                    "2018": 20000
                                }
                            }
                        },
                        "disabled": {
                            isSelected: "0",
                        },
                        "disabledChild": {
                            isSelected: "0",
                        },
                        "institutionSpouse": {
                            isSelected: "0",
                        },
                        "misc": {
                            data: {
                                docs: []
                            }
                        }
                    },
                    "products": [
                        {
                            "id": "p122",
                            "year": 2022, // TODO TBD
                            "description": "חסרים טפסים/חתימה",
                            "isProcured": true,
                            "isRelevant": true, //needed for rendering chevron|button|none in ProcuctPreview
                            "issues": false, // TODO TBD
                            "price": 300,
                            "isArchive": false
                        }
                    ],
                    //Power of attorney
                    "POA": {
                        "shaam": {
                            "userId1": "status"
                        },
                        "btl": {
                            "userId1": "status",
                            "userId2": "status"
                        }
                    },
                    "cart": [],
                    "confirmedCharacterization": false,
                    "isActive": true
                },
                {
                    "info": {
                        "children": {
                            "isSelected": 1,
                            "data": {
                                "children": [
                                    {
                                        "id": 10,
                                        "ownership": "",
                                        "name": "עידו האלוף",
                                        "birthdate": "1999-03-03",
                                        "gender": "male",
                                        "isSingleParent": 0,
                                        "isAccountPossession": 0
                                    },
                                    {
                                        "id": 72,
                                        "ownership": "947",
                                        "name": "אחד",
                                        "birthdate": "1999-05-04",
                                        "gender": "male",
                                        "isSingleParent": 0,
                                        "isAccountPossession": 1
                                    },
                                    {
                                        "id": 73,
                                        "ownership": "",
                                        "name": "שתיים",
                                        "birthdate": "1999-02-02",
                                        "gender": "female",
                                        "isSingleParent": 0,
                                        "isAccountPossession": 0
                                    },
                                    {
                                        "id": 81,
                                        "ownership": "",
                                        "name": "שלוש",
                                        "birthdate": "1999-03-03",
                                        "gender": "male",
                                        "isSingleParent": 0,
                                        "isAccountPossession": 0
                                    }
                                ]
                            }
                        },
                        tofes106: {
                            "data": {
                                "docs": [{
                                    "docId": 42,
                                    "extension": "pdf",
                                    "docName": "אלביט",
                                    forUser: 946,
                                    year: '2016',
                                    uploadedByAccount: true
                                },
                                {
                                    "docId": 43,
                                    "extension": "pdf",
                                    "docName": "מבטח סימון",
                                    forUser: 946,
                                    year: '2016',
                                    uploadedByAccount: false
                                },
                                {
                                    "docId": 45,
                                    "extension": "png",
                                    "docName": "אינטל",
                                    forUser: 947,
                                    year: '2016',
                                    uploadedByAccount: false
                                },
                                {
                                    "docId": null,
                                    "extension": "png",
                                    "docName": "טקסאון",
                                    forUser: 947,
                                    year: '2016',
                                    uploadedByAccount: false
                                },
                                {
                                    "docId": 46,
                                    "extension": "png",
                                    "docName": "כלל",
                                    forUser: 947,
                                    year: '2021',
                                    uploadedByAccount: false
                                },
                                {
                                    "docId": null,
                                    "extension": "png",
                                    "docName": "הוט",
                                    forUser: 947,
                                    year: '2021',
                                    uploadedByAccount: false
                                },
                                ]
                            }
                        },
                        BTL: {
                            "data": {
                                "docs": [
                                ]
                            }
                        },
                        "Marketing_question": {
                            "isSelected": 1,
                            "data": {
                                "docs": []
                            }
                        },
                        "privateInsurance": {
                            "isSelected": 0,
                            "data": {
                                "docs": [
                                    {
                                        "docId": 42,
                                        "extension": "pdf",
                                        "docName": "privateInsurance.pdf"
                                    },
                                    {
                                        "docId": 43,
                                        "extension": "pdf",
                                        "docName": "privateInsurance.pdf"
                                    },
                                    {
                                        "docId": 45,
                                        "extension": "png",
                                        "docName": "logo192.png"
                                    },
                                    {
                                        "docId": 46,
                                        "extension": "png",
                                        "docName": "logo180.png"
                                    }
                                ]
                            }
                        },
                        "providentFund": {
                            "isSelected": 1,
                            "data": {
                                "docs": [
                                    {
                                        "docId": 105,
                                        "extension": "pdf",
                                        "docName": "privateInsurance.pdf",
                                        "forUserId": 946
                                    },
                                    {
                                        "docId": 106,
                                        "extension": "pdf",
                                        "docName": "privateInsurance1.pdf",
                                        "forUserId": 947
                                    }
                                ]
                            },
                            metaData: {
                                2021: { 947: RELEVANT, 946: NOT_RELEVANT },
                                2016: { 947: RELEVANT, 946: RELEVANT }
                            }
                        },
                        "diploma": {
                            "isSelected": 0,
                            "data": {
                                "docs": []
                            }
                        },
                        "capitalMarket": {
                            "isSelected": 0,
                            "data": {
                                "docs": []
                            }
                        },
                        "withdrawal": {
                            "isSelected": 0,
                            "data": {
                                "docs": []
                            }
                        },
                        "donations": {
                            "isSelected": 0,
                            "data": {
                                "docs": []
                            }
                        },
                        "gambling": {
                            "isSelected": 0,
                            "data": {
                                "docs": []
                            }
                        },
                        "militaryService": {
                            "isSelected": 0,
                            "data": {
                                "docs": []
                            }
                        },
                        "spousalSupport": {
                            "isSelected": 0,
                            "data": {
                                "docs": []
                            }
                        },
                        "newImmigrants": {
                            "isSelected": 0,
                            "data": {
                                "docs": []
                            }
                        },
                        "beneficiarySettlement": {
                            "isSelected": 0,
                            "data": {
                                "docs": []
                            }
                        },
                        "appartmentRent": {
                            "isSelected": 1,
                            "data": {
                                "docs": [
                                    {
                                        "docId": 92,
                                        "extension": "jpeg",
                                        "docName": "lisence.jfif"
                                    }
                                ],
                                "years": {
                                    "2016": "18888",
                                    "2022": "19999"
                                }
                            }
                        },
                        "disabled": {
                            "isSelected": 0,
                            "data": {
                                "docs": []
                            }
                        },
                        "disabledChild": {
                            "isSelected": 0,
                            "data": {
                                "docs": []
                            }
                        },
                        "institutionSpouse": {
                            "isSelected": 0,
                            "data": {
                                "docs": []
                            }
                        },
                        "characterization_confirmed": {
                            "isSelected": false,
                            "data": {
                                "docs": []
                            }
                        },
                        "misc": {
                            "isSelected": 1,
                            "data": {
                                "docs": []
                            }
                        }
                    },
                    "availableProducts": {
                    },
                    // "availableProducts": {
                    //     // "2016": {
                    //     //     "name": "2016",
                    //     //     "id": 1,
                    //     //     "purchaseDate": "2022-12-15 00:00:00",
                    //     //     "status": true,//boolean
                    //     //     "users": [946, 947],
                    //     //     "productProgressStatus": null,
                    //     //     "type": null,
                    //     //     "submitdate": null,
                    //     //     "pash": null,
                    //     //     "mustSubmit": null,
                    //     //     "reportStatus": null,

                    //     // },
                    //     "2021": {
                    //         "name": "2021",
                    //         "id": 16,
                    //         "purchaseDate": null,
                    //         "status": true,
                    //         "users": [947],
                    //         "productProgressStatus": null,
                    //         "type": null,
                    //         "submitdate": null,
                    //         "pash": null,
                    //         "mustSubmit": null,
                    //         "reportStatus": null,
                    //     },
                    //     "2017": {
                    //         "name": "2017",
                    //         "id": 12,
                    //         "purchaseDate": null,
                    //         "status": false,
                    //         "users": [],
                    //         "productProgressStatus": null,
                    //         "type": null,
                    //         "submitdate": null,
                    //         "pash": null,
                    //         "mustSubmit": null,
                    //         "reportStatus": null,
                    //     }
                    // },
                    "accountId": 885,
                    "maritalStatus": "married",
                    "createdAt": "2022-11-18",
                    "users": [
                        {
                            "accountId": 885,
                            "userId": 946,
                            "nationalId": "88888888",
                            "firstName": "ספיר",
                            "lastName": "חחח",
                            "gender": "female",
                            "birthdate": {
                                "date": "1999-09-05 00:00:00.000000",
                                "timezone_type": 3,
                                "timezone": "UTC"
                            },
                            "birthdateString": "1999-09-05",
                            "mobile": "0505955574",
                            "email": "SAPIRHIKI@GMAIL.COM",
                            "emailId": 1132,
                            "maritalStatus": "married"
                        },
                        {
                            "accountId": 885,
                            "userId": 947,
                            "nationalId": "77777777",
                            "firstName": "מישהו",
                            "lastName": "חחחח",
                            "gender": null,
                            "birthdate": null,
                            "birthdateString": null,
                            "mobile": "0666666666",
                            "email": "SV@F.M",
                            "emailId": 1133,
                            "maritalStatus": "married",
                            "regLink": "https://my.taxon.co.il/partner?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2NzEwODcwNTEsImlzcyI6Imh0dHBzOi8vYWRtaW4udGF4b24uY28uaWwiLCJuYmYiOjE2NzEwODcwNTEsImV4cCI6MTY3MzY3OTA1MSwidXNlck5hbWUiOjk0NywiaXAiOiIyYTBkOjZmYzI6NjQ2MDozYzAwOmQ1OTk6ZDk5MDpiZmNkOmUwY2UiLCJhcHByb3ZlZCI6ZmFsc2V9.CYgK6qM-jfekF1b0dL7nli9AgAfEPtfnVnAiH_LG5d9steH7wEox679apKhz-WeXQqmXNYcm9NxVgqgEob0T3Q&userId=947"
                        }
                    ],
                    "viewOnly": true,
                    "bank": {
                        "isSelected": 1,
                        "data": {
                            "docs": [
                                {
                                    "docId": 106,
                                    "extension": "pdf",
                                    "docName": "privateInsurance.pdf"
                                }
                            ]
                        }
                    },
                    cart: [{
                        "product_id": 16,
                        "name": "2021",
                        "added_to_cart": "2023-01-18 13:20:26",
                        price: 100
                    }],
                    "authenticated": true,
                    "jwt": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2NzEwODcwNTEsImlzcyI6Imh0dHBzOi8vYWRtaW4udGF4b24uY28uaWwiLCJuYmYiOjE2NzEwODcwNTEsImV4cCI6MTY3MTA4NzQxMSwidXNlck5hbWUiOjk0NiwiaXAiOiIyYTBkOjZmYzI6NjQ2MDozYzAwOmQ1OTk6ZDk5MDpiZmNkOmUwY2UiLCJhcHByb3ZlZCI6dHJ1ZX0.l7LUDipXJzvQ4pxgnObPV8v3GXs5nfZ9VXQqfTzpNQ8Lbr86QwZnZ_xgkfJvC_X6iCWteo9qPTA7vMo3jQzalw"
                }
            ];
            return storageService.postMany(STORAGE_KEY, accounts);
        } else return accounts;
    } catch (err) {
        console.log("accountService: _createAccounts had issue: " + err);
    }
}
