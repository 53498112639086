import React from "react";

export default function ProgressBar({ stepNo,stepsLength, lables }) {
  const progress =  (100 / (stepsLength - 1)) * stepNo;

  return (
    <section className="progress-bar flex auto-center">
      <div style={{ backgroundSize: `${progress}%` }} className="prog-bar">
        {lables.map((step, index) => {
          return (
            <div
              key={index}
              className="step-label flex column auto-center"
              style={{ right: `${(100 / (lables.length - 1)) * index - 1}%`}}
            >
              <div
                className="step-num flex auto-center"
                style={{
                  border:
                     index <= stepNo
                      ? "2px solid var(--main-color)"
                      : "2px solid var(--border-color)",
                }}
              >
                {index + 1}
              </div>
              <p>{step}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
}
