import React from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { login } from '../../store/actions/accountActions';
import { TextBox } from '../comon/TextBox';

export const MacForm = ({ userData }) => {
  const disptch = useDispatch()
  const navigate = useNavigate()
  const [otp, setOtp] = useState('')
  const [disable, setDisable] = useState(false)
  if (userData.method === 'sms' && "OTPCredential" in window) {

    const ac = new AbortController();
    setTimeout(() => {
      // abort after 5 minuts seconds
      ac.abort();
    }, 60 * 1000 * 5);

    // Request the OTP via get()
    navigator.credentials
      .get({
        otp: { transport: ["sms"] },
        signal: ac.signal,
      })
      .then((smsOtp) => {
        // When the OTP is received by the app client, enter it into the form
        // input and submit the form automatically
        setOtp(smsOtp.code);
        submit(ac,smsOtp.code);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const submit = async (ac, smsOtp) => {
    setDisable(true)
    try {
      const localOtp = smsOtp || otp
      await disptch(login({ ...userData, 'otp': localOtp }));
      if (ac.abort) {
        ac.abort();
      }
      navigate("/dashboard/");
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) {
        toast.error("קוד שגוי", {
          position: "top-center",
          theme: "colored",
          autoClose: 3000,
        });
      } else {
        toast.error("משהו השתבש", {
          position: "top-center",
          theme: "colored",
          autoClose: 3000,
        });
      }
    } finally {
      setDisable(false)
    }
  };

  return (
    <div className='mac-form'>
      <TextBox type={"text"} label={"מה הקוד שקיבלת?"} value={otp} onChange={({ target }) => setOtp(target.value)} autoFocus={true} autoComplete="one-time-code" inputMode="numeric" />
      <button className="" onClick={submit} disabled={disable}>
        כניסה לאיזור אישי
      </button>
    </div>
  )
}
