import React from 'react'
import { eventBus } from '../../../services/eventBus.service';
import { ProductTable } from './ProductTable';
import { Accordion } from '../../comon/Accordion';
import { CircledMinus, CircledPlus } from '../../../assets/icons';
import { useState } from 'react';
import { addToCart } from "../../../store/actions/accountActions";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ConfirmModal } from '../../comon/ConfirmModal';

const getServiceStr = (product, users) => {
    let mainUser = users.find(u => u.userId === product.users[0])
    mainUser = mainUser || users[0];

    const variant = product.productVariant === "דוח זוגי" ? `דוח זוגי` : `דוח אישי עבור ${mainUser.firstName}`
    if (!product.isPurchasable) {
        return product.discardedStatus
    }
    return `${variant}, ${product.isPurchasable ? product.serviceType : product.discardedStatus}`
}
// if you need to use in more cpms insert this func to utilService
const islFormat = (num) => {

    return new Intl.NumberFormat('he-IL', { style: 'currency', currency: 'ILS', maximumFractionDigits: 0 }).format(num)
}

const currentDate = new Date();

// Get the current year
const currentYear = currentDate.getFullYear();

// Subtract one year to get the previous year
const lastYear = currentYear - 1;
let modalWasShown = false;

export const Products = ({ availableProducts, accountUsers, isViewMode, accountTofes106 }) => {

    const modalInit = accountUsers[0].accountId < 14100 ? true : false;
    const [showNewPriceModal, setShowNewPriceModal] = useState(modalInit && !modalWasShown);


    const [isPositiveExpectedCollapsed, setIsPositiveExpectedCollapsed] = useState(false)
    const [isPendingProdCollapsed, setIsPendingProdCollapsed] = useState(false)
    const [isNegativeExpectedCollapsed, setIsNegativeExpectedCollapsed] = useState(true)
    const [isPurchasedProdCollapsed, setIsPurchasedProdCollapsed] = useState(false)

    //the value of the 4 states below doesn't matter the contentHeightResizeDependecy need to feel any change
    const [positiveExpectedResize, setPositiveExpectedResize] = useState(false)
    const [pendingProdResize, setPendingProdResize] = useState(false)
    const [ngativeExpectedResize, setNgativeExpectedResize] = useState(false)
    const [purchasedProdResize, setPurchasedProdResize] = useState(false)
    const [isNeededPopUpPurcuseWarning, setPopUpPurcuseWarning] = useState(false)
    const isMarried = accountUsers.length > 1

    const dispatch = useDispatch();
    const navigate = useNavigate();



    // console.log(availableProducts[2022].expectedReturn);
    const expectedReturncalculate = (availableProducts) => {
        // let sumOfExpectedReturn;
        // for (i = availableProducts[0]; availableProducts.length; i++) {
        //    if(availableProducts[i].expectedReturn !==null &&availableProducts[i].expectedReturn) {
        //     sumOfExpectedReturn += availableProducts[i].expectedReturn
        //    }

        // }return sumOfExpectedReturn

        if (!availableProducts || typeof availableProducts !== 'object') {
            return null; // Return null or an alternative component/rendering if the object is undefined, null, or not an object
        }

        // Extract 'expectedReturn' values from the object
        const expectedReturns = Object.values(availableProducts)
            .filter(obj => obj && typeof obj === 'object') // Filter out invalid objects
            .map(obj => obj.expectedReturn)
            .filter(value => typeof value === 'number' && value > 0);

        // Calculate the sum of 'expectedReturn' values
        const sumOfExpectedReturn = expectedReturns.reduce((acc, value) => acc + value, 0);



        return sumOfExpectedReturn;
    }


    const yearsOfExpectedReturn = (availableProducts) => {
        //    const yearsWithExpectedReturn = availableProducts.map(obj => obj.expectedReturn ) 
        const yearsWithExpectedReturn = Object.values(availableProducts)
            .filter(item => item.expectedReturn > 0 && item.expectedReturn !== null) // Filter out only the expectedReturns objects that arenot null and bigger then 0
            // .filter(item=> item.expectedReturn && typeof obj === 'number') 
            .map(item => item.productName);

        // console.log(Object.values(availableProducts).filter(obj => typeof obj !== null));
        return yearsWithExpectedReturn.toString().replaceAll(",", " , ")

    }

    const allProductsNotPurchasable = Object.values(availableProducts).every(product => !product.isPurchasable);


    const pendingProdTitles = [
        { txt: 'סוג שירות', zugiKey: "Service" },
        { txt: 'המידע שהתקבל', zugiKey: "information_status" },
        { txt: 'הכנסה חייבת במס', zugiKey: "current_status" },
        { txt: 'המס ששולם בפועל', zugiKey: "potential" },
        { txt: 'סטטוס בדיקה', zugiKey: "request_status" },
    ]
    const checkedProdTitles = [
        { txt: 'סוג השירות', zugiKey: "Service" },
        { txt: 'הכנסה חייבת במס', zugiKey: "current_status" },
        { txt: 'מס ששולם בפועל', zugiKey: "potential" },
        { txt: 'המס שהיה צריך לשלם', zugiKey: "calculated_tax" },
        { txt: 'ריבית והצמדה', zugiKey: "interest" },
        { txt: 'החזר המס הצפוי', zugiKey: "refund_expected" },
        { txt: 'הגשת בקשה להחזר מס' },
    ]
    const negativeProdTitles = [
        { txt: 'סוג השירות', zugiKey: "Service" },
        { txt: 'הכנסה חייבת במס', zugiKey: "current_status" },
        { txt: 'מס ששולם בפועל', zugiKey: "potential" },
        { txt: 'המס שהיה צריך לשלם', zugiKey: "calculated_tax" },
        { txt: 'ריבית והצמדה', zugiKey: "interest" },
        { txt: ' תשלום חסר', zugiKey: "refund_expected_negative" },
        { txt: 'הגשת דוח שנתי', zugiKey: "request_status_negative" },
    ]
    const purchasedProdTitles = [
        // { txt: 'סוג שירות', zugiKey: "Service" },
        { txt: '', },
        { txt: 'הכנסה חייבת במס', zugiKey: "current_status" },
        { txt: 'מס ששולם בפועל', zugiKey: "potential" },
        { txt: 'המס שהיה צריך לשלם', zugiKey: "calculated_tax" },
        { txt: 'ריבית והצמדה', zugiKey: "interest" },
        { txt: 'החזר המס הצפוי', zugiKey: "refund_expected" },
        { txt: 'סטטוס הטיפול', zugiKey: "bike" },
    ]
    // console.log("availableProducts :", availableProducts);

    let allProdPending = true;
    Object.keys(availableProducts).forEach((key) => {
        if (availableProducts[key].proStatus.id > 3) {
            allProdPending = false;
        }
    });
    // ghjkl;'lkjh'
    // divide the products in to 4 groups
    // has expectedReturn (undifined or null)
    // positive expectedReturn
    // negative expectedReturn
    // purchased
    const tablesData = Object.keys(availableProducts).reduce((acc, year) => {
        const product = availableProducts[year]
        let calculatedTax = product.calculatedTax
        const calculatatedisNull = product.calculatedTax
        // console.log(typeof product.calculatedTax, product.productName);

        if (typeof product.calculatedTax != "number") {
            const YearNumber = new Date().getFullYear() - product.productName
            // console.log(YearNumber);
            calculatedTax = product.expectedReturn * (1 - YearNumber * 0.05)
        }
        let ViewcalculatedTax = product.taxes - calculatedTax

        let rowData = {
            year: year,
            product: product,
            subtitleForMobile: '',
            data: {
                service: getServiceStr(product, accountUsers),
                income: islFormat(product.income),
                taxes: islFormat(product.taxes),
                calculatedTax: islFormat(ViewcalculatedTax),
                interest: islFormat(product.expectedReturn - calculatedTax),
                expectedReturn: islFormat(product.expectedReturn)
            },
        }



        // if(allProdPending){
        //     acc.pendingProd = [...acc.pendingProd,rowData];
        // }
        if (product.purchaseDate && !allProdPending) {
            rowData.subtitleForMobile = product.proStatus.status
            acc.purchased = [...acc.purchased, rowData]
        }
        else if (!product.isPurchasable && !allProdPending) {
            rowData.subtitleForMobile = product.discardedStatus
            rowData.data = {
                discardedStatus: product.discardedStatus
            }
            acc.negativeExpected = [...acc.negativeExpected, rowData]
        }
        else if ((!product.expectedReturn && product.expectedReturn !== 0) || allProdPending) {

            if (product.isPurchasable == false || (allProdPending && product.serviceType == null)) {
                // rowData.subtitleForMobile = `החזר מס צפוי: ${islFormat(product.expectedReturn)} `
                rowData.subtitleForMobile = product.discardedStatus
                delete rowData.data.income;
                delete rowData.data.income;
                delete rowData.data.taxes;
                delete rowData.data.calculatedTax;
                delete rowData.data.interest;
                delete rowData.data.expectedReturn;

            }
            else {
                rowData.subtitleForMobile = product.proStatus.status
                rowData.data = {
                    service: getServiceStr(product, accountUsers),
                    // hasInfo: accountTofes106.some(tofes => tofes.year === +year && !tofes.docId) ? "חלקי" : "מלא",
                    // hasInfo: product.gotData,
                    hasInfo: (
                        <span style={{ color: product.gotData !== "מלא" ? "red" : "inherit", fontWeight: product.gotData !== "מלא" ? "bold" : "normal" }}>
                            {product.gotData}
                        </span>
                    ),
                    income: islFormat(product.income),
                    taxes: islFormat(product.taxes),
                    status: product.proStatus.status
                }
            }
            acc.pendingProd = [...acc.pendingProd, rowData]
        }
        else if (product.expectedReturn <= 0) {
            rowData.subtitleForMobile = `החזר מס צפוי: ${islFormat(product.expectedReturn)} `
            acc.negativeExpected = [...acc.negativeExpected, rowData]
        } else {
            rowData.subtitleForMobile = `החזר מס צפוי: ${islFormat(product.expectedReturn)} `
            acc.positiveExpected = [...acc.positiveExpected, rowData]
        }
        return acc
    }, { pendingProd: [], purchased: [], negativeExpected: [], positiveExpected: [] })
    // sort the pendingProd by productName
    tablesData.pendingProd.sort((rowData1, rowData2) => rowData1.product.productName > rowData2.product.productName ? -1 : 1)
    // sort the positiveExpected by expectedReturn
    tablesData.positiveExpected.sort((rowData1, rowData2) => rowData1.product.expectedReturn > rowData2.product.expectedReturn ? -1 : 1)
    // sort the negativeExpected by expectedReturn
    tablesData.negativeExpected.sort((rowData1, rowData2) => rowData1.product.expectedReturn < rowData2.product.expectedReturn ? -1 : 1)
    // sort the purchased by date

    tablesData.purchased.sort((rowData1, rowData2) => {
        const date1 = new Date(rowData1.product.purchaseDate);
        const date2 = new Date(rowData2.product.purchaseDate);
        if (date2.getDate() === date1.getDate() && date2.getYear() === date1.getYear() && date2.getMonth() === date1.getMonth()) {
            return rowData1.product.expectedReturn > rowData2.product.expectedReturn
        }
        return (
            new Date(rowData1.product.purchaseDate) -
            new Date(rowData2.product.purchaseDate)
        );
    });


    //make the first purchased product be open with a proggres bar
    if (tablesData.purchased[0]) {
        tablesData.purchased[0].autoOpenProggresbar = true
    }

    const getNegativeExpectedTitle = (rowsData) => {
        let strs = []
        // chacks if has negative return

        if (rowsData.some(prod => prod.product.expectedReturn && prod.product.expectedReturn < 0)) {
            strs.push('שנים שבהן כנראה שילמת פחות מדי מס')
        }


        if (rowsData.filter(prod => prod?.product?.expectedReturn === 0).length !== 0) {
            strs.push('שנים שבהן אין זכאות להחזר מס')
        }

        // chacks if the product is not relevant
        if (rowsData.some(prod => prod.product.client_intent && !prod.product.purchaseDate && !prod.product.isPurchasable)) {
            strs.push('שנים לא רלוונטיות')
        }

        // chacks if the user give up on this year 
        if (rowsData.some(prod => !prod.product.client_intent)) {
            strs.push(isMarried ? 'שנים שהחלטתם לא לבדוק' : 'שנים שהחלטת לא לבדוק')
        }
        return strs.join(', ו/או ') + ':'
    }

    // console.log("tablesData :",tablesData);

    const addAllPositiveToCart = (ev) => {
        ev.stopPropagation()

        const allPositivePurchesblesId = tablesData.positiveExpected.reduce((acc, item) => {
            if (!item.product.added_to_cart) {
                acc.push(item.product.productId)
            } return acc;
        }, []);
        const allPositivePurchesblesNames = tablesData.positiveExpected.reduce((acc, item) => {
            if (!item.product.added_to_cart) {
                acc.push(item.product.productName)
            } return acc;
        }, []);


        navigate("/dashboard/cart");
        dispatch(addToCart(allPositivePurchesblesId, allPositivePurchesblesNames));

    };


    const getIntrTxt = () => {
        // console.log("Object.keys(availableProducts).length in getIntrTxt :", Object.keys(availableProducts)[5]);
        // console.log("Object.keys(availableProducts).length in getIntrTxt :", availableProducts);
        if (allProdPending && expectedReturncalculate(availableProducts) <= 0) return <>
            <p>
                בעמוד זה, ריכזנו את המידע שמשכנו עבורך מרשות המיסים והמוסד לביטוח לאומי, לפי שנים.
            </p>
            <br />
            <p>
                להסבר נוסף, אפשר ללחוץ על סימן השאלה במקום המתאים.
            </p>
            <br />
            {(Object.keys(availableProducts).length === 6) && allProductsNotPurchasable ? (
                <div>
                    {/* Content for when number of years is equal to 6 */}
                    <p>
                        <b>
                            מה קורה עכשיו?
                        </b>
                        <p>
                            מהמידע שמשכנו עולה כי מערכת Tax:on לא תוכל לצערנו לבדוק את זכאותך להחזרי מס, מהסיבות המופיעות בטבלה שלפניך.
                        </p>
                        <p>
                            נבדוק שוב בשנה הבאה! :)
                        </p>
                        <p>
                            לשירותך,
                        </p>
                        <p>
                            צוות Tax:on
                        </p>
                    </p>
                </div>
            ) : (
                (Object.keys(availableProducts).length < 6) && allProductsNotPurchasable && (
                    <div>
                        {/* Content for when number of years is less than 6 */}
                        <p>
                            <b>
                                מה קורה עכשיו?
                            </b>
                            <p>
                                מהמידע שמשכנו עולה כי מערכת Tax:on לא תוכל לצערנו לבדוק את זכאותך להחזרי מס, מהסיבות המופיעות בטבלה שלפניך.
                            </p>
                            <br />
                            <p>
                                <b>לידיעתך</b>: המידע לשנת המס {lastYear} עדיין לא זמין, לכן שנה זו טרם נבדקה. עד ה-31 באוגוסט נמשוך את המידע גם עבור שנת המס האחרונה, ונעדכן כי ניתן לבדוק גם אותה.
                            </p>
                        </p>
                    </div>
                )
            )}
        </>
        else if (!allProdPending && expectedReturncalculate(availableProducts) <= 0) return <>
            <p>
                מהבדיקה שביצענו עולה כי לא מגיע לך החזר מס עבור השנים שנבדקו.
            </p>
            <p>
                ננסה שוב בשנה הבאה 😊
            </p>
            <p>
                להסבר נוסף, אפשר ללחוץ על סימן השאלה במקום המתאים.
            </p>

        </>
        else if (!allProdPending && expectedReturncalculate(availableProducts) > 0) return <>
            <p>
                <b>
                    תוצאות הבדיקה :
                </b>
                <span >
                    &nbsp;  מגיע לך החזר מס עבור השנים    &nbsp; {yearsOfExpectedReturn(availableProducts)}
                </span>
            </p>
            <p>
                <b>
                    החזר מס כולל צפוי :
                </b>
                <b style={{ color: 'green' }}>
                    &nbsp; {islFormat(expectedReturncalculate(availableProducts))}
                </b>
            </p>
            {/* <p>
              <b>
                  עבור השנים :
              </b>
              <span>
              &nbsp; {yearsOfExpectedReturn(availableProducts)}
              </span>
          </p> */}
            <br />
            <p>
                להסבר נוסף, אפשר ללחוץ על סימן השאלה במקום המתאים
            </p>
            {/* <p>מערכת Tax:on כבר מטפלת בהחזר המס שלך, עבור השנים שבחרת להגיש. </p>
          <p>בטבלה שלפניך, אפשר להתעדכן בסטטוס של החזר המס בשנים שהגשנו עבורך, באמצעות לחיצה על השנה הרלוונטית. </p>
          <p >
              <b><B>לידיעתך</B>: </b>
              ניתן בכל שלב להגיש בקשות להחזר מס גם עבור יתר השנים שמופיעות כאן ופתוחות לבדיקה.
          </p> */}
        </>
    }
    const openZugi = (zugiKey) => {
        eventBus.setZugi({ textKey: zugiKey })
    }

    const accordionIcon = (isCollapsed) => isCollapsed ? <CircledPlus /> : <CircledMinus />


    return (
        <div>
            <div className='user-product'>
                <div className="instruction main-dashboard-layout">
                    <div className="title flex align-center ">
                        <h1>החזרי המס שלי</h1>
                    </div>
                    {getIntrTxt()}
                </div>
                <div className="main-dashboard-layout">
                    {!!tablesData.positiveExpected.length &&
                        <Accordion
                            contentHeightResizeDependecy={[positiveExpectedResize]}
                            autoCollapsed={false}
                            onCollapsed={(isCollapsed) => setIsPositiveExpectedCollapsed(isCollapsed)}>
                            {
                                {
                                    summary:
                                        <div className='flex align-center gap-5'>
                                            {accordionIcon(isPositiveExpectedCollapsed)}
                                            <p className='bold pointer'>
                                                שנים שעבורן מגיע לך החזר מס, לפי החישוב שלנו:</p>
                                        </div>,
                                    details: <>
                                        <br />
                                        <ProductTable openZugi={openZugi} tableTitles={checkedProdTitles} rowsData={tablesData.positiveExpected}
                                            onShowDetails={() => setPositiveExpectedResize(prev => !prev)} />
                                        <br />
                                        <div style={{ textAlign: "center" }}>
                                            <button className='submit-btn auto-center' style={{ alignItems: "center" }} onClick={addAllPositiveToCart}>
                                                לקבלת הכסף שלך
                                            </button>
                                        </div>
                                    </>
                                }
                            }
                        </Accordion>
                    }
                    <br />
                    {allProdPending && !!tablesData.pendingProd.length &&
                        <Accordion
                            contentHeightResizeDependecy={[pendingProdResize]}
                            autoCollapsed={false}
                            onCollapsed={(isCollapsed) => setIsPendingProdCollapsed(isCollapsed)} >
                            {
                                {
                                    summary:
                                        <div hidden={true} className='flex align-center gap-5'>
                                            {/* {console.log(tablesData.pendingProd)}   */}
                                        </div>,
                                    details: <>
                                        <br />
                                        <ProductTable openZugi={openZugi} tableTitles={pendingProdTitles} rowsData={tablesData.pendingProd}
                                            onShowDetails={() => setPendingProdResize(prev => !prev)} />
                                    </>
                                }
                            }
                        </Accordion>
                    }
                    <br />
                    {!allProdPending && !!tablesData.pendingProd.length &&
                        <Accordion
                            contentHeightResizeDependecy={[pendingProdResize]}
                            autoCollapsed={false}
                            onCollapsed={(isCollapsed) => setIsPendingProdCollapsed(isCollapsed)} >
                            {
                                {
                                    summary:
                                        <div className='flex align-center gap-5'>

                                            {accordionIcon(isPendingProdCollapsed)}
                                            <p className='bold pointer'>שנים לבדיקה:</p>

                                        </div>,
                                    details: <>
                                        <br />
                                        <ProductTable openZugi={openZugi} tableTitles={pendingProdTitles} rowsData={tablesData.pendingProd}
                                            onShowDetails={() => setPendingProdResize(prev => !prev)} />
                                    </>
                                }
                            }
                        </Accordion>
                    }
                    <br />
                    {!!tablesData.negativeExpected.length &&

                        <Accordion
                            contentHeightResizeDependecy={[isNegativeExpectedCollapsed]}
                            autoCollapsed={Object.keys(availableProducts).length === tablesData.negativeExpected.length ? false : true}
                            onCollapsed={(isCollapsed) => setIsNegativeExpectedCollapsed(isCollapsed)}>
                            {
                                {
                                    summary:
                                        <div className='flex align-center gap-5'>
                                            {/* {console.log("avalibleProduct in the xml :",availableProducts)}
                                            {console.log("tableData in the xml :",tablesData)}
                                            {console.log("tableData.negativeExpected.length in the xml :",tablesData.negativeExpected.length)}
                                            {console.log("(Object.keys(availableProducts).length in the xml :", (Object.keys(availableProducts).length))} */}
                                            {accordionIcon(isNegativeExpectedCollapsed)}
                                            <p className='bold pointer'>{getNegativeExpectedTitle(tablesData.negativeExpected)}</p>
                                        </div>,
                                    details: <>    <br />
                                        <ProductTable openZugi={openZugi} tableTitles={negativeProdTitles} rowsData={tablesData.negativeExpected} className="negative-expected"
                                            onShowDetails={() => setNgativeExpectedResize(prev => !prev)} />
                                    </>
                                }
                            }
                        </Accordion>
                    }
                    < br />
                    {!!tablesData.purchased.length &&
                        <Accordion
                            contentHeightResizeDependecy={[purchasedProdResize]}
                            autoCollapsed={false}
                            onCollapsed={(isCollapsed) => setIsPurchasedProdCollapsed(isCollapsed)}>
                            {
                                {
                                    summary:
                                        <div className='flex align-center gap-5'>
                                            {accordionIcon(isPurchasedProdCollapsed)}
                                            <p className='bold pointer'>שנים בטיפול:</p>
                                        </div>,

                                    details: <> <br />
                                        <ProductTable openZugi={openZugi} tableTitles={purchasedProdTitles} rowsData={tablesData.purchased}
                                            onShowDetails={() => setPurchasedProdResize(prev => !prev)} />
                                    </>
                                }
                            }
                        </Accordion>
                    }
                </div >


                {allProductsNotPurchasable ? null : (
                    isViewMode &&
                    <div className="main-dashboard-layout" style={{ marginTop: '0px' }}>

                        <b>
                            כעת, ניתן לבדוק את השנים שבהן המערכת יכולה לטפל.
                        </b>
                        {/* <p>
                            כעת, ניתן לבדוק את השנים שבהן המערכת יכולה לטפל.
                        </p>
                        <br />
                        <p>
                            עליך ללחוץ על כפתור "להמשך הבדיקה", שיעביר אותך לעמוד תנאי הזכאות שלך.
                        </p>
                        <p>
                            בעמוד תנאי הזכאות, נבקש ממך להעלות את כל המסמכים הנדרשים לקריטריונים שציינת בשאלון, עבור השנים הרלוונטיות בלבד.
                        </p>
                        <p>
                            לאחר מכן, ניתן יהיה לבצע בדיקת זכאות להחזר מס.
                        </p>*/}
                        <br />
                        <div className='flex auto-center'>
                            <a className='submit-btn' style={{ padding: "0.9em 4em" }} href='/dashboard/criteria' >
                                להמשך בדיקה
                            </a>
                        </div>
                        <br />
                        <br />
                        {/* <p>
                        <b>ודאגנו לך גם להחזר המס של 2022: </b>
                        <br />
                        בין החודשים אפריל ואוגוסט 2023, נקבל מהרשויות את המידע הרלוונטי להחזר המס שלך לשנת 2022.
                        <br />
                        מייד לאחר מכן, נוסיף את שנת 2022 לטבלה כאן, ונשלח לך הודעה כי ניתן לבדוק גם אותה.
                    </p> */}
                    </div>)}
            </div>
            {/* {showNewPriceModal &&
                <ConfirmModal cancelable={false} onClose={() => {
                    setShowNewPriceModal(false)
                    modalWasShown = true;
                }}>
                    {{
                        body: <>

                            היי {accountUsers[0].firstName},<br/>
                            מחירי השירות שלנו עודכנו ב-15.2.24, והם מופיעים בעמוד הבית שלנו.<br/>
                            מאחר שנרשמת למערכת לפני העדכון, אנו שומרים לך על הזכות להגיש בקשות להחזרי מס בתעריף הקודם, וזאת עד ליום ה', 15.8.24.<br/>
                            החל מ-16.8.24, מחירי השירות יותאמו לתעריף החדש.<br/>
                            הנה, עוד סיבה לדרוש ולקבל את הכסף שלך בהקדם😊<br/>
                            לשירותך,<br/>
                            צוות Tax:on
                        </>
                    }}
                </ConfirmModal>
            } */}
        </div >
    )
};