import React from 'react'
import { useDispatch } from 'react-redux'
import { CircledMinus, CircledPlus, Paperclip, XIcon } from '../../../assets/icons'
import { useAccordion } from '../../../hooks/useAccordion'
import { accountService } from '../../../services/account.service'
import { addDocToInfo } from '../../../store/actions/accountActions'
import { UploadBox } from './UploadBox'

export const SecondaryFields = ({ form, connectedUser, partner, msg, UBZ, criteriaKey, openDeleteWarning, openRemoveYearWarning, metaData
    // years,
}) => {
    const dispatch = useDispatch()
    const isMarried = connectedUser.maritalStatus === "married"
    const className = isMarried ? 'main-fields married' : 'main-fields'
    const users = isMarried ? [connectedUser, partner] : [connectedUser]

    let years = Object.keys(metaData).reduce((acc, year) => {
        if (!metaData[year].users.length || (metaData[year].client_intent && !metaData[year].isPurchasable || metaData[year].proStatus.id !== 1)) return acc
        const meta = {
            year,
            yearId: metaData[year].productId,
            isPurchasable: metaData[year].isPurchasable,
            users: metaData[year].users
        }
        // if (!metaData[year].users.length || !metaData[year].isPurchasable) return acc
        return [...acc, meta]
    }, []).sort((a, b) => b.year - a.year)

    const docs = years.reduce((acc, year) => {
        const usersYear = users.reduce((acc, user) => {
            // debugger
            const userYear = form.docs.filter(doc => {
                return doc.forUser === user.userId && doc.year == year.year
            })
            return { ...acc, [user.userId]: userYear }
        }, {})
        return { ...acc, [year.year]: usersYear }
    }, {})

    const missingDocs = form.docs.filter(doc => !doc.docId)
    const userUploads = form.docs.filter(doc => doc.uploadedByAccount)
    const userFullName = connectedUser.firstName
    const pratnerFullName = partner?.firstName

    const isMobile = window.innerWidth < 650
    const [descRef, isDescCollapsed, toggelDescCollapse, descHeight] = useAccordion();
    const descIcon = isDescCollapsed ? <CircledPlus /> : <CircledMinus />
    const noDocs = criteriaKey === 'tofes106' ? 'אין מעסיקים' : 'לא התקבלו גימלאות'

    const addFile = async (file, year, userId, docName) => {
        file.year = year
        file.forUser = userId
        file.docName = docName

        dispatch(addDocToInfo(criteriaKey, file))
    }

    const getLoadedDocs = (year, userId) => {
        return docs[year]?.[userId].filter(doc => doc.docId).map(doc => doc.docName).join(", ")
    }

    const desc = () => {
        if ((missingDocs.length && UBZ)) {
            if (criteriaKey === 'tofes106') {
                return <>
                    {UBZ}
                    <br />
                    <a
                        // href={`${window.location.origin}/1245`}
                        target='_blank'
                        className="underline pointer"
                        onClick={
                            () => loadUrl(0)
                        }
                    >
                        להפקת טופס 1245 של {userFullName}
                    </a>
                    {isMarried && <a
                        // href={`${window.location.origin}/1245`}
                        target='_blank'
                        className="underline pointer"
                        onClick={
                            () => loadUrl(1)
                        }
                    >
                        להפקת טופס 1245 של {pratnerFullName}
                    </a>}
                    <br />
                    <br />
                    <b>נתקלת בבעיה ולא ניתן להשיג טופס 106? </b>
                    אפשר ללחוץ על ה-X האדום, ו- Tax:on לא תבדוק את אותה שנה.
                </>
            }
            return UBZ
        }
        return msg;
    }

    const loadUrl = async (idx) => {
        const base = await accountService.get1245(idx)
        let alink = document.createElement("a");
        alink.href = `data:application/pdf;base64,${base}`;
        alink.download = "1245.pdf";
        alink.target = "_blank";
        alink.click();
        // return base
    }

    return (
        <div className='secondary-fields' >
            {isMobile && <div className='bold flex align-center gap-5 pointer'
                onClick={toggelDescCollapse}>
                <span className="flex align-center">
                    {descIcon}
                </span>
                <span>
                    מה צריך לעשות כאן?
                </span>
            </div>}
            <div className="accordion" style={{ maxHeight: isMobile ? descHeight : 'initial' }}>
                <div className='description' ref={descRef}>{desc()}</div>
            </div>

            <div className={className}>
                {isMarried && <div className="main-fields-title bold main-fields-row">
                    <div>שנה</div>
                    <div>{userFullName}</div>
                    <div>{pratnerFullName}</div>
                </div>}

                <div className="main-fields-body">
                    {!years.length && <div>אין שנים להצגה</div>}
                    {years.map(year =>
                        <div className="main-fields-row" key={year.year}>
                            <div className="year bold">{year.year}</div>
                            {users.map(user =>
                                <div className="actions" key={user.userId}>
                                    <div className="name">{user.firstName}</div>
                                    <div className={`${year.isPurchasable ? "" : 'gray'} employers`} >
                                        {year.users.find(uId => uId == user.userId)
                                            && !docs[year.year]?.[user.userId].length
                                            && <div className='secondary-fields-btn ellipsis' >{noDocs}</div>}
                                        {!!getLoadedDocs(year.year, user.userId).length &&
                                            <span className='secondary-fields-btn ellipsis sucsess mobile-only'>
                                                {getLoadedDocs(year.year, user.userId)}
                                            </span>}
                                        {docs[year.year]?.[user.userId].map(doc => doc.docId
                                            ? <span className='secondary-fields-btn sucsess not-in-mobile'
                                                key={`${doc.docName}_${year.year}`}>
                                                <span className='ellipsis'>{doc.docName}</span>
                                            </span>
                                            : <>
                                                <div className='relative flex flex-grow-1'
                                                    key={`${doc.docName}_${year.year}`}
                                                >
                                                    {year.isPurchasable && <button className='delete-btn'
                                                        onClick={() => openRemoveYearWarning(year.yearId, year.year)}>
                                                        <XIcon />
                                                    </button>}
                                                    <UploadBox className='secondary-fields-btn ellipsis failed pointer'
                                                        txt={doc.docName}
                                                        addFile={(file) => addFile(file, year.year, user.userId, doc.docName)}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>)}
                </div>
            </div>
            {!!userUploads.length && <><h2 className='doc-list-title'>המסמכים שעלו למערכת:</h2>
                <div className='doc-list'>
                    {userUploads.map((doc, idx) => <div className='relative' key={doc.id}>
                        <button className='delete-btn' onClick={() => openDeleteWarning(idx, doc.docId)}><XIcon /></button>
                        <DocPreview users={users} doc={doc} style={{ height: '20px', width: '16px' }} />
                    </div>)}
                </div></>}
        </div>
    )
}

const DocPreview = ({ users, doc }) => {
    let [currUser] = users.filter((user) => doc.forUser === user.userId)
    currUser = currUser || users[0];
    const fullName = `${currUser.firstName} ${currUser.lastName}`
    const { docName, year } = doc
    return (
        <div className='doc-preview flex align-center gap-5'>
            <div style={{ overflow: "hidden" }}>
                <div className='ellipsis'>{fullName}</div>
                <div className='ellipsis'>{docName}</div>
                <div className='ellipsis'>{year}</div>
            </div>
            <Paperclip width={'50px'} style={{ width: '30px', height: '30px', flexShrink: '0' }} />
        </div>
    )
}
