import { useEffect, useRef, useState } from "react";
/**
 * When you use it give the container css class "accordion"
 * and give the container inline style maxHeight: contentHeight style={{ maxHeight: contentHeight }}
 * const [accoContentRef, isCollapsed, toggelCollapse, contentHeight] = useAccordion(); 
 * 
 * @returns {object} {accoContainerRef - give the content container ref={accoContainerRef}
 * , accoContentRef - give the content ref={accoContentRef}
 * , isCollapsed - boolean
 * , toggelCollapse - toggel isCollapsed
 * , contentHeight - 0 or the content height in px - use it that way style={{maxHeight: contentHeight}} on the content container
 * }
 */
export const useAccordion = (startClose = true, contentHeightResizeDependecy = []) => {
  const [isCollapsed, setIsCollapsed] = useState(startClose);
  const [contentHeight, setContentHeight] = useState(null);
  const accoContentRef = useRef();
  // const accoContainerRef = useRef();
  var contentStyles;
  const setHeight = () => {
    if (!accoContentRef.current) return;
    if (!contentStyles)
    contentStyles = getComputedStyle(accoContentRef.current);
    
    // need calc the margin because offsetHeight not doing it
    // console.log(contentStyles.height);
    const margin =
    parseFloat(contentStyles.marginBottom) +
    parseFloat(contentStyles.marginTop);

    setContentHeight(accoContentRef.current.offsetHeight + margin);
  };

  useEffect(() => {
    setHeight();
    window.addEventListener("resize", setHeight);
    return () => {
      window.removeEventListener("resize", setHeight);
    };
  }, []);

  // useEffect(() => {
  //   console.log(contentHeightResizeDependecy);
  //   setHeight()
  // }, [...contentHeightResizeDependecy])

  const toggelCollapse = () => {
    setHeight();
    setIsCollapsed((prevIsCollapsed) => !prevIsCollapsed);
  };


  //the comment below is a magic we can't trust on..
  // useEffect(() => {
  //     accoContainerRef.current.style.transition = 'max-height 0.3s ease-out'
  //     accoContainerRef.current.style.overflow = 'hidden'
  //     accoContainerRef.current.style.zIndex = '-1'
  //     accoContainerRef.current.style.maxHeight = isCollapsed ? '0' : `${contentHeight}px`
  // }, [contentHeight, isCollapsed])
  return [
    // accoContainerRef,
    accoContentRef,
    isCollapsed,
    toggelCollapse,
    isCollapsed ? "0" : `${contentHeight}px`,
    setIsCollapsed,
    setHeight
  ];
};