import { boardingService } from "../../services/boarding.service";
import { setIsLoading } from "../reducers/siteReducer";

export function loadPartnerDetails() {
    return async (dispatch, getState) => {
        const searchParams = new URLSearchParams(window.location.search); 
        const params = {
            token: searchParams.get("token"),
            userId: searchParams.get("userId"),
        };
        try {
            if (params.token && params.userId) {
                const { partnerDetails, userDetails, token } = await boardingService.getAccountDetails(params);
                dispatch({ type: "LOAD_USER", partnerDetails, userDetails, token, userId: params.userId, });
            }
        } catch (err) {
            throw err
        }
    }
}

export function setSearchParms() {
    return async (dispatch) => {
        const searchParams = new URLSearchParams(window.location.search);
        let criteria = searchParams.get("criteria")
        if (criteria) {
            criteria = criteria.split(',')
        }
        try {
            dispatch({ type: "SET_SEARCH_PARMS", searchParams: searchParams.toString(), criteria });
        } catch (err) {
            throw err
        }
    }
}

export function setTerms(terms) {
    return async (dispatch, getState) => {
        dispatch({ type: "SET_TERMS", terms })
    }
}

export function loadLicenseDetails(licenseImg) {
    return async (dispatch, getState) => {
        dispatch(setIsLoading(true))
        try {
            const token = await getState().signUpModule.token
            dispatch({ type: "SET_LICENSE_DETAILS", licenseImg })
            // const licenseDetails = await boardingService.getLicenseDetails(licenseImg, token)
            // dispatch({ type: "SET_LICENSE_DETAILS", licenseDetails, licenseImg })
        } catch (err) {
            dispatch({ type: "SET_LICENSE_DETAILS", licenseImg })
            throw err
        } finally {
            dispatch(setIsLoading(false))
        }
    }
}

export function setDetails(details) {
    return async (dispatch, getState) => {
        try {

            const token = await getState().signUpModule.token
            const userId = await getState().signUpModule.userId
            details.firstName = details.firstName.trim()
            details.lastName = details.lastName.trim()
            details.token = token
            details.userId = userId
            const docs = await boardingService.SendUserDetails(details);
            dispatch({ type: "SET_DETAILS", details, docs })
        } catch (err) {
            throw err
        }
    }
}

export function setStatus(status) {
    return async (dispatch, getState) => {
        try {
            dispatch(setIsLoading(true))
            dispatch({ type: "SET_STATUS", status })
        } catch (err) {
            throw err
        } finally {
            dispatch(setIsLoading(false))
        }
    }
}

export function setSignature(signature) {
    return async (dispatch, getState) => {
        dispatch({ type: "SET_SIGNATURE", signature })
    }
}

export function setPartnerDetails(partnerDetails) {
    return async (dispatch, getState) => {
        dispatch({ type: "SET_PARTNER_DETAILS", partnerDetails })
    }
}

export function signUp() {
    return async (dispatch, getState) => {
        try {

            const userDetails = await getState().signUpModule
            const userData = JSON.parse(JSON.stringify(userDetails))
            delete userData.docs;
            const res = await boardingService.signUp(userData)
            dispatch({ type: "SET_PARTNER_LINK", partnerLink: res.partnerLink })
        } catch (err) {
            throw err
        }
    }
};

export function setLink(partnerLink) {
    return async (dispatch) => {
        dispatch({ type: "SET_PARTNER_LINK", partnerLink })
    }
}

export function setCcId(ccData) {
    return async (dispatch) => {
        dispatch(setIsLoading(true))
        try {
            const isValid = await boardingService.checkCC(ccData)
            if (isValid) {
                dispatch({ type: "SET_CC_ID", ccData: { id: ccData.id, isOwner: ccData.isOwner } })
            }
            return isValid
        } catch (err) {
            throw err;
        } finally {
            dispatch(setIsLoading(false))
        }
    }
}

export function setIsDup(userDetails) {
    return async (dispatch) => {
        dispatch(setIsLoading(true))
        try {
            const isValid = await boardingService.checkDupe(userDetails);
            return isValid 
        } catch (err) {
            throw err;
        } finally {
            dispatch(setIsLoading(false))
        }
    }
}