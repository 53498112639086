import {
  Blackboard,
  Card,
  Children,
  DepositBox,
  Diploma,
  Disabled,
  Donations,
  Home,
  HospitalBed,
  Keys,
  PrivateInsurance,
  Separated,
  Soldier,
  StockMarket,
  Suitcase,
  Withdrawal,
} from "../assets/svg-img";
import bankDoc from "../assets/docs/bankAccount.pdf"
import privateInsuranceDoc from "../assets/docs/privateInsurance.pdf"
import providentFundDoc from "../assets/docs/providentFund.pdf"
import withdrawalDoc from "../assets/docs/withdrawal.pdf"
import appartmentRentDoc from "../assets/docs/appartmentRent.pdf"
import donationsDoc from "../assets/docs/donations.pdf"
import beneficiarySettlementDoc from "../assets/docs/beneficiarySettlement.pdf"
import militaryServiceDoc from "../assets/docs/militaryService.pdf"
import spousalSupportDoc from "../assets/docs/spousalSupport.pdf"
import newImmigrantsDoc from "../assets/docs/newImmigrants.pdf"
import disabledDoc from "../assets/docs/disabled.pdf"
import disabledChildDoc from "../assets/docs/disabledChild.pdf"
import institutionSpouseDoc from "../assets/docs/institutionSpouse.pdf"
import diplomaDoc from "../assets/docs/diploma.pdf"
import capitalMarketDoc from "../assets/docs/capitalMarket.pdf"
import BTLDoc from "../assets/docs/BTL.pdf"
import tofes106tDoc from "../assets/docs/tofes106.pdf"
import { accountService } from "../services/account.service";


const loadUrl = async (idx) => {
  const base = await accountService.get1245(idx)
  let alink = document.createElement("a");
  alink.href = `data:application/pdf;base64,${base}`;
  alink.download = "1245.pdf";
  alink.target = "_blank";
  alink.click();
  // return base
}

// for the criteria details modal
// IMPORTANT: the key must have be the same name as the key in account's characterization/info
export const criteriaData = {
  tofes106: {
    title: "טפסי 106",
    details: "",
    description: <>
      <p>
        איזה כיף, לא צריך להעלות כלום!
      </p>
      <p>
        מערכת Tax:on תמשוך עבורך אוטומטית את כל המסמכים הרלוונטיים מרשות המיסים.
      </p>
      <p>
        אם מסיבה כלשהי לא נוכל למשוך את המסמכים, נעדכן ונאפשר לך להעלות אותם באופן עצמאי למערכת.
      </p>
    </>,
    fieldDesc: <>
      השלמנו בהצלחה את איסוף טפסי ה-106 שלך, וריכזנו אותם כאן
    </>,
    fieldDesc_UBZ: <>
      משכנו עבורך את המידע מרשות המיסים, אך חלק מטפסי ה-106 חסרים (ומסומנים באדום).
      <br />
      איך משיגים את הטפסים החסרים? חיברנו עבורך
      <a
       href="https://taxon.co.il/%D7%98%D7%95%D7%A4%D7%A1-106-%D7%94%D7%97%D7%96%D7%A8-%D7%9E%D7%A1/"
       target='_blank'
       className="underline"
      > מדריך קצר 
      ופשוט.
      
      </a>
      <br />
      <p>
      </p>
      <br />
      <b>חסר לך טופס? </b>
      אנחנו יכולים גם להפיק עבורך טופס 1245 (בקשה לקבלת מידע) עם כל החוסרים, שעליך להגיש אונליין באופן עצמאי במערכת  <a
        href="https://www.gov.il/apps/taxes/taxes/#/shelot-vetshuvot/he?pniyaPopUp=true&pniya=true"
        target='_blank'
        className="underline"
      >
        במערכת מפ"ל
      </a>
      של רשות המיסים.
      
    </>,
    fields: ["secondaryFields"],
    // fields: ["msgCrit"],
    docUrl: tofes106tDoc
  },
  BTL: {
    title: "מסמכי ביטוח לאומי",
    details: "",
    description: <>
      <p>
        איזה כיף, לא צריך להעלות כלום!
      </p>
      <p>
        מערכת Tax:on תמשוך עבורך אוטומטית את כל המסמכים הרלוונטיים מהמוסד לביטוח לאומי.
      </p>
      <p>
        אם מסיבה כלשהי לא נוכל למשוך את המסמכים, נעדכן ונאפשר לך להעלות אותם באופן עצמאי למערכת.
      </p>
    </>,
    fieldDesc: <>
      <p>
        איזה כיף, לא צריך להעלות כלום!
        מערכת Tax:on משכה עבורך את כל הנתונים הרלוונטיים.
      </p>
    </>,
    fields: ["secondaryFields"],
    docUrl: BTLDoc
  },
  bankAccount: {
    title: "חשבון בנק ישראלי",
    details: "צילום צ'ק או אישור ניהול חשבון בנק ישראלי",
    description: <>
      <p>
        כמה ימים לאחר אישור בקשת הייצוג על ידך, נדע איזה שירות נוכל לתת לך, ועבור אילו שנים.
      </p>
      <p>
        מייד לאחר מכן, נבקש ממך להעלות צילום צ'ק או אישור ניהול חשבון בנק ישראלי.
      </p>
    </>,
    fields: ["uploadBox"],
    docUrl: bankDoc
  },
  children: {
    title: "ילדים",
    details: "(עליך למסור את פרטי כל ילדיך, גם אם גילם מעל 18)",
    description: <>
      <p>
        כמה ימים לאחר אישור בקשת הייצוג על ידך, נדע איזה שירות נוכל לתת לך, ועבור אילו שנים.
      </p>
      <p>
        מייד לאחר מכן, נבקש ממך למלא את פרטי ילדיך.
      </p>
    </>,
    fields: ["childrenFields"],
  },
  privateInsurance: {
    title: "ביטוח פרטי",
    details: "(ביטוח חיים, משכנתא או אובדן כושר עבודה)",
    description: <>
      <p>
        כמה ימים לאחר אישור בקשת הייצוג על ידך, נדע איזה שירות נוכל לתת לך, ועבור אילו שנים.
      </p>
      <p>
        מייד לאחר מכן, נבקש ממך להעלות מסמך בשם אישור מס עבור ביטוח חיים מהדוח השנתי המקוצר (לא המורחב).
      </p>
    </>,
    fields: ["mainFields"],
    docUrl: privateInsuranceDoc
  },
  providentFund: {
    title: "הפקדת כספים עצמאית לפוליסת ביטוח, קופת גמל או קרן פנסיה",
    details: "(לא כולל קופות גמל להשקעה)",
    description: <>
      <p>
        כמה ימים לאחר אישור בקשת הייצוג על ידך, נדע איזה שירות נוכל לתת לך, ועבור אילו שנים.
      </p>
      <p>
        מייד לאחר מכן, נבקש ממך להעלות אישור מס שנתי על הפקדות באופן פרטי / עצמאי לקופת גמל, קרן פנסיה או פוליסת ביטוח.
      </p>
    </>,
    fields: ["mainFields"],
    docUrl: providentFundDoc
  },
  withdrawal: {
    title: "משיכת כספים בניכוי מס",
    details: "(מקופת גמל, קרן השתלמות, קרן פנסיה, ביטוח מנהלים או כספי פיצויים)",
    description: <>
      <p>
        כמה ימים לאחר אישור בקשת הייצוג על ידך, נדע איזה שירות נוכל לתת לך, ועבור אילו שנים.
      </p>
      <p>
        לאחר מכן, נבקש ממך להעלות טופס 806/857 מקופת הגמל, המפרט מהו הסכום שמשכת, ומהו שיעור המס שנוכה ממנו.
      </p>
    </>,
    fields: ["mainFields"],
    docUrl: withdrawalDoc
  },
  capitalMarket: {
    title: "מסחר בשוק ההון",
    description: <>
      <p>
        כמה ימים לאחר אישור בקשת הייצוג על ידך, נדע איזה שירות נוכל לתת לך, ועבור אילו שנים.
      </p>
      <p>
        מייד לאחר מכן, נבקש ממך להעלות טופס 867 - מסמך בנקאי ובו ריכוז שנתי של פעילותך בשוק ההון, כולל הכנסות מריבית, ורווחים או הפסדים ממסחר בניירות ערך.
      </p>
    </>,
    fields: ["mainFields"],
    docUrl: capitalMarketDoc
  },
  diploma: {
    title: "סיום לימודים",
    details: "(במוסד אקדמי או לא-אקדמי)",
    description: <>
      <p>
        כמה ימים לאחר אישור בקשת הייצוג על ידך, נדע איזה שירות נוכל לתת לך, ועבור אילו שנים.
      </p>
      <p>
        מייד לאחר מכן, נבקש ממך להעלות שני מסמכים: אישור על סיום לימודים, ואישור שעות לימוד ממוסד הלימודים (לפחות 1,700 שעות)
      </p>
    </>,
    fields: ["mainFields"],
    docUrl: diplomaDoc
  },
  appartmentRent: {
    title: "הכנסות משכר דירה",
    fields: ["appartmentRent"],
    description: <>
      <p>
        כמה ימים לאחר אישור בקשת הייצוג על ידך, נדע איזה שירות נוכל לתת לך, ועבור אילו שנים.
      </p>
      <p>
        מייד לאחר מכן, נבקש ממך לציין מה הכנסתך השנתית משכר דירה, ובמקרה ששילמת 10% מס על שכר הדירה - להעלות למערכת את אישור התשלום לרשות המיסים עבור השנים הרלוונטיות.    </p>
    </>,
    docUrl: appartmentRentDoc
  },
  gambling: {
    title: "הכנסות מהימורים, הגרלות או פרסים ",
    description: <>
      <p>
        כמה ימים לאחר אישור בקשת הייצוג על ידך, נדע איזה שירות נוכל לתת לך, ועבור אילו שנים.
      </p>
      <p>
        מייד לאחר מכן, נבקש ממך להעלות אישור על הזכייה ואישור על ניכוי מס במקור, במקרה שסכום הזכייה עולה על הסכום המקסימלי הפטור ממס.
      </p>
    </>,
    fields: ["mainFields"],
  },
  donations: {
    title: "תרומות",
    description: <>
      <p>
        כמה ימים לאחר אישור בקשת הייצוג על ידך, נדע איזה שירות נוכל לתת לך, ועבור אילו שנים.
      </p>
      <p>
        מייד לאחר מכן, נבקש ממך להעלות קבלה שנתית מרוכזת וחתומה דיגיטלית, עבור כל מוסד או עמותה שתרמת להם.
      </p>
    </>,
    fields: ["mainFields"],
    docUrl: donationsDoc
  },
  beneficiarySettlement: {
    title: "ישוב מזכה",
    description: <>
      <p>
        כמה ימים לאחר אישור בקשת הייצוג על ידך, נדע איזה שירות נוכל לתת לך, ועבור אילו שנים.
      </p>
      <p>
        מייד לאחר מכן, נבקש ממך להעלות טופס 1312 א'- אישור תושבות ביישוב המזכה, כולל תאריך תחילת מגורים באותו יישוב. בנוסף, רשות המיסים דורשת את אחד המסמכים הבאים: חשבון חשמל/ארנונה על שמך בכתובתך בישוב המזכה, ולהורים - רישום לגני ילדים או בתי ספר.      </p>
    </>,
    details: `כדי לקבל הטבת מס עבור ישוב מזכה, נא לצרף טופס 1312 א'- אישור תושבות ביישוב המזכה, כולל תאריך תחילת מגורים ביישוב (נשואים? נא להעלות אישור עבור שני בני הזוג)
    בנוסף, עליך להעלות חשבון חשמל או ארנונה על שמך ביישוב המזכה, או רישום לגן ילדים או בי"ס. `,
    fields: ["BeneficiarySettlementFields"],
    docUrl: beneficiarySettlementDoc
  },
  militaryService: {
    title: "שירות צבאי או לאומי",
    description: <>
      <p>
        כמה ימים לאחר אישור בקשת הייצוג על ידך, נדע איזה שירות נוכל לתת לך, ועבור אילו שנים.
      </p>
      <p>
        מייד לאחר מכן, נבקש ממך להעלות תעודת שחרור משרות חובה הכוללת תאריך תחילת שרות וסיום שרות.
      </p>
    </>,
    fields: ["mainFields"],
    docUrl: militaryServiceDoc
  },
  spousalSupport: {
    title: "תשלום מזונות",
    details: "(עליך להעלות באופן חד-פעמי הסכם גירושין חתום או פסק דין על תשלום מזונות, אם הוא אינו כלול בהסכם הגירושין. בנוסף, עליך להעלות אישור על העברות והפקדות כספי מזונות, עבור השנה או השנים המתאימות)",
    description: <>
      <p>
        כמה ימים לאחר אישור בקשת הייצוג על ידך, נדע איזה שירות נוכל לתת לך, ועבור אילו שנים.
      </p>
      <p>
        מייד לאחר מכן, נבקש ממך להעלות לצרף שני מסמכים –
      </p>
      <p>
        1.	הסכם מזונות חתום או פסק דין על תשלום מזונות, אם הוא ניתן בנפרד ואינו כלול בהסכם הגירושין. יש להעלות מסמך זה באופן חד-פעמי למערכת
      </p>
      <p>
        2.	אישור על העברות והפקדות כספי מזונות, עבור השנה או השנים המתאימות.
      </p>
    </>,
    fields: ["spousalSupportFields"],
    docUrl: spousalSupportDoc
  },
  newImmigrants: {
    title: "עולה חדש / תושב חוזר",
    description: <>
      <p>
        כמה ימים לאחר אישור בקשת הייצוג על ידך, נדע איזה שירות נוכל לתת לך, ועבור אילו שנים.
      </p>
      <p>
        מייד לאחר מכן, נבקש ממך להעלות תעודת עולה חדש או תעודת תושב חוזר.
      </p>
    </>,
    fields: ["mainFields"],
    docUrl: newImmigrantsDoc
  },
  disabled: {
    title: "נכות / עיוורון",
    description: <>
      <p>
        כמה ימים לאחר אישור בקשת הייצוג על ידך, נדע איזה שירות נוכל לתת לך, ועבור אילו שנים.
      </p>
      <p>
        מייד לאחר מכן, נבקש ממך להעלות אישור פטור מס הכנסה לפי סעיף 9(5) לפקודת מס הכנסה.
      </p>
    </>,
    fields: ["mainFields"],
    docUrl: disabledDoc
  },
  disabledChild: {
    title: "ילד נטול יכולת / לקות למידה",
    description: <>
      <p>
        כמה ימים לאחר אישור בקשת הייצוג על ידך, נדע איזה שירות נוכל לתת לך, ועבור אילו שנים.
      </p>
      <p>
        מייד לאחר מכן, נבקש ממך להעלות אישור של ועדת השמה/זכאות (תקפה ל-3 שנים מיום הוצאתה), או אישור על קצבת ילד נכה מהמוסד לביטוח לאומי      </p>
    </>,
    fields: ["mainFields"],
    docUrl: disabledChildDoc
  },
  institutionSpouse: {
    title: "החזקת קרוב משפחה במוסד",
    description: <>
      <p>
        כמה ימים לאחר אישור בקשת הייצוג על ידך, נדע איזה שירות נוכל לתת לך, ועבור אילו שנים.
      </p>
      <p>
        מייד לאחר מכן, נבקש ממך להעלות -
      </p>
      <p>
        1. חשבונית מקור או אישור על התשלומים למוסד המיוחד
      </p>
      <p>
        2. טופס 116 א' - בקשת זיכוי ממס בגין קרוב נטול יכולת
      </p>
      <p>
        3. טופס 127 - תעודה רפואית עם פרטים ואבחנה מדויקת
      </p>
    </>,
    fields: ["mainFields"],
    docUrl: institutionSpouseDoc
  },
  misc: {
    title: "שונות / מסמכים צרופים",
    description: <>
      <p>
        כמה ימים לאחר אישור בקשת הייצוג על ידך, נדע איזה שירות נוכל לתת לך, ועבור אילו שנים.
      </p>
      <p>
        בקטגוריה הזו, נאפשר לך להעלות כל מסמך או קבלות בדבר הוצאות מוכרות לצרכי מס, שעשויות להשפיע על החזר המס שלך בשנים הרלוונטיות לבדיקה, ולא ברור לך לאן הן שייכות (למשל: קבלה על טיפול בהחזר מס של שנים קודמות)
      </p>
    </>,
    fields: ["miscFields"],
  },

};

// preview of criteria render in the criteria and looks like btn
// IMPORTANT: the key must have be the same name as the key in account's characterization/info
// IMPORTANT: the order of this array influence the display order in the cretiria
export const criteriaOptions = [
  // {title: "עובד טקסאון", key:"TaxonEmployee" , },
  { key: "bankAccount", title: "חשבון בנק ישראלי" },
  { key: "tofes106", title: "טפסי 106" },
  { key: "BTL", title: "מסמכי ביטוח לאומי" },
  { key: "children", title: "ילדים", icon: <Children /> },
  { key: "privateInsurance", title: "ביטוח  פרטי" },
  // { key: "privateInsurance", title: "ביטוח  פרטי", icon: <PrivateInsurance /> },
  { key: "providentFund", title: "הפקדות כספים לקופות גמל", icon: <DepositBox /> },
  { key: "withdrawal", title: "משיכת כספים מקופות גמל", icon: <Withdrawal /> },
  { key: "capitalMarket", title: "מסחר בשוק ההון", icon: <StockMarket /> },
  { key: "diploma", title: "סיום לימודים", icon: <Diploma /> },
  { key: "appartmentRent", title: "הכנסות משכר דירה", icon: <Keys /> },
  { key: "gambling", title: "הכנסות מהימורים הגרלות או פרסים", icon: <Card /> },
  { key: "donations", title: "תרומות", icon: <Donations /> },
  { key: "beneficiarySettlement", title: "ישוב מזכה", icon: <Home /> },
  { key: "militaryService", title: "שירות צבאי או לאומי", icon: <Soldier /> },
  { key: "spousalSupport", title: "תשלום מזונות", icon: <Separated /> },
  { key: "newImmigrants", title: "עולה חדש או תושב חוזר", icon: <Suitcase /> },
  { key: "disabled", title: "נכות או עיוורון", icon: <Disabled /> },
  {
    key: "disabledChild",
    title: "ילד נטול יכולת או לקות למידה",
    icon: <Blackboard />,
  },
  {
    key: "institutionSpouse",
    title: "החזקת קרוב משפחה במוסד",
    icon: <HospitalBed />,
  },
  {
    key: "misc",
    title: "שונות / מסמכים נוספים",
  },

];
