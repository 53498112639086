import React from 'react'
import { useDispatch } from 'react-redux';
import { addDocToInfo, updateFieldRelevance } from '../../../store/actions/accountActions';
import { UploadBox } from './UploadBox';
import { NOT_RELEVANT, RELEVANT_AND_HAS_DOC, RELEVANT } from '../../../services/account.service'
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const SYSTEM_BLOCK = 3

export const MainFields = ({ connectedUser, partner, criteriaKey, years }) => {
    const dispatch = useDispatch();
    const userFullName = connectedUser.firstName
    const pratnerFullName = partner?.firstName
    const isMarried = connectedUser.maritalStatus === "married"
    const className = isMarried ? 'main-fields married' : 'main-fields'
    const users = isMarried ? [connectedUser, partner] : [connectedUser]

    const addFile = async (file, year, userId) => {
        const yearMetaData = years.find(y => {
            return y.year === year
        })

        try {
            if (yearMetaData[userId] === NOT_RELEVANT) {
                await dispatch(updateFieldRelevance(criteriaKey, year, userId, true))
            }

            file.year = year
            file.forUser = userId
            file.docName = file.docName + "_" + year
            await dispatch(addDocToInfo(criteriaKey, file))
        } catch (err) {
            toast.error('משהו השתבש..', {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
            });
        }
    }

    const updateRelevance = async ({ target }, year, forUserId) => {
        //if checked the edo gets false 

        await dispatch(updateFieldRelevance(criteriaKey, year, forUserId, !target.checked))

    }

    return (
        <div className={className}>
            {isMarried && <div className="main-fields-title bold main-fields-row">
                <div>שנה</div>
                <div>{userFullName}</div>
                <div>{pratnerFullName}</div>
            </div>}

            <div className="main-fields-body">
                {!years.length && <div>אין שנים להצגה</div>}
                {years.map(year => <div className="main-fields-row" key={year.year}>
                    <div className="year bold">{year.year}</div>
                    {users.map((user,sindex) => <div className="actions" key={user.userId}>
                        <div className="name">{user.firstName}</div>
                        {year[user.userId] !== SYSTEM_BLOCK && <>
                            <UploadBox className={'main-fields-btn ' + `${RELEVANT_AND_HAS_DOC === year[user.userId] ? 'Uploaded' : ''}`}
                                txt={RELEVANT_AND_HAS_DOC === year[user.userId] && "הוסף קובץ נוסף"}
                                // disabled={NOT_RELEVANT === year[user.userId]}
                                addFile={(file) => addFile(file, year.year, user.userId)} />
                            <input type="checkbox"
                                checked={!year[user.userId]}
                                onChange={(ev) => updateRelevance(ev, year.year, user.userId)}
                                name={`${year.year}_${user.userId}`} id={`${year.year}_${user.userId}`}
                                hidden
                            />
                            <label htmlFor={`${year.year}_${user.userId}`}
                                className='is-relevant main-fields-btn'>
                                אין לי
                            </label>
                        </>}
                    </div>)}
                </div>)}
            </div>
        </div>
    )
}