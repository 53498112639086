import React from 'react'
import { MainFields } from './MainFields'
import { UploadBox } from './UploadBox';

export const SpousalSupportFields = ({ connectedUser, partner, criteriaKey, years, form, addFile, docUrl, }) => {
    const divorceContract = form.docs.find(doc => doc.fieldName === "spousalSupportContract");
    const className = divorceContract ? "add-doc Uploaded" : "add-doc"
    return (
        <>

            <div className='flex justify-center'>
                <UploadBox addFile={addFile} docUrl={docUrl} criteriaKey={criteriaKey} multiple={false} txt={"הסכם גירושין חתום או פסק דין"} className={className} />
            </div>
            <br />
            <br />

            <MainFields connectedUser={connectedUser} partner={partner} criteriaKey={criteriaKey} years={years} />
        </>
    )
}
