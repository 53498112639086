import React from 'react'
import { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'


export const NavCard = ({ icon, txt, path, subNav, end, onClick }) => {
    let { pathname } = useLocation();
    const [isOpen, setIsOpen] = useState(false)
    const toggelSubNav = () => {
        setIsOpen(prevIsOpen => !prevIsOpen)
    }
    const openSubNav = () => {
        setIsOpen(true)
    }
    const handleClick = () => {
        if (onClick) {
            onClick()
        }
        if (subNav) {
            openSubNav()
        }
    }

    const closeSubNav = () => {
        setIsOpen(false)
    }

    return (
        <>
            <li onClick={handleClick}>
                <NavLink
                    to={path}
                    className={({ isActive }) =>
                        `card ${isActive ? 'active' : ''}`
                    }
                    end={end}
                >
                    <div className='flex auto-center'>
                        {icon}
                    </div>
                    <div className='flex auto-center'>
                        <p>{txt}</p>
                    </div>
                </NavLink>
            </li>
            {(subNav && isOpen && pathname.includes(path)) && < ul >

                {subNav.map(sub => {
                    if (sub.condition === undefined || sub.condition)
                        return <li key={sub.txt}>
                            <NavLink to={sub.path} className={({ isActive }) =>
                                `subcard ${isActive ? 'active' : ''}`
                            }
                                end
                            >
                                <div className='flex auto-center'>
                                    {sub.icon}
                                </div>
                                <div className='flex auto-center'>
                                    <p>{sub.txt}</p>
                                </div>
                            </NavLink>
                        </li>
                }


                )}
            </ul>}
        </>
    )
}
