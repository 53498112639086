import React from 'react'
import { useState } from 'react'
import { useRef } from 'react'
import { Amex, Maesto, Mastercard, Visa } from '../../../assets/svg-img/creditcard'
import { useMask } from '../../../hooks/useMask'

const icons = {
    amex: <Amex className='ccicon' />,
    mastercard: <Mastercard className='ccicon' />,
    visa: <Visa className='ccicon' />,
    maesto: <Maesto className='ccicon' />
}

const regexes = {
    amex: '^3[47]\\d{0,13}',
    mastercard: '^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}',
    visa: '^4\\d{0,15}',
    maesto: '^(?:5[0678]\\d{0,2}|6304|67\\d{0,2})\\d{0,12}'
}
const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
const years = ['2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034', '2035']

export const CcCart = ({ price, doPay }) => {
    const [form, setForm] = useState({
        name: '',
        ccNo: '',
        month: '',
        year: '',
        cvv: '',
        id: ''
    })

    const handleChange = ({ target }) => {
        const { name, value } = target
        setForm(prevFrom => ({ ...prevFrom, [name]: value }))
    }

    const handleSubmit = () => {
        const data = {
            name: form.name,
            ccNo: form.ccNo,
            id: form.id,
            cvv: form.cvv,
            exp: form.month + form.year.substring(2),
        }
        doPay(data)
    }

    return (
        <div className='cc-cart'>
            <input name="name" type="text"
                value={form.name} onChange={handleChange}
                placeholder="שם בעל הכרטיס" maxLength={12} />
            <input name="ccNo" type="tel"
                value={form.ccNo} onChange={handleChange}
                placeholder="מס' כרטיס" maxLength={16} />
            <div className="field-row">
                <select name="month" value={form.month} onChange={handleChange}>
                    <option value="" disabled hidden>תוקף חודש</option>
                    {months.map(m => <option value={m} key={m}>{m}</option>)}
                </select>
                <select name="year" value={form.year} onChange={handleChange}>
                    <option value="" disabled hidden>תוקף שנה</option>
                    {years.map(y => <option value={y} key={y}>{y}</option>)}
                </select>
            </div>
            <input name='cvv' type="tel"
                value={form.cvv} onChange={handleChange}
                placeholder="3 ספרות בגב הכרטיס" maxLength={4} />
            <input name='id' type="tel"
                value={form.id} onChange={handleChange}
                placeholder="ת.ז." maxLength={9} />
            <div>
                סה"כ לתשלום {price} <span className="shekel-sign">שח</span>
            </div>
            <button className='pay-btn' onClick={handleSubmit}>
                בצע תשלום
            </button>
        </div>
    )
}
