import React, { useContext, useEffect, useRef, useState } from 'react'
import { OpenMenuContext } from '../../context/OpenMenuContext'
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { IdIcon, ArrowRotate, MessageIcon, LogoutIcon, CriteriaIcon, MaleIcon, FemaleIcon, KidsIcon } from '../../assets/icons'
import { useDispatch } from 'react-redux';
import { doLogout } from '../../store/actions/accountActions';
import { NavCard } from './navbar/NavCard';

export const DashboardNav = ({ users, info }) => {

    const { characterization_confirmed, children } = info
    const loggedInUser = users[0]
    const partner = users[1]
    const dispatch = useDispatch()

    const { isOpen, setIsOpen } = useContext(OpenMenuContext)
    const navRef = useRef(null)
    useOnClickOutside(navRef, () => setIsOpen(false))

    const [windowWidth, setWindowWidth] = useState()
    useEffect(() => {
        window.addEventListener('resize', updateWindowWidth)
        return () => {
            window.removeEventListener('resize', updateWindowWidth)
        }
    })

    const updateWindowWidth = () => {
        setWindowWidth(window.innerWidth)
    }
    const logout = () => {
        dispatch(doLogout())
    }

    const cardsData = [
        {
            icon: <IdIcon />,
            txt: 'פרטים אישיים',
            path: '/dashboard/UserDetails',
            subNav: [
                {
                    icon: loggedInUser.gender === "male" ? < MaleIcon /> : <FemaleIcon />,
                    txt: `הפרטים של ${loggedInUser.firstName}`,
                    path: '/dashboard/UserDetails'
                },
                {
                    icon: partner?.gender === "male" ? < MaleIcon /> : <FemaleIcon />,
                    txt: `הפרטים של ${partner?.firstName}`,
                    path: '/dashboard/UserDetails/partner',
                    condition: (loggedInUser.maritalStatus === "married")
                },
                {
                    icon: <KidsIcon />,
                    txt: `הפרטים של הילדים`,
                    path: '/dashboard/UserDetails/children',
                    condition: (characterization_confirmed.isSelected && children?.data?.children.length>=1)
                    // condition: (children?.isSelected && children?.data?.children.length)
                },
                // {
                //     icon: '',
                //     txt: 'פרטי חשבון בנק',
                //     path: '/dashboard/UserDetails/bank'
                // },
            ]
        },
        { icon: <CriteriaIcon />, txt: 'תנאי הזכאות שלי', path: '/dashboard/criteria' },
        { icon: <ArrowRotate />, txt: 'החזרי המס שלי', path: '/dashboard/products', end: true },
        { icon: <ArrowRotate />, txt: 'ארכיון מסמכים', path: '/dashboard/archive', end: true },
        // { icon: <QuestionIcon />, txt: 'שאלות ותשובות', nav: '/dashboard/faqs' },
    ]


    return (
        <section className={`dashboard-nav ${isOpen ? 'open' : ''}`} ref={navRef}>
            <div className='dashboard-nav-container'>
                <div>
                    <div className="user-preview">
                        {loggedInUser.gender === "male" ? <MaleIcon /> : <FemaleIcon />}
                        <p className='user-name bold'>
                            {loggedInUser.firstName}
                        </p>
                    </div>
                    <ul className='navigation'>
                        {cardsData.map(card => <NavCard {...card} key={card.txt} onClick={() => setIsOpen(false)} />)}
                    </ul>
                </div>
                <ul className='contact'>
                    <li>
                        <a
                            href="https://taxon.co.il/contact/"
                            className={`card flex column auto-center`}
                            target="_blank"
                        >

                            <div className='flex auto-center'>
                                <MessageIcon />
                            </div>
                            <div className='flex auto-center'>
                                <p>יצירת קשר</p>
                            </div>
                        </a>
                    </li>
                    <li className="card flex column auto-center" onClick={logout}>
                        <div className='flex auto-center'>
                            <LogoutIcon />
                        </div>
                        <div className='flex auto-center'>
                            <p>יציאה</p>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}
