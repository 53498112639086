import { createSlice } from "@reduxjs/toolkit";

export const siteSlice = createSlice({
    name: 'site',
    initialState: {
        isLoading: false,
        isShowWorningLogout: false,
        editAccountPage: 'loggedUser',
    },
    reducers: {
        setIsLoading: (state, { payload }) => {
            state.isLoading = payload
        },
        setIsShowWorningLogout: (state, { payload }) => {
            state.isShowWorningLogout = payload
        },
        setEditAccountPage: (state, { payload }) => {
            state.editAccountPage = payload
        }
    },
})

export const { setIsLoading, setIsShowWorningLogout, setEditAccountPage } = siteSlice.actions

export const siteReducer = siteSlice.reducer