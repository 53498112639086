import React from "react";
import { ProductPreview } from "./ProductPreview";

export const ProductList = ({ children, tableTitles, rowsData, openZugi, onShowDetails }) => {
  return (
    <section className="list-wrapper">
      {children}
      {rowsData.map((prod, idx) => (
        <ProductPreview key={idx}
          tableTitles={tableTitles}
          openZugi={openZugi}
          rowData={prod}
          onShowDetails={onShowDetails}
        />
      ))}
    </section>
  );
};