import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ZugiV } from '../assets/svg-img/zugi'
import SignComponent from '../components/boarding/SignComponent'
import Head from '../components/comon/header'
import { Spinner } from '../components/comon/Spinner'
import { accountService } from '../services/account.service'

export const Signatures = () => {
    const navigate = useNavigate()
    const [userDetails, setUserDetails] = useState();
    const [openThankPage, setopenThankPage] = useState(false);
    const [signature, setSignature] = useState(null)
    const searchParams = new URLSearchParams(window.location.search);
    const service = searchParams.get("service");
    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {

        switch (service) {
            case "1301":
                const params = {
                    token: searchParams.get("tn"),
                    userId: searchParams.get("ui"),
                    productId: searchParams.get("pi"),
                    year: searchParams.get("year"),
                };
                if (!params.token || !params.userId || !params.productId) {
                    navigate('/error')
                }
                await load1301(params);

                break;
            case "poa":
                const poaParams = {
                    token: searchParams.get("tkn"),
                    userId: searchParams.get("ui"),
                }
                if (!poaParams.token || !poaParams.userId) {
                    navigate('/error');
                    console.log('no data');
                }
                await loadPoa(poaParams);
                break;

            default:
                navigate('/error')
                break;
        }
    }

    const load1301 = async ({ userId, token, productId, year }) => {
        try {
            const res = await accountService.get1301(userId, token, productId)
            const data = {
                signModalTitle: "חתימה על מסמך 1301",
                doc: res.file,
                img: res.img,
                fileId: res.fileId,
                jwt: res.jwt,
                addToDocName: year,
                userId
            }

            setUserDetails(data)
        } catch (err) {
            navigate('/error');
        }
    }

    const loadPoa = async ({ userId, token }) => {
        try {
            const res = await accountService.getUnsignedPoa(userId, token)
            const data = {
                signModalTitle: `חתימה על ייפוי כוח ${"\n"}${res.type}`,
                doc: res.file,
                img: res.img,
                jwt: token,
                userId
            }

            setUserDetails(data)
        } catch (err) {
            navigate('/error');
        }
    }

    const submit1301 = async ({ signImg }) => {
        const { userId, jwt, fileId } = userDetails
        try {
            await accountService.sign1301({ signature: signImg, userId, jwt, fileId })
            setopenThankPage(true)
        } catch (err) {
            toast.error("משהו השתבש", {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                autoClose: 3000,
            });
        }
    }

    const submitPoa = async ({ signImg }) => {
        const { userId, jwt } = userDetails
        try {
            await accountService.signPoa({ signature: signImg, userId, jwt })
            setopenThankPage(true)
        } catch (err) {
            toast.error("משהו השתבש", {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                autoClose: 3000,
            });
        }
    }

    const submit = async (signImg) => {
        switch (service) {
            case "1301":
                await submit1301({ signImg });
                break;

            case "poa":
                await submitPoa({ signImg });
                break;

            default:
                navigate('/error')
                break;
        }
    }

    if (!userDetails) return <Spinner autoLoading={true} />

    if (openThankPage) return <Thanks />

    return (<>
        <div></div>
        <section className="boarding-page page">
            <Head>
            </Head>
            <section className="main-boarding-layout">
                <SignComponent
                    nextPage={submit}
                    title={userDetails.signModalTitle}
                    docs={userDetails.doc}
                    imgDoc={userDetails.img}
                    pdfDoc={userDetails.doc}
                    fileName={"1301"}
                    sign={signature}
                    addToDocName={userDetails.addToDocName}
                />
            </section>
        </section>
    </>
    )

}


const Thanks = () => {
    return (<>
        <div></div>
        <section className="boarding-page page">
            <Head>
            </Head>
            <section className="main-boarding-layout signatures-thank-page">
                <ZugiV />
                <h1>חתימך נשמרה בהצלחה!</h1>
            </section>
        </section>
    </>
    )
}
