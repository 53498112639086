import React from 'react'
import { useDispatch } from 'react-redux';
import { addDocToInfo, updateFieldRelevance } from '../../../store/actions/accountActions';
import { UploadBox } from './UploadBox';
import { NOT_RELEVANT, RELEVANT_AND_HAS_DOC, RELEVANT } from '../../../services/account.service'

const SYSTEM_BLOCK = 3
export const BeneficiarySettlementFields = ({ connectedUser, partner, criteriaKey, years }) => {
    const dispatch = useDispatch();
    const userFullName = connectedUser.firstName
    const pratnerFullName = partner?.firstName
    const isMarried = connectedUser.maritalStatus === "married"
    const className = isMarried ? 'main-fields married' : 'main-fields'
    const users = isMarried ? [connectedUser, partner] : [connectedUser]

    const addFile = async (file, year, userId, fieldName) => {
        const yearMetaData = years.find(y => {
            return y.year === year
        })

        try {
            if (yearMetaData[userId] === NOT_RELEVANT) {
                await dispatch(updateFieldRelevance(criteriaKey, year, userId, true))
            }

            file.year = year
            file.forUser = userId
            file.docName = file.docName + "_" + year
            file.fieldName = fieldName
            await dispatch(addDocToInfo(criteriaKey, file))
        } catch (err) {
            throw err
        }
    }

    const updateRelevance = async ({ target }, year, forUserId) => {
        await dispatch(updateFieldRelevance(criteriaKey, year, forUserId, !target.checked))
    }

    return (
        <div className={className + " beneficiary-settlement-fields"}>
            <div className="main-fields-title bold main-fields-row">
                <div>שנה</div>
                <div>
                    <p>
                        {userFullName}
                    </p>
                    <p>אישור תושבות</p>
                </div>
                {isMarried && <div>
                    <p>
                        {pratnerFullName}
                    </p>
                    <p>אישור תושבות</p>
                </div>}
                <div>אישור חשמל/ארנונה /מוסד חינוכי</div>
            </div>

            <div className="main-fields-body">
                {!years.length && <div>אין שנים להצגה</div>}
                {/* year loop */}
                {years.map(year => <div className="main-fields-row" key={'year-' + year.year}>
                    <div className="year bold">{year.year}</div>
                    {/* user loop */}
                    {
                        users.map(user =>

                            <div className="actions" key={'user-' + user.userId}>
                                <div className="name">{user.firstName}</div>
                                {/* if not blocked */}
                                {year[user.userId] !== SYSTEM_BLOCK && <>
                                    <UploadBox className={'main-fields-btn ' + `${RELEVANT_AND_HAS_DOC === year[user.userId] ? 'Uploaded' : ''}`}
                                        txt={RELEVANT_AND_HAS_DOC === year[user.userId] && 'הוסף קובץ נוסף'}
                                        addFile={(file) => addFile(file, year.year, user.userId)} />
                                    <input type="checkbox"
                                        checked={!year[user.userId]}
                                        onChange={(ev) => updateRelevance(ev, year.year, user.userId)}
                                        name={`${year.year}_${user.userId}`} id={`${year.year}_${user.userId}`}
                                        hidden
                                    />
                                    <label htmlFor={`${year.year}_${user.userId}`}
                                        className='is-relevant main-fields-btn'>
                                        אין לי
                                    </label>
                                </>}
                            </div>
                        )
                    }
                    <div className="actions">
                        <div className="name">אישור חשמל</div>
                        <UploadBox className={'main-fields-btn '
                            + `${RELEVANT_AND_HAS_DOC === year['proof'] ? 'Uploaded' : ''}`
                        }
                            txt={RELEVANT_AND_HAS_DOC === year['proof'] && "הוסף קובץ נוסף"}
                            addFile={(file) => addFile(file, year.year, null, 'beneficiarySettlementProof')} />

                    </div>
                </div>)}
            </div>
        </div >
    )
}
