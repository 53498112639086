import React from "react";
import { TextBox } from "./TextBox";
import BaseJoi, { optional } from "joi";
import { toast } from "react-toastify";
import { DateInputMask } from "./DateInputMask";
import { MobileInputMask } from "./MobileInputMask";
import { NationalidInputMask } from './NationalidInputMask';
import JoiDate from "@joi/date";
var Joi = BaseJoi.extend(JoiDate);

class Form extends React.Component {
  //get the current year for users above age of 18
  minDate = `${new Date().getFullYear() - 77}-01-01`;
  maxDate = `${new Date().getFullYear() - 18}-01-01`;
  // regex = new RegExp(/[\P{Script_Extensions=Hebrew} '\-"]/u);

  schema = {
    firstName: Joi.string()
      .pattern(/^[\u0590-\u05FF ,.'-]+$/i)
      .required()
      .min(2),
    // .regex(new RegExp(/^[\u0590-\u05FF ,.'-]+$/i)),
    // .regex(/[אבגדהוזחטיכלמנסעפצקרשתץףןםך״׳-\s.]+$/),
    lastName: Joi.string()
      .required()
      .min(2)
      .regex(/^[אבגדהוזחטיכלמנסעפצקרשתץףןםך״׳-\s.]+$/),
    birthdate: Joi.date()
      .required()
      .greater(this.minDate)
      .less(this.maxDate)
      .format(["YYYY-MM-DD", "DD-MM-YYYY"]).utc(),
    cardIssuedDate: Joi.date()
      .required()
      // .greater(this.minDate) // no need too validate min or max date for the id issued
      // .less(this.maxDate)
      .format(["YYYY-MM-DD", "DD-MM-YYYY"]).utc(),
    // id: Joi.string().valid('1','2','3','4','5','6','7','8','9','0').required().max(9),
    // id: Joi.string().required().max(9),
    id: Joi.string().required().max(9),
    license: Joi.string().pattern(/^[\d]*$/).required().min(7),
    address: Joi.string().required(),
    email: Joi.string()
      .required()
      .email({ tlds: { allow: false } }),
    tel: Joi.string()
      .required()
      .regex(/^[0]{1}[0-9]{9}$/),
    year: Joi.number().integer().required(),
    street: Joi.string()
      .required()
      .min(2)
      .pattern(/^[אבגדהוזחטיכלמנסעפצקרשתץףןם׳-\s.]+$/),
    houseNo: Joi.number().integer().required(),
    city: Joi.string().required(),
    gender: "",
    status: Joi.string().required(),
    bankName: Joi.string().required(),
    branchNo: Joi.number().integer().required(),
    accountNo: Joi.number().integer().required(),
    nameOfAccount: Joi.string()
      .required()
      .min(2)
      .pattern(/^[אבגדהוזחטיכלמנסעפצקרשתץףןם׳-\s.]+$/),
    method: Joi.string(),
    otp: Joi.number().required(),
    gender: Joi.string().required(),
    token: Joi.any().optional(),
    userId: Joi.any().optional(),
  };

  validateInput({ name, value }) {
    //{type,value}

    const data = {
      [name]: value,
    };

    const schema = Joi.object({
      [name]: this.schema[name],
    });

    const { error } = schema.validate(data);

    return error ? error.details[0].message : null;
  }

  validateForm(form) {
    //build schema according form's keys
    const schema = {};
    for (var key in form) {
      schema[key] = this.schema[key];
    }

    const { error } = Joi.object({ ...schema }).validate(form, {
      abortEaely: false,
    });

    if (!error) {
      return null;
    }
    const errors = {};
    for (const detail of error.details) {
      errors[detail.path[0]] = detail.message;
      if (detail.path[0]) {
        console.error(detail.path[0]);
        return errors;
      }
    }

    return errors;
  }

  handleSubmit = (e, form) => {
    e.preventDefault();
    e.target.disabled = true;

    const errors = this.validateForm(form);
    // const errors = "this"
    this.setState({ errors });

    if (errors) {
      if (errors.gender) {
        toast.error("יש לסמן גבר או אישה", {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          autoClose: 2000,
        });

      } else {
        toast.error("אחד מהפרטים שהכנסת לא נכונים", {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          autoClose: 2000,
        });
      }
      e.target.disabled = false;
      return errors;
    }
    
    this.doSubmit();
  };

  handleChange = ({ target }) => {
    if(document.getElementById('btnSubmit')){
      document.getElementById('btnSubmit').disabled = false;
    }
    if(document.getElementById('btnContimue')){
      document.getElementById('btnContimue').disabled = false;
    }
    

    const { form, errors } = this.state;
    this.setState({
      form: {
        ...form,
        [target.name]: target.value,
      },
      errors: {
        ...errors,
        [target.name]: this.validateInput(target),
      },
    });
  };


  renderInput({
    label,
    name,
    type = "text",
    placeholder,
    icon,
    errorText,
    helperText,
    value,
    minLength = "0",
    maxLength,
    disabled = false,
    onBlur
  }) {
    const { errors } = this.state;
    return (
      <TextBox
        minLength={minLength}
        maxLength={maxLength}
        placeholder={placeholder}
        type={type}
        name={name}
        icon={icon}
        label={label}
        disabled={disabled}
        value={value}
        errorText={errorText}
        onChange={this.handleChange}
        error={errors?.[name]}
        onBlur={onBlur}
      />
    );
  }

  renderDateInputMask({
    label,
    name,
    type = "text",
    placeholder,
    icon,
    errorText,
    helperText,
    value,
    maxLength,
    disabled = false,
  }) {
    const { errors } = this.state;
    return (
      <DateInputMask
        maxLength={maxLength}
        placeholder={placeholder}
        type={type}
        name={name}
        icon={icon}
        label={label}
        disabled={disabled}
        value={value}
        onChange={this.handleChange}
        helperText={helperText}
        errorText={errorText}
        error={errors?.[name]}
      />
    );
  }

  renderMobileInputMask({
    label,
    name,
    type = "text",
    placeholder,
    icon,
    errorText,
    helperText,
    value,
    maxLength,
    disabled = false,
  }) {
    const { errors } = this.state;
    return (
      <MobileInputMask
        maxLength={maxLength}
        placeholder={placeholder}
        type={type}
        name={name}
        icon={icon}
        label={label}
        disabled={disabled}
        value={value}
        onChange={this.handleChange}
        // helperText={helperText}
        errorText={errorText}
        error={errors?.[name]}
      />
    );
  }
  renderNationalIdInputMask({
    label,
    name,
    type = "text",
    placeholder,
    icon,
    errorText,
    helperText,
    value,
    maxLength,
    disabled = false,
  }) {
    const { errors } = this.state;
    return (
      <NationalidInputMask
        maxLength={maxLength}
        placeholder={placeholder}
        type={type}
        name={name}
        icon={icon}
        label={label}
        disabled={disabled}
        value={value}
        onChange={this.handleChange}
        // helperText={helperText}
        errorText={errorText}
        error={errors?.[name]}
      />
    );
  }
}



export default Form;
