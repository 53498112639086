import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TextBox } from "../../comon/TextBox";
import { DateInputMask } from "../../comon/DateInputMask";
import BaseJoi from "joi";
import JoiDate from "@joi/date";
import { SelectBox } from "../../comon/SelectBox";
import { DeleteIcon } from "../../../assets/icons";

export const ChildrenFields = ({ form, setForm, openRemoveWarning }) => {
  // needed for give initial value to childrenCount
  const prevChildren = useSelector((state) => state.accountModule.info.children.data?.children);
  const [childrenCount, setChildrenCount] = useState(0);

  useEffect(() => {
    //if the user filled children then
    setChildrenCount(prevChildren?.length || 1);
  }, []);

  useEffect(() => {
    buildForm(childrenCount);
  }, [childrenCount]);

  const buildForm = (length) => {
    if (!length) return
    const newForm = [];
    const fields = {
      name: '',
      birthdate: '',
      gender: '',
      ownership: '',
      isSingleParent: false,
      isAccountPossession: false,
    };
    for (let i = 0; i < length; i++) {
      if (form.children && form.children[i]) {
        newForm.push(JSON.parse(JSON.stringify(form.children[i])));
      } else newForm.push(JSON.parse(JSON.stringify(fields)));
    }
    setForm((prevForm) => ({ ...prevForm, children: newForm }));
  };

  const handleChange = (ev, idx) => {
    var { name, value, type } = ev.target;
    if (type === "checkbox") value = ev.target.checked;
    const data = [...form.children];
    data[idx][name] = value;
    setForm((prevForm) => ({ ...prevForm, children: data }));
  };

  return (
    <>
      <div
        className="criteria-children flex align-center"
        style={{ gap: "7px", padding: "5px", height: "max-content" }}
      >
        <p>יש לי</p>
        <input
          type="number"
          value={childrenCount}
          onChange={(ev) => setChildrenCount(+ev.target.value || "")}
        />
        <p>ילדים</p>
      </div>
      {!!form.children?.length &&
        form.children?.map((fields, idx) => (
          fields.deleted
            ? <></>
            : <Fields
              fields={fields}
              idx={idx}
              handleChange={handleChange}
              key={idx}
              remove={openRemoveWarning}
            />
        ))}
    </>
  );
};

const Fields = ({ fields, handleChange, idx, remove }) => {
  var Joi = BaseJoi.extend(JoiDate);
  const users = useSelector(
    (state) => state.accountModule.users
  );


  const genderOpts = [{ value: '', label: 'בחר מין' },
  { value: 'male', label: 'זכר' },
  { value: 'female', label: 'נקבה' }
  ]

  const ownershipOpts = [{ value: '', label: 'ילד משותף' },
  { value: users[0].userId, label: `של ${users[0].firstName}` },
  { value: users[1]?.userId, label: `של ${users[1]?.firstName}` }
  ]
// the schema validate the joi
  const schema = {
    name: Joi.string()
      .required()
      .min(2)
      .regex(/^[אבגדהוזחטיכלמנסעפצקרשתץףןםך״׳-\s.]+$/),
    // yearOfBrith: Joi.number().max(2022).min(1900).integer().required()
    birthdate: Joi.date()
      .required()
      .format(["YYYY-MM-DD", "DD-MM-YYYY"]).utc(),
    gender: Joi.required()
  }

  const [errors, setErrors] = useState({})
  const maritalStatus = useSelector(
    (state) => state.accountModule.maritalStatus
  );

  const handleSingleParent = (ev) => {
    if (!ev.target.checked) return handleChange(ev, idx);
    handleChange(ev, idx);
    handleChange({ target: { name: "isAccountPossession", value: true } }, idx);
  };

  const validateInput = ({ target }) => {
    const { name, value } = target
    const { error } = schema[name].validate(value);
    setErrors((prevError) => ({ ...prevError, [name]: error }))
  }

  // const

  return (
    <div className="flex align-center children-fields">
      <TextBox
        value={fields["name"]}
        type="text"
        onChange={(ev) => {
          handleChange(ev, idx)
          validateInput(ev)
        }}
        name="name"
        label="שם"
        placeholder="שם"
        errorText={"שם לא תקין"}
        error={errors?.name}
      />
      <DateInputMask
        value={fields["birthdate"]}
        type="tel"
        onChange={(ev) => {
          handleChange(ev, idx)
          validateInput(ev)
        }}
        name="birthdate"
        label="שנת לידה"
        placeholder="שנת לידה"
        errorText={"שנה לא תקינה"}
        error={errors?.birthdate}
      />

      <SelectBox opts={genderOpts} name="gender" value={fields["gender"]}
        onChange={(ev) => handleChange(ev, idx)} />
      {maritalStatus == "married" && (
        <SelectBox opts={ownershipOpts} name="ownership" value={fields["ownership"]}
          onChange={(ev) => handleChange(ev, idx)} />
      )}

      <div className="flex align-center gap-5">

        {(maritalStatus !== "married" || fields["ownership"]) &&
          <>
            <label className="flex gap-5">
              <input
                type="checkbox"
                name="isAccountPossession"
                disabled={fields["isSingleParent"]}
                value={users[0].id}
                checked={
                  fields["isSingleParent"] || fields["isAccountPossession"]
                }
                onChange={(ev) => handleChange(ev, idx)}
              />
              <span>
                בחזקתי
              </span>
            </label>
            <label className="flex gap-5">
              <input
                type="checkbox"
                name="isSingleParent"
                checked={fields["isSingleParent"]}
                onChange={handleSingleParent}
              />
              <span>
                הורה יחידני
              </span>
            </label>
          </>
        }
      </div>

      <button
        className="delete-btn flex auto-center"
        onClick={() => remove(fields, idx)}
      >
        <DeleteIcon className="delete-icon" />
        <span className="bold">הסר</span>
      </button>
    </div>
  );
};
