"use strict"
import axios from "axios";
import { httpService } from "./http.service"
export const ENV_PRODUCTION = "production"
export const ENV_SATGE = "stage"
export const ENV_DEV = "development"

var zugiCash = null

export const externalService = {
    completion,
    fileUpdate,
    fileUpdateGet
};

async function completion({ token, userId, docs }) {
    try {
        const res = await httpService.post("completion", { token, userId, docs });
    } catch (err) {

    }
}

async function fileUpdate({ jwt, userId, file }) {
    try {
        const res = await httpService.post("file-update", { jwt, userId, file });
        return res;
    } catch (err) {
        throw err
    }
}

//validate the token
async function fileUpdateGet({ jwt, userId }) {
    try {
        const res = await httpService.post("file-update-get", { jwt, userId });
        return res;
    } catch (err) {
        throw err
    }
}


export async function getZugiText(isStage) {
    try {
        if (zugiCash) return zugiCash
        // console.log('not cash');
        const res = await axios({
            url: `https://admin.taxon.co.il/zugi`,
            method: 'get',
            params: { isStage }
        });
        const txtObj = res.data.reduce((acc, key) => {
            const data = {
                opened: key.open,
                closed: key.closed
            }
            return { ...acc, [key.varname]: data }
        }, {})
        zugiCash = txtObj
        // console.log(zugiCash);
        return txtObj
    } catch (err) {
        // console.log(err);
    }
}

export function getEnv() {
    switch (window.location.hostname) {
        case "localhost":
            return ENV_DEV;
        case "stage-my.taxon.co.il":
            return ENV_SATGE;
        case "my.taxon.co.il":
            return ENV_PRODUCTION;
        default:
            return ENV_PRODUCTION;
    }
}