import { NO, NOT_SURE, YES } from "../services/account.service"

// quests for personalSurvey
// IMPORTANT: the quest's name must have be the same name as the key in account's characterization 
export const quests = [
    {
        "question": "האם קיבלת דמי אבטלה, דמי לידה, יצאת לחל\"ת, החלפת מקום עבודה, או עבדת במקביל ביותר ממקום עבודה אחד?",
        "details": "",
        "name": "Marketing_question",
        "answers": [
            {
                "txt": "כן",
                "value": YES
            },
            {

                "txt": "לא",
                "value": NO
            },
            // {

            //     "txt": "לא בטוח",
            //     "value": NOT_SURE
            // }
        ],

    },
    {
        "question": "האם היה לך ביטוח פרטי ששילמת מכספך?",
        "details": "(ביטוח חיים, משכנתא, או אובדן כושר עבודה)",
        "name": "privateInsurance",
        "answers": [
            {

                "txt": "כן",
                "value": YES
            },
            {

                "txt": "לא",
                "value": NO
            },
            // {

            //     "txt": "לא בטוח",
            //     "value": NOT_SURE
            // }
        ],

    },
    {
        "question": "האם הפקדת כספים באופן עצמאי לפוליסת ביטוח, קופת גמל או קרן פנסיה?",
        "details": "(לא כולל קופות גמל להשקעה וקרן השתלמות)",
        "name": "providentFund",
        "answers": [
            {
                "txt": "כן",
                "value": YES
            },
            {
                "txt": "לא",
                "value": NO
            },
            // {
            //     "txt": "לא בטוח",
            //     "value": NOT_SURE
            // }
        ],

    },
    {
        "question": "האם שילמת מס על כספים שמשכת מקופת גמל, קרן השתלמות, קרן פנסיה, ביטוח מנהלים, או על משיכת כספי פיצויים?",
        "name": "withdrawal",
        "answers": [
            {

                "txt": "כן",
                "value": YES
            },
            {

                "txt": "לא",
                "value": NO
            },
            // {
            //     "txt": "לא בטוח",
            //     "value": NOT_SURE
            // }
        ],

    },
    {
        "question": "האם מימשת רווח או הפסד ממסחר בשוק ההון?",
        "name": "capitalMarket",
        "answers": [
            {

                "txt": "כן",
                "value": YES
            },
            {

                "txt": "לא",
                "value": NO
            }
        ],
    },
    {
        //TODO:getyear()-1
        "question": "האם סיימת לימודים במוסד אקדמי או לא-אקדמי בשנת 2015 ואילך?",
        "name": "diploma",
        "answers": [
            {

                "txt": "כן",
                "value": YES
            },
            {

                "txt": "לא",
                "value": NO
            }
        ]
    },
    {
        "question": "האם היו לך הכנסות מדמי שכירות עבור דירת מגורים שבבעלותך?",
        "name": "appartmentRent",
        "answers": [
            {

                "txt": "כן",
                "value": YES
            },
            {

                "txt": "לא",
                "value": NO
            }
        ]
    },
    {
        "question": "האם היו לך הכנסות מהימורים, הגרלות או פרסים?",
        "name": "gambling",
        "answers": [
            {

                "txt": "כן",
                "value": YES
            },
            {

                "txt": "לא",
                "value": NO
            }
        ]
    },
    {
        "question": "האם העברת תרומה למוסדות ציבור המוכרים לעניין תרומות?",
        "name": "donations",
        "answers": [
            {

                "txt": "כן",
                "value": YES
            },
            {

                "txt": "לא",
                "value": NO
            }
        ]
    },
    {
        "question": "האם התגוררת ביישוב מזכה?",
        "name": "beneficiarySettlement",
        "answers": [
            {

                "txt": "כן",
                "value": YES
            },
            {

                "txt": "לא",
                "value": NO
            }
        ]
    },
    {
        //TODO:getyear()-2
        "question": "האם השלמת שירות צבאי או שירות לאומי משנת 2015 ואילך?",
        "name": "militaryService",
        "answers": [
            {

                "txt": "כן",
                "value": YES
            },
            {

                "txt": "לא",
                "value": NO
            }
        ]
    },
    {
        "question": "האם שילמת מזונות?",
        "name": "spousalSupport",
        "answers": [
            {

                "txt": "כן",
                "value": YES
            },
            {

                "txt": "לא",
                "value": NO
            }
        ]
    },
    {
        "question": "האם היה לך מעמד של עולה חדש או תושב חוזר?",
        "name": "newImmigrants",
        "answers": [
            {

                "txt": "כן",
                "value": YES
            },
            {

                "txt": "לא",
                "value": NO
            }
        ]
    },
    {
        "question": "האם היה לך פטור מתשלום מס הכנסה בגין נכות או עיוורון?",
        "name": "disabled",
        "answers": [
            {

                "txt": "כן",
                "value": YES
            },
            {

                "txt": "לא",
                "value": NO
            }
        ]
    },
    {
        "question": "האם ילדך הוגדר נטול יכולת או סובל מלקות למידה? ",
        "name": "disabledChild",
        "answers": [
            {

                "txt": "כן",
                "value": YES
            },
            {

                "txt": "לא",
                "value": NO
            }
        ]
    },
    {
        "question": "האם היה עליך לממן החזקת הורה או בן/בת זוג במוסד מיוחד?",
        "name": "institutionSpouse",
        "answers": [
            {

                "txt": "כן",
                "value": YES
            },
            {

                "txt": "לא",
                "value": NO
            }
        ]
    }
]

export const questsLength = quests.length