import React from 'react'
import { useState } from 'react'
import { utilService } from '../../../services/util.service'
import { TextBox } from '../../comon/TextBox'
import { Paperclip } from "../../../assets/icons";
import Joi from "joi";
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../../store/reducers/siteReducer';



export const MiscFields = ({ addFile, form }) => {
    const schema = {
        docName: Joi.string()
            .required()
            .regex(/^[אבגדהוזחטיכלמנסעפצקרשתץףןםך״׳-\s.]+$/),
    }

    const [formFields, setFormFields] = useState({ docName: '', file: '' })
    const [errors, setErrors] = useState({})
    const [isDisabled, setIsDisabled] = useState(true)
    const disptch = useDispatch()

    const addFields = async ({ target }) => {
        let file;
        if (8388608 < target.files[0].size) {
            file = await utilService.resizeFile(target.files[0])
        } else {
            file = await utilService.toBase64(target.files[0]);
        }

        setFormFields((prev) => ({ ...prev, file }))
        return { docName: formFields.docName, file }
    }

    const handleFile = async (ev) => {
        try {
            disptch(setIsLoading(true))
            const file = await addFields(ev)
            await addFile(file)
            setFormFields({ docName: '', file: '' })
        } catch (err) {
            console.log(err);
        } finally {
            disptch(setIsLoading(false))
        }
    }

    const validateInput = ({ target }) => {
        const { name, value } = target
        const { error } = schema[name].validate(value);
        setErrors((prevError) => ({ ...prevError, [name]: error }))
        setIsDisabled(!!error)
        return !!error
    }

    const showMsg = () => {
        if (isDisabled) {
            toast.error("שם הקובץ לא תקין", {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
            });
        }
    }


    const handleChange = ({ target }, idx) => {
        validateInput({ target })
        setFormFields((prev) => ({ docName: target.value }))
    }

    const uploadBoxClassName = form.docs.length ? 'add-doc Uploaded' : "add-doc"
    const uploadBoxText = form.docs.length ? 'הוסף קובץ נוסף' : 'הוסף קובץ'

    return (<>
        <p>כאן ניתן להעלות כל מסמך רלוונטי נוסף להחזר המס שלך.
            <br />
            עליך להזין קודם את שם הקובץ, ולאחר מכן להעלות אותו וללחוץ אישור.</p>
        <br />
        <div className='flex align-center wrap'>
            <TextBox
                value={formFields["docName"]}
                type="text"
                onChange={(ev) => {
                    handleChange(ev)
                }}
                name="docName"
                label=" שם הקובץ"
                placeholder="שם הקובץ"
                errorText={"יכול להכיל רק אותיות בעברית"}
                error={errors?.docName}
            />
            <label className={uploadBoxClassName} tabIndex="0" onClick={showMsg}>
                <input
                    type="file"
                    name="file"
                    accept=".doc,.docx,.pdf,image/*"
                    onChange={(ev) => {
                        handleFile(ev)
                    }}
                    disabled={isDisabled}
                    hidden
                />
                <span>{uploadBoxText}</span>
                <span className="paperclip-icon">
                    <Paperclip />
                </span>
            </label>

        </div>
    </>
    )
}
