
const INITIAL_STATE = {
    userDetails: {
        firstName: null,
        lastName: null,
        email: null,
        tel: null,
        birthdate: null,
        id: null,
        license: null,
        passportNo: null,
        address: null,
        gender: null,
    },
    partnerDetails: {
        firstName: '',
        lastName: '',
        email: '',
        tel: '',
    },
    maritalStatus: {
        status: '',
        statusYear: null
    },
    agreements: {
        terms: null,
        newsletter: null,
    },
    docs: {
        shaam: {
            pdf: '',
            jpeg: ""
        },
        btl: {
            pdf: '',
            jpeg: ""
        }
    },
    hasChildren: '',
    userId: null,
    token: null,
    licenseImg: null,
    signature: null,
    partnerLink: null,
    ccData: {
        ccId: null,
        isOwner: null
    }
};

export function signUpReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "SET_SEARCH_PARMS":
            return {
                ...state,
                searchParams: action.searchParams,
                characterization: action.criteria
            };
            break;
        case 'LOAD_USER':
            const partner = { ...state.partnerDetails, ...action.partnerDetails }
            const user = { ...state.userDetails, ...action.userDetails }
            return {
                ...state,
                userDetails: user,
                partnerDetails: partner,
                token: action.token,
                userId: action.userId,
            };
        case "SET_LICENSE_DETAILS":
            var userDetails = state.userDetails
            if (action.licenseDetails) {
                userDetails = Object.keys(userDetails).reduce((acc, key) => {
                    acc[key] = state.userDetails[key] || action.licenseDetails[key]
                    return acc
                }, {});
            }
            return { ...state, licenseImg: action.licenseImg, userDetails }
        case "SET_TERMS":
            return { ...state, agreements: action.terms };
        case "SET_DETAILS":
            return { ...state, userDetails: action.details, docs: action.docs };
        case "SET_STATUS":
            return { ...state, ...action.status };
        case "SET_PARTNER_DETAILS":
            return { ...state, partnerDetails: action.partnerDetails }
        case "SET_SIGNATURE":
            return { ...state, signature: action.signature };
        case "SET_PARTNER_LINK":
            return { ...state, partnerLink: action.partnerLink };
        case "SET_CC_ID":
            return { ...state, ccData: { ...action.ccData } }
        default:
            return state;
    } 
};

