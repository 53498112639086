import React from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useCallbackPrompt } from '../../hooks/useCallcackPrompt'
import { authService } from '../../services/auth.service'
import { doLogout } from '../../store/actions/accountActions'
import { setIsShowWorningLogout } from '../../store/reducers/siteReducer'
import { ConfirmModal } from '../comon/ConfirmModal'

export const WarningLogout = () => {
    const dispatch = useDispatch()
    // const [showPrompt, confirmNavigation, cancelNavigation] =
    //     useCallbackPrompt(showDialog)

    useEffect(() => {
        const logoutTimeout = setTimeout(logout, 1000 * 60)
        // console.log(logoutTimeout);
        return () => {
            // console.log(logoutTimeout + " clear");
            clearTimeout(logoutTimeout)
        }
    }, [])

    const closeModal = () => {
        dispatch(setIsShowWorningLogout(false))
    }

    const logout = () => {
        // console.log("logout");
        dispatch(doLogout())
    }

    const sendPing = () => {
        authService.ping()
    }

    return (
        <ConfirmModal onClose={closeModal} onSubmit={logout} onCancel={sendPing}>
            {{
                body: <>
                    <div className='title'>המערכת עומדת לנתק אותך
                        <br />
                        לחץ "אישור" כדי להתנתק או "ביטול" כדי להשאר מחובר
                    </div>
                </>
            }}
        </ConfirmModal>
    )
}
