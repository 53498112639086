import { accountService } from "../../services/account.service";
import { authService } from "../../services/auth.service";
import { setLoggedinAccount, updateInfoSelection, infoAddDoc, updateCriteria, saveCriteria, infoRemoveDoc, logout, updateProductFieldRelevance, productIntent, updateValidCC, updateCart, updatePoa } from "../reducers/accountReducer";
import { setIsLoading } from "../reducers/siteReducer";
// import { toast } from "react-toastify";

export function loadLoggedinAccount() {
  return async (dispatch) => {
    const searchParams = new URLSearchParams(window.location.search)
    const token = searchParams.get("token")
    const userId = searchParams.get("userId")
    let params;
    if (token || userId) {
      params = {
        jwt: token,
        userId
      }
    }
    try {
      const loggedinAccount = await authService.getLoggedinAccount(params);
      if (!loggedinAccount) return
      dispatch(setLoggedinAccount(loggedinAccount));
    } catch (err) {
      throw err;
    } finally {
      // dispatch(setIsLoading(false))
    }
  };
}

export function loadCart() {
  return async (dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const cart = await accountService.loadCart()
      dispatch(updateCart(cart));
    } catch (err) {
      throw err
    } finally {
      dispatch(setIsLoading(false))
    }
  }
}

export function sendOtp(data) {
  return async () => {
    try {
      return authService.sendOtp(data)
    } catch (err) {
      throw err
    }
  }
}

/**
 *
 * @param {string} authCode | The code that the user get
 * @returns
 */
export function login(data) {
  return async (dispatch, getState) => {
    try {
      const loggedinAccount = await authService.login(data);
      dispatch(setLoggedinAccount(loggedinAccount));
    } catch (err) {
      throw err;
    }
  };
}

export function doLogout() {
  return async (dispatch, getState) => {
    authService.logout()
    dispatch(logout())
    window.location.pathname = "/login"
  }
}

export function updatePresonalQuiz(key, ans) {
  return async (dispatch, getState) => {
    const { info } = await getState().accountModule;
    const fallback = info[key]?.isSelected;
    dispatch(updateInfoSelection({ key, ans }));
    try {
      await accountService.updatePresonalQuiz(key, ans);
    } catch (err) {
      dispatch(updateInfoSelection({ key, ans: fallback }));
      throw err;
    }
  };
}

export function addDocToInfo(key, fileData) {
  return async (dispatch, getState) => {
    try {
      const { docId } = await accountService.addDocToInfo(key, fileData)
      fileData.docId = docId
      fileData.uploadedByAccount = true
      dispatch(infoAddDoc({ key, fileData }));
    } catch (err) {
      throw err
    }
  }
}

export function removeDocFromInfo(docId, key, idx) {
  return async (dispatch, getState) => {
    try {
      await accountService.removeDocFromInfo(docId)
      dispatch(infoRemoveDoc({ key, idx, docId }));
    } catch (err) {
      throw err
    }
  }
}

export function cancelCrit(key) {
  return async (dispatch, getState) => {
    try {
      await accountService.updateCrit(key, 0)
      dispatch(updateCriteria({ key, isSelected: 0 }));
    } catch (err) {
      throw err
    }
  }
}

export function addCrit(key, metaData) {
  return async (dispatch) => {
    try {
      await accountService.updateCrit(key, 1, metaData)
      dispatch(updateCriteria({ key, isSelected: 1 }));
    } catch (err) {
      throw err
    }
  }
}

export function auditPurchase(ccData) {
  return async (dispatch) => {
    try {
      await accountService.auditPurchase(ccData)
      dispatch(updateCriteria({ key: "characterization_confirmed", isSelected: 1 }));
    } catch (err) {
      throw err
    }
  }
}

export function submitCriteria(key, data) {
  return async (dispatch, getState) => {
    try {
      // accountService.saveCrit(key, data)
      const payload = await accountService.saveCrit(key, data)
      dispatch(saveCriteria({ key, data, children: payload }));
    } catch (err) {
      throw err
    }
  }
}

export function updateFieldRelevance(key, year, forUserId, isRelevant) {
  return async (dispatch, getState) => {
    try {
      if (!isRelevant) {
        const docs = await getState().accountModule.info[key].data.docs.filter(doc => doc.year == year && doc.forUser == forUserId);
        await _deleteManyDocs(dispatch, docs, key)
      }
      const payload = await accountService.updateProductFieldRelevance(key, year, forUserId, isRelevant);
      await dispatch(updateProductFieldRelevance({ key, year, forUserId, isRelevant }))
    } catch (err) {
      throw err
    }
  }
}

export function clientProductIntent(productId, year) {
  return async (dispatch, getState) => {
    dispatch(setIsLoading(true))
    try {
      const availableProducts = await accountService.clientProductIntent(productId)
      dispatch(productIntent({ year, availableProducts }))
    } catch (err) {
      throw err
    } finally {
      dispatch(setIsLoading(false))
    }
  }
}

export function validateCC(ccData) {
  return async (dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const isValid = await accountService.sendCC(ccData, true)
      if (isValid) {
        dispatch(updateValidCC())
      }
      return isValid
    } catch (err) {
      throw err;
    } finally {
      dispatch(setIsLoading(false))
    }
  }
}

/**
 *
 * @param {product} product
 * @returns
 */

export function addToCart(productIds, years) {
  return async (dispatch, getState) => {
    dispatch(setIsLoading(true))
    try {
      const { cart } = await accountService.addToCart(productIds);
      dispatch(updateCart({ cart, years }));
    } catch (err) {
      throw err;
    } finally {
      dispatch(setIsLoading(false))
    }
  };
}

export function removeFromCart(productIds, year) {
  return async (dispatch, getState) => {
    dispatch(setIsLoading(true))
    try {
      const { cart } = await accountService.removeFromCart(productIds);
      dispatch(updateCart({ cart, years: [year] }));
    } catch (err) {
      throw err;
    } finally {
      dispatch(setIsLoading(false))
    }
  };
}

export function sendPurchase(data) {
  return async (dispatch, getState) => {
    dispatch(setIsLoading(true))
    try {
      const isValid = await accountService.sendPurchase(data);
      if (isValid) {
        // dispatch(updateCart({ cart: [], productIds: data.productIds }))
        dispatch(loadLoggedinAccount())
      }
      return isValid
    } catch (err) {
      throw err;
    } finally {
      dispatch(setIsLoading(false))
    }
  };
}

export function getDocUrl(docId, reference) {
  return async (dispatch, getState) => {
    dispatch(setIsLoading(true))
    try {
      const { file, mimeType } = await accountService.getDocUrl(docId, reference)
      return { file, mimeType }
    } catch (err) {
      throw err;
    } finally {
      dispatch(setIsLoading(false))
    }
  };
}

export function updateUserId (idCrationDate, sibelingId) {
  return async (dispatch, getState) => {
    dispatch(setIsLoading(true))
    try {
      const res = await accountService.userIdUpdate(idCrationDate, sibelingId)
      dispatch(updatePoa(false));
      return res
    } catch (err) {
      throw err;
    } finally {
      dispatch(setIsLoading(false))
    }
  };
}

async function _deleteManyDocs(dispatch, docs, critKey) {
  try {
    for (var i = 0; i < docs.length; i++) {
      const doc = docs[i];
      await dispatch(removeDocFromInfo(doc.docId, critKey))
    }
  } catch (error) {
    throw error
  }
}

