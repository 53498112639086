import React from "react";
import { SearchZugi } from "../assets/svg-img/zugi";

export default function ErrorPage() {
  return (
    <section className="error-page flex column auto-center">
        <h1 className="error-h1">לינק שגוי אנא נסו שנית</h1>
      <div className="angry-zugi" >
        <SearchZugi />
      </div>
      <a className="home-btn" href="https://taxon.co.il/">לעמוד הבית</a>
    </section>
  );
}
