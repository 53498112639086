import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { RELEVANT_AND_HAS_DOC } from '../../../services/account.service'
import { addDocToInfo } from '../../../store/actions/accountActions'
import { TextBox } from '../../comon/TextBox'
import { UploadBox } from './UploadBox'

export const AppartmentRentFields = ({ form, setForm, metaData, years }) => {
  const dispatch = useDispatch();

  const yearLimit = {
    '2021': 5074 * 12,
    '2020': 5100 * 12,
    '2019': 5090 * 12,
    '2018': 5030 * 12,
    '2017': 5010 * 12,
    '2016': 5030 * 12
  }

  const [errors, setErrors] = useState({})

  const handleChange = (ev) => {
    var { name, value } = ev.target;
    setForm((prevForm) => ({
      ...prevForm,
      years: { ...prevForm.years, [name]: value },
    }));
    if (value > yearLimit[name]) {
      setErrors(pervErrors => ({ ...pervErrors, [name]: true }))
    } else {
      setErrors(pervErrors => ({ ...pervErrors, [name]: false }))
    }
  };

  const addFile = async (file, year) => {
    file.year = year
    dispatch(addDocToInfo('appartmentRent', file))
  }

  const handleFocus = (event) => event.target.select();

  return (
    <div className='appartment-rent'>
      <p>מהי ההכנסה השנתית שלך משכר דירה על נכסים שבבעלותך?</p>
      <div className='appartment-rent-fields'>

        {years.map(year =>
          <div className='rent-field' key={year.year}>
            {/* <React.Fragment key={year}> */}
            <TextBox
              type="number"
              name={year.year}
              label={`שנת ${year.year}`}
              key={year}
              value={form.years?.[year.year] || ''}
              errorText={"יש לצרף אישור תשלום מס במקור עבור שנה זו"}
              error={errors?.[year.year]}
              onChange={handleChange}
              onFocus={handleFocus}
            />
            <UploadBox addFile={(file) => addFile(file, year.year)} criteriaKey="appartmentRent"
              className={year.relevant ? 'add-doc Uploaded' : 'add-doc'}
              txt={year.relevant ? 'הוסף קובץ נוסף' : ''}
            />
            {/* </React.Fragment> */}
          </div>
        )}
      </div>
    </div>
  )
}
