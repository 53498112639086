import React from 'react'
import { useState } from 'react';
import { Chevron, Download, WatchEye } from '../../../assets/icons'
import { Accordion } from '../../comon/Accordion';
import { ArchiveDoc } from './ArchiveDoc';

export const DocsTitle = ({ titleStr, docs, watch, download, displayActions = true, displayBtnSign, onOpenSignUrl, users
    // userFirstNames
}) => {
    const [chvaronCalss, setChvaronClass] = useState('')


    // const addFirstNameToDocs = (docs, users) => {
    //     return docs.map(doc => {
    //         const matchingUser = users.find(user => user.userId === doc.forUserId);
    //         if (matchingUser) {
    //             return { ...doc, userFirstName: matchingUser.firstName };
    //         }
    //         return doc , console.log("doc inside the function :", doc);;
    //     });
    // };


    // const combineDocIdWithUserFirstName = (docs, users) => {
    //     // Create a new array by mapping over the 'docs' array
    //     const combinedArray = docs.map(doc => {
    //         // Find the user with matching 'userId' in 'users' array
    //         const user = users.find(user => user.userId === doc.forUserId);

    //         // If a matching user is found, combine 'docId' with 'userFirstName'
    //         if (user) {
    //             return {
    //                 docId: doc.docName ,
    //                 combinedName: `${doc.docName} - ${user.userFirstName}`
    //             };
    //         } else {
    //             // If no matching user is found, you can handle this case as needed
    //             return {
    //                 docId: doc.docName,
    //                 combinedName: `${doc.docName} - User Not Found`
    //             };
    //         }
    //     });

    //     return combinedArray;
    // };

    // const combineDocIdWithUserFirstName = (docs, users) => {
    //     const combinedArray = [];

    //     for (const doc of docs) {
    //         const matchingUser = users.find(user => user.userId === doc.forUserId);
    //         if (matchingUser) {
    //             combinedArray.push(matchingUser.firstName);
    //             doc.docName = matchingUser.firstName;
    //         }
    //         console.log("combinedArray :", combinedArray);
    //         return matchingUser;
    //     }
    // }
    // const combinedArray = combineDocIdWithUserFirstName(docs, users);
    // console.log("combinedArray :", combinedArray);

    const addFirstNameToDocs = (docs, users) => {
        // Create a copy of the 'docs' array to avoid modifying the original array
        const updatedDocs = [...docs];
      
        // Iterate over the 'docs' array and update the 'userFirstName' property
        updatedDocs.forEach(doc => {
          const matchingUser = users?.find(user => user.userId === doc.forUserId);
          if (matchingUser) {
            // updatedDocs.push ()
            doc.userFirstName = matchingUser.firstName;
          }
        });
      
        return updatedDocs;
      };
    //   console.log(users);
      const updatedDocs = addFirstNameToDocs(docs, users);
    //   console.log("updatedDocs :",updatedDocs);

    // {console.log("userFirstNames :",userFirstNames)}
    return (
        <div className="preview">
            <Accordion
                onCollapsed={(isCollapsed) => setChvaronClass(isCollapsed ? '' : 'rotate-180')}
                autoCollapsedDependency={[docs]}
            >
                {{
                    summary: <>
                        <div className='doc-list-title flex'>
                            <p>{titleStr}</p>
                            {/* {displayBtnSign && <button onClick={onOpenSignUrl}> לחתימה </button>} */}
                            <div className="icon-container flex auto-center">
                                <Chevron className={'chevaron ' + chvaronCalss} />
                            </div>
                        </div>
                    </>,
                    details: <>
                        <div className='doc-list-body'>
                            <div className='spacer'></div>
                            {docs.map(d => <React.Fragment key={d.docId}
                            >
                                <div className='preview doc-preview'  >
                                    <p>
                                        {d.displayName}
                                        <span> של </span>
                                        {d.userFirstName}

                                        {/* {updatedDocs.map(item=>(
                                              <span key={item.docId}>
                                                {item.userFirstName}
                                              </span>
                                        ) )} */}

                                        {/* {ArchiveDoc.findUser(d.userId).firstName} */}
                                        {/* {combinedArray.map(item=>(
                                           <span key={item.docId}>
                                            {item.combinedArray}
                                           </span> 
                                        ))} */}
                                        {/* {combinedArray.map((firstName, index) => (
                                            <span key={index}>
                                                {firstName}
                                            </span>
                                        ))} */}
                                        {/* {userFirstNames} */}
                                        {/* {()=>addFirstNameToDocs} */}
                                    </p>
                                    {displayActions && <div className='btn-container'>
                                        {/* {<div className='btn-container'> */}
                                        {/* {console.log(displayActions)} */}
                                        <button onClick={() => watch({
                                            ...d, isPurchased: displayActions,
                                            // userFirstNames
                                        })}>
                                            צפייה
                                            <WatchEye />
                                        </button>
                                        <button onClick={() => download({
                                            ...d, isPurchased: displayActions,
                                            // userFirstNames 
                                        })}>
                                            הורדה
                                            <Download />
                                        </button>
                                    </div>}
                                </div>
                            </React.Fragment>)}
                        </div>
                    </>
                }}
            </Accordion>
        </div>
    )
}