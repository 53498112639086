import React from "react";
import Form from "../comon/Form";
import { eventBus } from "../../services/eventBus.service";
import { ArrowLeft, MailIcon, PhoneIcon, MaleIcon, FemaleIcon } from "../../assets/icons";
import { connect } from "react-redux";
import { setDetails } from "../../store/actions/signUpActions"
import { toast } from "react-toastify";
import { ConfirmModal } from "../comon/ConfirmModal";

class _SignUp extends Form {
  state = {
    form: {
      firstName: this.props.userDetails.userDetails.firstName,
      lastName: this.props.userDetails.userDetails.lastName,
      birthdate: this.props.userDetails.userDetails.birthdate,
      id: this.props.userDetails.userDetails.id,
      license: this.props.userDetails.userDetails.license,
      address: this.props.userDetails.userDetails.address,
      email: this.props.userDetails.userDetails.email,
      tel: this.props.userDetails.userDetails.tel,
      gender: this.props.userDetails.userDetails.gender,
    },
    isLoading: false,
    errorModal: null
  };


  componentDidMount() {
    eventBus.setZugi({ textKey: "signup" });
  }
  componentWillUnmount() {
  }

  genderHandleChange = ({ target }) => {
    const { form, errors } = this.state;
    this.setState({
      form: {
        ...form,
        gender: target.value,
      },
      errors: {
        ...errors
      },
    });
  };

  doSubmit = async () => {
    // localStorage.setItem("userDetails", JSON.stringify(this.state.form));

    try {
      await this.props.setDetails(this.state.form)
      this.props.nextStep()
    } catch (err) {
      const { response } = err
      // console.dir(response);
      toast.error(response.data, {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
        autoClose: 3000,
      });
    }
  };

  render() {
    const { errorModal } = this.state
    return <>
      {!!errorModal && <ConfirmModal cancelable={false} submitText={"סגירה"} onSubmit={errorModal.onSubmit}>
        {{
          body: <>
            {errorModal.errTxt}
          </>
        }}
      </ConfirmModal>
      }
      <section className="sing-up">
        <div className="center">
          <h1>יש לנו את זה!</h1>
          <p>
            הקובץ עלה למערכת.
          </p>
          <p>
            כעת עליך רק למלא פרטים אישיים, כמו שהם מופיעים ברישיון או בדרכון
            שלך.
          </p>
          {/* <p>
            <b>מצאת טעות בפרטים שמשכנו? </b> זה הזמן לתקן.
          </p> */}
          <p>
            <b>סיימת?</b> מעולה, אפשר ללחוץ "אישור".
          </p>
        </div>
        <div className="form">
          {this.renderInput({
            name: "firstName",
            type: "text",
            label: "שם פרטי",
            errorText: "כפי שמופיע ברישיון נהיגה/דרכון שלך",
            value: this.state.form.firstName,
          })}
          {this.renderInput({
            name: "lastName",
            type: "text",
            label: "שם משפחה",
            errorText: "כפי שמופיע ברישיון נהיגה/דרכון שלך",
            value: this.state.form.lastName,
          })}
          {this.renderDateInputMask({
            name: "birthdate",
            label: "תאריך לידה",
            type: "tel",
            errorText: "כפי שמופיע ברישיון נהיגה/דרכון שלך",
            value: this.state.form.birthdate,
          })}
          {this.renderInput({
            name: "id",
            label: "מספר תעודת זהות",
            type: "tel",
            errorText: "כפי שמופיע ברישיון נהיגה/דרכון שלך",
            maxLength: "9",
            value: this.state.form.id,
          })}
          {this.renderInput({
            name: "license",
            label: "מספר רישיון/דרכון",
            type: "tel",
            errorText: "כפי שמופיע ברישיון נהיגה/דרכון שלך",
            maxLength: "9",
            value: this.state.form.license,
          })}
          {this.renderInput({
            name: "email",
            type: "email",
            errorText: "המייל לא תקין, נא להקליד שוב",
            icon: <MailIcon className="inputPic" />,
            label: "כתובת מייל",
            value: this.state.form.email,
          })}
          {this.renderInput({
            name: "address",
            label: "כתובת למשלוח דואר",
            type: "text",
            errorText: "כפי שמופיע ברישיון נהיגה/דרכון שלך",
            value: this.state.form.address,
          })}
          {this.renderInput({
            name: "tel",
            type: "tel",
            errorText: "מס׳ הטלפון לא תקין, נא להקליד שוב",
            icon: <PhoneIcon className="inputPic" />,
            label: "טלפון נייד",
            maxLength: "10",
            value: this.state.form.tel,
            onBlur: this.handleBlur
          })}
        </div>
        <div className="btns flex space-between">
          <div className="">
            <div className="gender flex">
              <input type="radio" name="gender" id="male" value="male"
                checked={this.state.form.gender === "male"}
                onChange={this.genderHandleChange} hidden />
              <label htmlFor="male">
                <span><MaleIcon /></span>
                <span>גבר</span>
              </label>
              <input type="radio" name="gender" id="female" value="female"
                checked={this.state.form.gender === "female"}
                onChange={this.genderHandleChange} hidden />
              <label htmlFor="female">
                <span><FemaleIcon /></span>
                <span>אישה</span>
              </label>
            </div>
            <p className="comment">בחירה זו תסייע לנו לחשב את מלוא החזר המס המגיע לך</p>

          </div>
        </div>
        <section className="flex space-between btns">
          <button className="back" onClick={() => {
            this.props.prevStep();
          }}>
            <ArrowLeft className="rotate-180" />
            חזור
          </button>
          <button className="submit" id="btnSubmit"
            onClick={(e) => {
              this.handleSubmit(e, this.state.form)
            }
            }
          >
            אישור
          </button>
        </section>
      </section>
    </>
  }
}

const mapDispatchToProps = {
  setDetails,
}

export const SignUp = connect(null, mapDispatchToProps)(_SignUp)