import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { HappyZugi, MainZugi, ZugiX } from "../../assets/svg-img/zugi";
// import { zugiText } from "../../data/zugiText";
import { eventBus } from "../../services/eventBus.service";
import { getZugiText } from "../../services/external.service";

export const Zugi = ({ type }) => {
  const [readMore, setReadMore] = useState(false);
  const [isOpenText, setIsOpenText] = useState(true);
  const [zugiText, setZugiText] = useState(null);
  const [fullText, setFullText] = useState(null);
  const [prevText, setPrevText] = useState(null);
  const isMobile = window.innerWidth < 1920;

  const resetZugi = () => {
    setReadMore(false);
    setIsOpenText(true);
  };

  useEffect(() => {
    const stopZugiListener = eventBus.on("zugi-msg", setZugi)
    const stopZugiReadMoreListener = eventBus.on("zugi-read-more", openReadMore)

    return () => {
      stopZugiReadMoreListener();
      stopZugiListener();
      closeText();
    };
  }, []);

  useEffect(() => {
    // closeText();
  }, [prevText]);

  const setZugi = async (data) => {
     // @PRODUCTION CHANGE before production STAGE=TRUE PROD=FALSE
    const txt = await getZugiText(false)
    // console.log('data', data);
    if (!data) {
      setFullText(null);
      setPrevText(null);
      return;
    }
    const { textKey } = data;
    // console.log(textKey, txt[textKey]);
    setFullText(txt[textKey].opened);
    setPrevText(txt[textKey].closed);
    resetZugi();
  };

  const closeText = (ev) => {
    ev?.stopPropagation();
    setIsOpenText(false);
    // if (isMobile) {
    //   setIsOpenText(false);
    // } else if (fullText) setReadMore(false);
  };

  const openText = (ev) => {
    ev.stopPropagation();
    setIsOpenText(true);
    if (fullText) setReadMore(true);
  };

  const openReadMore = () => {
    setReadMore(true);
  }

  const ZugiIcon = (props) => {
    switch (type) {
      case "happy":
        return <HappyZugi {...props} />;
      default:
        return <MainZugi {...props} />;
    }
  };

  if (!fullText && !prevText) return <div></div>;

  return (
    <div className="zugi-container pointer" id="zugi">
      {isOpenText && (
        <div
          className="zugi-bubble-text"
          onClick={() => {
            if (fullText) setReadMore((prevVal) => !prevVal);
          }}
        >
          <span className="x-mark" onClick={closeText}>
            <ZugiX />
          </span>
          {readMore ? <div className="content" dangerouslySetInnerHTML={{ __html: fullText }} />
            : <div className="content" dangerouslySetInnerHTML={{ __html: prevText }} />}
          {/* <div className="content" >{readMore ? fullText : prevText}</div> */}
        </div>
      )}

      <div className="zugi" onClick={openText}>
        <ZugiIcon />
      </div>
    </div>
  );
};
