import React, { useEffect, useRef, useState } from 'react'
import { DeleteIcon } from '../../assets/icons';

export const AlertModal = ({ children, closeModal, title, CloseIcon }) => {
    const titleRef = useRef();
    const modalRef = useRef();
    const [titleHeight, setTitleHeight] = useState(0);
    const [modalHeight, setModalHeight] = useState(0);

    useEffect(() => {
        setHeight();
        document.body.style.overflow = "hidden";
        window.addEventListener("resize", setHeight);
        return () => {
            document.body.style.overflow = "auto";
            window.removeEventListener("resize", setHeight);
        };
    }, []);

    const setHeight = () => {
        setModalHeight(getComputedStyle(modalRef.current).height)
        if (!titleRef.current) return;
        setTitleHeight(getComputedStyle(titleRef.current).height)
    };

    return (
        <div className='alert-modal modal-wrapper' onClick={closeModal}>
            <div className='inner-modal' onClick={e => e.stopPropagation()}>
                <button className="x-icon pointer" onClick={closeModal}>
                    <DeleteIcon style={{ fill: "var(--text-color)" }} />
                </button>
                <div className="modal" ref={modalRef}>
                    {(!!children.title || !!title) && <div className="modal-title" ref={titleRef}>{children.title || title}</div>}
                    <div className="modal-body" style={{ maxHeight: `calc(70vh - ${titleHeight} - 10px)` }}>
                        {children.body}
                    </div>
                </div>
            </div>
        </div>
    )
}
