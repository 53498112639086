import Form from "../comon/Form";
import { ArrowLeft, MailIcon, PhoneIcon } from "../../assets/icons";
import { eventBus } from "../../services/eventBus.service";
import { setPartnerDetails, setIsDup } from "../../store/actions/signUpActions"
import { connect } from "react-redux";
import { toast } from "react-toastify";

class _Married extends Form {
  state = {
    form: {
      firstName: "",
      lastName: "",
      email: "",
      tel: "",
    },
  };

  async doSubmit() {
    if (this.props.userDetails.userDetails.tel === this.state.form.tel) {
      toast.error("נא למלא את מספר הטלפון הנייד של בן/בת הזוג שלך", {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
        autoClose: 2000,
      });
      this.validatePhone()
      return
    }
    const { form } = this.state;
    const duped = await this.props.setIsDup(form);
    // console.log(duped);
    if (duped) {
      toast.error("אחד או יותר מהפרטים נמצאים במערכת", {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
        autoClose: 2000,
      });
      return;
    }

    this.props.setPartnerDetails(form)
    this.props.nextStep();
    try {
    } catch ({ response }) {
      if (response && response.status === 400) {
        this.setState({ errors: response.data });
      }
    }
  }

  validatePhone() {
    if (this.props.userDetails.userDetails.tel === this.state.form.tel) {
      const { form, errors } = this.state;
      this.setState({
        form,
        errors: {
          ...errors,
          tel: 'טעות',
        },
      });
    }
  }


  componentDidMount() {
    window.scrollTo(0, 0);
    eventBus.setZugi(null);
  }

  render() {
    return (
      <section className="partner">
        <div className="form-container">
          <div className="partnerText">
            <h1>
              קיבלנו את המידע, תודה
            </h1>
            <p className="stitle">
              {/* {this.props.userDetails.userDetails.firstName}, */}
              זוג שכירים (נשואים) חייבים להגיש יחד את הבקשה להחזר מס.
              <br />
              כדי שנוכל לטפל בהחזר המס של שניכם, עליך למלא את פרטי בן/בת זוגך,
              <br />
              ונשלח להם מייד קישור במייל ובוואטסאפ, להליך מקוצר שבו יתבקשו רק
              לאשר פרטים אישיים ולחתום על מסמכי ייצוג (שני בני הזוג צריכים לחתום
              בנפרד).
            </p>
          </div>
          <form
            className="flex column auto-center"
            onSubmit={(e) => this.handleSubmit(e, this.state.form)}
            noValidate
            autoComplete="off"
          >
            <h2 className="partner-details-h2 flex">פרטי בן/בת הזוג:</h2>
            <div className="form flex gap-5">
              {this.renderInput({
                name: "firstName",
                type: "text",
                label: "שם",
                errorText: "כפי שמופיע בתעודת זהות של בן/בת זוגך",
                value: this.state.form.firstName,
              })}

              {this.renderInput({
                name: "lastName",
                type: "text",
                label: "שם משפחה",
                errorText: "כפי שמופיע בתעודת זהות של בן/בת זוגך",
                value: this.state.form.lastName,
              })}
              {this.renderInput({
                name: "email",
                type: "email",
                errorText: "כתובת מייל לא תקינה, נסו שוב",
                icon: <MailIcon className="partner-input-icon" />,
                label: "כתובת מייל",
                value: this.state.form.email,
              })}
              {this.renderMobileInputMask({
                name: "tel",
                type: "tel",
                errorText: "   מס' הטלפון כבר נמצא במערכת, אנא הזן מס' שונה.  ",
                // errorText: "מס׳ טלפון לא תקין, נסו שוב",
               
                icon: <PhoneIcon className="partner-input-icon" />,
                label: "מס׳ טלפון",
                maxLength: "10",
                value: this.state.form.tel,
                onBlur: () => this.validatePhone()
              })}
            </div>
            <div className="partner-btns flex justify-center">
              <button
                onClick={() => this.props.prevStep()}
                className="back-btn flex auto-center"
              >
                <ArrowLeft className="back-arrow" />
                חזור
              </button>
              <button className="foward-btn flex auto-center">
                המשך
                <ArrowLeft className="foward-arrow" id="btnContimue" />
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  }
}
const mapDispatchToProps = {
  setPartnerDetails, setIsDup
}

export const Married = connect(null, mapDispatchToProps)(_Married)


