import React from 'react'
import { useEffect } from 'react';
import { ArrowLeft } from '../../../assets/icons';
import { eventBus } from '../../../services/eventBus.service';

export const IntroPersonalSurvey = ({ startSurvey, userName, years }) => {
    /*
     * 
     * @returns  the range of years the user can get our service in string ex: 2021-2016
     */
    const getTime = () => {
        const today = new Date();
        let year = today.getFullYear();

        //checks if the user register after 15.11 of this year 
        if (new Date(`${today.getFullYear()}-11-15`) <= today) {
            return `${year - 5}-${year - 1}`
        }

        // check if today is after 1.3
        if (today.getMonth() >= 2) return `${year - 6}-${year - 1}`


        return `${year - 6}-${year - 2}`
    }

    eventBus.setZugi({ textKey: `personal_quest_intro` })
    useEffect(() => {
        return () => {
            eventBus.setZugi(null)
        }
    }, [])

    return (
        <section className='survey-intro personal-survey-layout'>
            <h1 className='title' >היי  {userName},</h1>
            <div className='content'>

                <p>עליך להשלים שאלון קצר, שעשוי להשפיע על החזר המס שלך.</p>
                <br />
                <p>השאלות מתייחסות לשנים {getTime()} לכל הפחות, אלא אם כן צוין אחרת. </p>
                <br />
                <p>
                    <b>לנשואים: </b>
                    השאלון מתייחס לשני בני הזוג.</p>
                <br />
                <p>בכל שאלה, צריך פשוט לסמן כן/לא, והמערכת כבר תרכז עבורך את כל הנתונים.</p>
                <br />
                <br />
            </div>

            <button className="start-btn" onClick={() => startSurvey(0)}>בואו נתחיל</button>
        </section>
    )
}
