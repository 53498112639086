import React, { useEffect, useState } from "react";
import { ArrowDropDown } from "../../../assets/icons";
import { CriteriaDetails } from "./CriteriaDetails";
import { criteriaData, criteriaOptions } from "../../../data/criteria";
import { useAccordion } from "../../../hooks/useAccordion";
import { CheckMark } from "../../../assets/icons";
import { eventBus } from "../../../services/eventBus.service";
import { ConfirmModal } from "../../comon/ConfirmModal";
import BaseJoi, { number, object } from "joi";
import JoiDate from "@joi/date";
import {
  NOT_RELEVANT,
  RELEVANT_AND_HAS_DOC,
  RELEVANT,
  accountService,
} from "../../../services/account.service";
import { useDispatch } from "react-redux";
import { addCrit, auditPurchase } from "../../../store/actions/accountActions";
import { useNavigate } from "react-router-dom";
import { CcCart } from "../cart/CcCart";
import { setIsLoading } from "../../../store/reducers/siteReducer";
import { toast } from "react-toastify";

export const Criteria = ({
  connectedUser,
  partner,
  isViewMode,
  availableProducts,
  info,
  personalFiles,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedCrit, setSelectedCrit] = useState(null);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isPayModalOpen, setIsPayModalOpen] = useState(false);
  const [isGiveUpModalOpen, setIsGiveUpModalOpen] = useState(false);
  const [isExplanModalOpen, setIsExplanModalOpen] = useState(false);
  const [price, setPrice] = useState("");
  const [accoContentRef, isCollapsed, toggelCollapse, contentHeight] =
    useAccordion(false);

  var Joi = BaseJoi.extend(JoiDate);
  const childrenSchema = {
    id: Joi.optional(),
    name: Joi.string()
      .required()
      .min(2)
      .regex(/^[אבגדהוזחטיכלמנסעפצקרשתץףןםך״׳-\s.]+$/),
    birthdate: Joi.date().required().format(["YYYY-MM-DD", "DD-MM-YYYY"]).utc(),
    gender: Joi.required(),
    ownership: Joi.any().optional(),
    isSingleParent: Joi.any().optional(),
    isAccountPossession: Joi.any().optional(),
  };
  //Divide the criteriaOptions to activeCriteria and inactiveCriteria
  const { activeCriteria, inactiveCriteria } = criteriaOptions.reduce(
    (acc, crit) => {
      // checks if the user select this crit (isSelected===1) and add it to activeCriteria
      if (+info[crit.key]?.isSelected) {
        acc.activeCriteria.push(crit);
      }
      // add to activeCriteria defualt crits
      else if (
        crit.key === "tofes106" ||
        crit.key === "BTL" ||
        crit.key === "bankAccount"
      ) {
        acc.activeCriteria.push(crit);
      } else acc.inactiveCriteria.push(crit);
      return acc;
    },
    { activeCriteria: [], inactiveCriteria: [] }
  );

  const years = Object.keys(availableProducts || {});

  //checking if the year is perchable
  const activeYears = years.reduce((acc, year) => {
    // if (availableProducts[year].users.length) acc = [...acc, year]
    if (
      availableProducts[year].isPurchasable &&
      availableProducts[year].users.length &&
      availableProducts[year].proStatus.id == 1
    )
      acc = [...acc, year];
    return acc;
  }, []);

  useEffect(() => {
    loadZugi();
  }, [selectedCrit, isViewMode]);

  // useEffect(() => {
  //   return () => {
  //     eventBus.setZugi({ textKey: `criteria_default` });
  //   };
  // });

  const loadZugi = () => {
    if (isViewMode) {
      if (!info.characterization_confirmed.isSelected) {
        eventBus.setZugi(
          selectedCrit ? null : { textKey: `criteria_default` }
        );
      } else {
        eventBus.setZugi(null);
      }
    } else if (!isViewMode && !info["characterization_confirmed"].isSelected) {
      eventBus.setZugi(
        selectedCrit ? { textKey: `criteria_${selectedCrit}` } : { textKey: `criteria_default` }
        // selectedCrit ? { textKey: `criteria_${selectedCrit}` } : { textKey: `criteria_default` }
      );
    } else {
      eventBus.setZugi(null);
    }
  };
  loadZugi();

  const submit = async () => {
    try {
      await dispatch(auditPurchase());
      window.scrollTo(0, 0);
    } catch (err) {
      err.message = "משהו השתבש...";
      throw err;
    }
  };
  //validate all categories by checking their key
  const validateCriteria = () => {
    var isHasAllData = activeCriteria.every(
      (criterion) =>
        criterion.key === "misc" || hasData(criterion.key, info[criterion.key])
    );
    return isHasAllData;
  };

  const currentDate = new Date();

  // Get the current year
  const currentYear = currentDate.getFullYear();

  // Subtract one year to get the previous year
  const lastYear = currentYear - 1;



  const openSubmitModal = async () => {

    if (validateCriteria()) {
      try {
        dispatch(setIsLoading(true))
        const quote = await accountService.auditQuote();
        if (quote.price == 0) {
          setIsSubmitModalOpen(true);
        }
        else {
          setPrice(quote.price)
          setIsPayModalOpen(true);
        }
      } catch (err) {
        toast.error('משהו השתבש...  ', {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          autoClose: 3000,
        });
      } finally {
        dispatch(setIsLoading(false))
      }

    } else setIsGiveUpModalOpen(true); //give up modal- if the user did not fill all slots
  }

  const closeSubmitModal = () => setIsSubmitModalOpen(false);
  const closeGiveUpModal = () => setIsGiveUpModalOpen(false);
  const closeExplanModal = () => setIsExplanModalOpen(false);
  const closePayModal = () => setIsPayModalOpen(false);

  const hasData = (key, formData) => {
    if (isViewMode) return false;
    if (key === "children") {
      return isChildrenValid(formData?.children || info[key]?.data?.children);
    }
    // chacks if has divorceContract
    if (key === "spousalSupport") {
      const divorceContract = info[key]?.data?.docs.find(
        (doc) => doc.fieldName === "spousalSupportContract"
      );
      if (!divorceContract) return false;
    }

    if (key === "appartmentRent") {
      return isRentsValid(formData?.years || info[key]?.data?.years);
    }

    if (key === "tofes106" || key === "BTL") {
      // if (key === "tofes106") {
      const activeYearDocs = info[key].data.docs.filter((doc) =>
        activeYears.includes(`${doc.year}`)
      );
      return activeYearDocs.every((doc) => doc.docId);
    }


    if (key === "bankAccount" || key === "misc" || key === "BTL") {
      return !!info[key]?.data?.docs?.length;
    }

    const hasDocs = !!info[key]?.data?.docs?.length;
    const hasData = isCritValid(key);

    return hasData;
    // return hasData && hasDocs;
  };

  const isCritValid = (key) => {
    if (!info[key]?.metaData) return false;
    //year loop
    for (var i = 0; i < activeYears.length; i++) {
      const year = activeYears[i];
      let usersRelevant = [];
      //users loop
      for (const userId in info[key].metaData[year]) {
        if (key === "beneficiarySettlement") {
          // checking if the user has a relevant docs for the spesiphic year
          // other wise its relevant but has no docs
          const hasDocs = info[key].data.docs.some(
            (d) => d.year == year && d.forUser == userId
          )
            ? 1
            : 0;
          //pushing the user only if: the user is relevant for the speciphic year by checking the array of the avalable products
          usersRelevant.push(
            availableProducts[year].users.find((id) => id == userId)
              ? 1
              : 0
          );
        }

        //chacks if the category is relevant and has docs
        let docsLength = info[key].data.docs.filter(
          (d) => d.year == year && d.forUser == userId
        ).length;

        if (
          info[key].metaData[year][userId] === RELEVANT &&
          !docsLength &&
          availableProducts[year].users.find((id) => id == userId)
        ) {
          return false;
        }
      }

      // Checks if there is proof
      if (key === "beneficiarySettlement") {
        let hasProof = info[key].data.docs.some(
          (doc) =>
            doc.fieldName === "beneficiarySettlementProof" && doc.year === year
        );
        let isAllUsersNotRelevat = usersRelevant.every(
          (isNotRelevat) => isNotRelevat === NOT_RELEVANT
        );
        // ONLY RELEVANT NO DOCS
        let hasRelevatUser = usersRelevant.some(
          (isRelevat) => isRelevat === RELEVANT
        );

        // WORKS THE SAME AS
        // if (isAllUsersNotRelevat || (!hasRelevatUser && hasProof)) {
        //   continue
        // } else return false
        if ((!hasProof && !isAllUsersNotRelevat) || hasRelevatUser)
         var a = 'a';
          // return false;
      }
    }

    return true;
  };

  const isChildrenValid = (children) => {
    if (!children) return false;
    return children.some((child) => {
      const { error } = Joi.object({ ...childrenSchema }).validate(child, {
        abortEaely: false,
      });
      return !error;
    });
  };

  const isRentsValid = (years) => {
    if (!years) return false;
    return Object.keys(years).some((key) => years[key]);
  };

  // ----------------------------------------------------------------------------------------------------------------------------------------------------------

  const isSixYears = (Object.keys(availableProducts).length === 6)
  const isfiveYears = (Object.keys(availableProducts).length <= 5)

  // ----------------------------------------------------------------------------------------------------------------------------------------------------------



  function hasBicycleHistoryBiggerThanOne(availableProducts) {
    for (const year in availableProducts) {
      if (availableProducts.hasOwnProperty(year) && availableProducts[year].bicycleHistory.length >= 1) {
        return true; // If at least one year has bicycleHistory with length >= 1
      }
    }
    return false; // If no year has bicycleHistory with length >= 1
  }
  // Call the function with your availableProducts object
  const isBicycleHistoryBiggerThenOne = hasBicycleHistoryBiggerThanOne(availableProducts);
  // console.log("isBicycleHistoryBiggerThenOne :",isBicycleHistoryBiggerThenOne); // This will be true or false based on the presence of bicycleHistory

  // ----------------------------------------------------------------------------------------------------------------------------------------------------------

  // כל השנים שוות באופניים ל-0
  // function hasBicycleHistoryBiggerThenZero(availableProducts) {
  //   for (const year in availableProducts) { //divide the avalibleProduct by a property that i call years wich is effectivly the entries of the avalibleProduct object
  //     if (availableProducts.hasOwnProperty(year) && availableProducts[year].bicycleHistory.length >= 1) {
  //       return true; // If at least one year has bicycleHistory Equals To Zero
  //     }
  //   }
  //   return false; // If no year has bicycleHistory Equals To Zero
  // }
  // // Call the function with your availableProducts object
  // const isBicycleHistoryBiggerThenZero = hasBicycleHistoryBiggerThenZero(availableProducts);
  //  console.log("isBicycleHistoryEqualsToZero :",isBicycleHistoryEqualsToZero); // This will be true or false based on the presence of bicycleHistory

  // ----------------------------------------------------------------------------------------------------------------------------------------------------------

  function hasYearWithExpectedReturnNotNull(availableProducts) {
    for (const year in availableProducts) {//divide the avalibleProduct by a property that i call years wich is effectivly the entries of the avalibleProduct object
      if (availableProducts.hasOwnProperty(year) && availableProducts[year].expectedReturn === typeof number) {
        return true;
      }
    }
    return false;
  }
  // Usage
  const hasExpectedReturnNotNull = hasYearWithExpectedReturnNotNull(availableProducts);
  // console.log("hasExpectedReturnNotNull :", hasExpectedReturnNotNull);

  // ----------------------------------------------------------------------------------------------------------------------------------------------------------

  function hasProStatusBiggerThenTwo(availableProducts) {
    for (const year in availableProducts) { //divide the avalibleProduct by a property that i call years wich is effectivly the entries of the avalibleProduct object
      if (availableProducts.hasOwnProperty(year) && availableProducts[year].proStatus.id > 2) {//Check if there is at least one proStatus.status >=2
        return true;
      }
    }
    return false;
  }
  // Usage
  const isProStatusBiggerThenTwo = hasProStatusBiggerThenTwo(availableProducts);

  // ----------------------------------------------------------------------------------------------------------------------------------------------------------

  function hasITag(availableProducts) {
    for (const year in availableProducts) { //divide the avalibleProduct by a property that i call years wich is effectivly the entries of the avalibleProduct object
      if (availableProducts.hasOwnProperty(year) && (availableProducts[year].itagId != null) || (availableProducts[year].itagId != false)) { //Check if there is at least one iTag
        return true;
      }
    }
    return false;
  }
  // Usage
  const isTherITag = hasITag(availableProducts);
  // ----------------------------------------------------------------------------------------------------------------------------------------------------------

  function hasAllItagFunc(availableProducts) {
    for (const year in availableProducts) { //divide the avalibleProduct by a property that i call years wich is effectivly the entries of the avalibleProduct object
      if (availableProducts.hasOwnProperty(year) && (availableProducts[year].itagId != null) || (availableProducts[year].itagId != false)) { //Check if there is at least one iTag
        return false;
      }
    }
    return true;
  }
  // Usage
  const hasAllItag = hasAllItagFunc(availableProducts);

  // ----------------------------------------------------------------------------------------------------------------------------------------------------------



  function hasNoIsPurchasableFalse(availableProducts) {
    for (const year in availableProducts) {
      if (availableProducts.hasOwnProperty(year) && (availableProducts[year].isPurchasable == false) || (availableProducts[year].isPurchasable == null)) {
        return true;
      }
    }
    return false;
  }
  // Usage
  const isNoIsPurchasableFalse = hasNoIsPurchasableFalse(availableProducts);

  // ----------------------------------------------------------------------------------------------------------------------------------------------------------

  function hasPurchasableTrue(availableProducts) {
    for (const year in availableProducts) {
      if (availableProducts.hasOwnProperty(year) && availableProducts[year].isPurchasable == true) {
        return true;
      }
    }
    return false;
  }
  // Usage
  const isPurchasableTrue = hasPurchasableTrue(availableProducts);

  // ----------------------------------------------------------------------------------------------------------------------------------------------------------


  // if (info["characterization_confirmed"]?.isSelected && availableProducts.bycleHistory.length>=1)///אופציה ה'                                                  V
  // else  if (info["characterization_confirmed"]?.isSelected && availableProducts.bycleHistory.length==0&&availableProducts.proStatus.status >=2 )///אופציה ד    V
  // else  if (info["characterization_confirmed"]?.isSelected && availableProducts.bycleHistory.length==0&&availableProducts.expectedReturn==null)//אופציה ג'     V
  // else  if (!info["characterization_confirmed"]?.isSelected && availableProducts.isPurchasable==false / null )//אופציה ב'  // לבדוק שכל ה itagType מסוג number                                     V
  // else  if (!info["characterization_confirmed"]?.isSelected && availableProducts.isPurchasable.hasOwnProperty==true && !isViewMode )//אופציה א'

  // console.log(" avalibleProducts :", availableProducts);
  // console.log("!isBicycleHistoryEqualsToZero :",!isBicycleHistoryEqualsToZero);
  // console.log("hasExpectedReturnNotNull :",hasExpectedReturnNotNull);
  // console.log(!info["characterization_confirmed"]?.isSelected);


  const introTxt = () => {
    if (!isViewMode && info["characterization_confirmed"]?.isSelected && isBicycleHistoryBiggerThenOne)//אופציה ה'
      return (
        <>
          <h1 className="title">
            {connectedUser.firstName},
          </h1>
          <div className="content">
            <p>
              ניתן לראות את תוצאות בדיקת הזכאות ולעקוב אחר סטאטוס הטיפול בשנים שרכשת בכל עת בעמוד " החזרי המס שלי"
            </p>
            <br />
            <br />
            <p className="bold"> בברכה צוות Tax:on!
            </p>
          </div>
        </>
      );
    // else if (isViewMode) // 
    //   return (
    //     <>
    //       <h1 className="title">תודה {connectedUser.firstName},</h1>
    //       <div className="content">
    //         <p>
    //           בעמוד זה, ריכזנו את כל תנאי הזכאות שציינת, ורלוונטיים להחזר המס
    //           שלך.
    //         </p>
    //         <br />
    //         <p>
    //           בלחיצה על כל קריטריון, תופיע דוגמה למסמך שיהיה עליך להעלות בהמשך,
    //           כשנדע בוודאות שאנחנו יכולים לטפל בהחזר המס שלך - כמובן שנודיע לך
    //           על כך בהקדם.
    //         </p>
    //         <br />
    //         <p>
    //           <b>רוצה להוסיף או לשנות משהו? </b>
    //           אין בעיה. פשוט לוחצים על הקריטריון הרלוונטי ולוחצים "אישור" כדי
    //           להוסיף אותו, או "אין לי" כדי למחוק אותו.
    //         </p>
    //       </div>
    //     </>
    // );


    else if (info["characterization_confirmed"]?.isSelected && !isBicycleHistoryBiggerThenOne && isProStatusBiggerThenTwo) // אופציה ד'

      return (
        <>
          <h1 className="title"> {connectedUser.firstName},</h1>
          <div className="content">
            <p>
              מערכת Tax:on השלימה עבורך בהצלחה את בדיקת הזכאות להחזר מס.
            </p>
            <br />
            <p>
              באפשרותך לצפות בתוצאות הבדיקה המלאות בלשונית "החזרי המס שלי", בתפריט מימין.
            </p>
            <br />
            <p>
              לשירותך,
            </p>
            <p>
              צוות Tax:on
            </p>
            <br />
          </div>
        </>
      );

    else if (isViewMode && info["characterization_confirmed"]?.isSelected && !isBicycleHistoryBiggerThenOne && !hasExpectedReturnNotNull) // אופציה ג'

      return (
        <>
          <h1 className="title"> {connectedUser.firstName}, השלמת את התהליך. כל הכבוד!</h1>
          <div className="content">
            <b>
              מה קורה עכשיו?
            </b>
            <br />
            <br />
            <p>
              השלמת את החלק שלך, והכדור עובר אלינו.
            </p>
            <br />
            <p>
              תוך 30 יום, מערכת Tax:on תשלים את בדיקת הזכאות, ותציג בפניך את התוצאות במלואן, לפי שנות מס.
            </p>
            <br />
            <p>
              עם קבלת התוצאות, ניתן יהיה לבחור בשנים שבהן מגיע לך החזר מס, ולהגיש אותן באופן אוטומטי לרשות המיסים, באמצעות המערכת הדיגיטלית של Tax:on, במחיר קבוע וידוע מראש .
            </p>
            <br />
            <p>
              נשתמע בקרוב!
            </p>
            <p>
              צוות Tax:on
            </p>
            <br />
          </div>
        </>
      );

    else if (!isViewMode && isSixYears && !isPurchasableTrue && isTherITag) // 1אופציה ב

      return (
        <>
          <h1 className="title"> {connectedUser.firstName},</h1>
          <div className="content">
            <p>

              מהמידע שמשכנו עבורך מרשות המיסים והמוסד לביטוח לאומי עולה כי לא מגיע לך החזר מס עבור אף אחת מהשנים הרלוונטיות.

            </p>
            <br />
            <p>

              באפשרותך לצפות בתוצאות הבדיקה בלשונית 'החזרי המס שלי', בתפריט מימין.

            </p>
            <br />
            <p>
              לכן, לא ניתן (ולא צריך) לבצע במסך זה כל פעולה נוספת.
            </p>
            <br />
            <p>

              לא נורא, נבדוק שוב בשנה הבאה! :)

            </p>
            <br />
          </div>
        </>
      );

    else if (!isViewMode && isfiveYears && !isPurchasableTrue && isTherITag) // 2אופציה ב
      return (
        <>
          <h1 className="title"> {connectedUser.firstName},</h1>
          <div className="content">
            <p>
              מהמידע שמשכנו עבורך מרשות המיסים והמוסד לביטוח לאומי  עולה כי לא מגיע לך החזר מס עבור אף אחת מהשנים הרלוונטיות.

            </p>
            <br />
          </div>
        </>
      );
    // else if (!isViewMode && !info["characterization_confirmed"]?.isSelected && isPurchasableTrue && !isViewMode) //אופציה א
    //   return (
    //     <>
    //       <h1 className="title">
    //         {connectedUser.firstName}, אנחנו מתקדמים נהדר לעבר החזר המס שלך 😎
    //       </h1>
    //       <div className="content">
    //         <p>

    //           <p>
    //             באפשרותך לצפות בתוצאות הבדיקה בלשונית 'החזרי המס שלי', בתפריט מימין.
    //           </p>
    //           <br />
    //           <p>
    //             <b>
    //               לידיעתך: &nbsp;
    //             </b>
    //             המידע לשנת המס {lastYear} עדיין לא זמין, לכן שנה זו טרם נבדקה. עד ה-31 באוגוסט נמשוך את המידע גם עבורה, ונעדכן כי ניתן לבדוק גם אותה.
    //           </p>
    //           <p>
    //             בינתיים, לא ניתן (ולא צריך) לבצע במסך זה כל פעולה נוספת.
    //           </p>
    //           <br />
    //           <p>
    //             לשירותך,
    //           </p>
    //           <p>
    //             צוות Tax:on
    //           </p>
    //         </p>

    //       </div>
    //     </>
    //   );
    else if (!isViewMode && !info["characterization_confirmed"]?.isSelected && isPurchasableTrue) //אופציה א
      return (
        <>
          <h1 className="title">
            {connectedUser.firstName}, אנחנו מתקדמים נהדר לעבר החזר המס שלך 😎
          </h1>
          <div className="content">
            <p>
              בעמוד זה, ריכזנו את כל תנאי הזכאות שציינת בשאלון, עבור השנים הרלוונטיות לבדיקה. כעת, עליך להעלות את המסמכים המתאימים לכל תנאי זכאות שסימנת בשאלון בתחילת התהליך.
            </p>
            <br />
            <p>
              {/* <b>רוצה להוסיף או לשנות משהו? </b> */}
              מערכת Tax:on מסמנת ב-V ירוק כל קריטריון שבחרת, והמסמכים עבורו נקלטו בהצלחה. כשבכל הקריטריונים שבחרת יופיע V ירוק, אפשר ללחוץ על הכפתור "סיימתי, אפשר לבצע את הבדיקה".
            </p>
            <br />
          </div>
        </>
      );
    else // if (isTherITag) // viewMode==1 לפני קבלת מידע 
      return (
        <>
          <h1 className="title"> {connectedUser.firstName},</h1>
          <div className="content">
            <p>
              בעמוד זה, ריכזנו את כל תנאי הזכאות שציינת, ורלוונטיים להחזר המס
              שלך.
            </p>
            <br />
            <p>
              בלחיצה על כל קריטריון, תופיע דוגמה למסמך שיהיה עליך להעלות בהמשך,
              כשנדע בוודאות שאנחנו יכולים לטפל בהחזר המס שלך - כמובן שנודיע לך
              על כך בהקדם.
            </p>
            <br />
            <p>
              <b>רוצה להוסיף או לשנות משהו? </b>
              אין בעיה. פשוט לוחצים על הקריטריון הרלוונטי ולוחצים "אישור" כדי
              להוסיף אותו, או "אין לי" כדי למחוק אותו.
            </p>
          </div>
        </>
      );


  };

  function hasPurchasableTrue(availableProducts) {
    for (const year in availableProducts) {
      if (availableProducts.hasOwnProperty(year) && availableProducts[year].isPurchasable == true) {
        return true;
      }
    }
    return false;
  }
  // Usage
  const isTherePurchasableTrue = hasPurchasableTrue(availableProducts);

  function hasITag(availableProducts) {
    for (const year in availableProducts) { //divide the avalibleProduct by a property that i call years wich is effectivly the entries of the avalibleProduct object
      if (availableProducts.hasOwnProperty(year) && availableProducts[year].itagId >= 10) { //Check if there is at least one iTag
        return true;
      }
    }
    return false;
  }
  // Usage
  const isHavingITag = hasITag(availableProducts);

  return (
    <section className="criteria main-dashboard-layout">
      {isSubmitModalOpen && (
        <SubmitModal
          userName={connectedUser.firstName}
          onClose={closeSubmitModal}
          onSubmit={submit}
        />

      )}
      {isGiveUpModalOpen && (
        <GiveUpModal
          userName={connectedUser.firstName}
          onClose={closeGiveUpModal}
        />
      )}
      {isExplanModalOpen && (
        <ExplanModal
          userName={connectedUser.firstName}
          onClose={closeExplanModal}
        />
      )}
      {selectedCrit && (
        <CriteriaDetails
          personalFiles={personalFiles}
          isViewMode={isViewMode}
          viewOnly={isViewMode && info.characterization_confirmed.isSelected}
          criteriaKey={selectedCrit}
          criteriaData={criteriaData[selectedCrit]}
          cirtData={info[selectedCrit]?.data}
          cirtMetaData={info[selectedCrit]?.metaData}
          metaData={availableProducts}
          validateCrit={hasData}
          connectedUser={connectedUser}
          partner={partner}
          onClose={() => setSelectedCrit(null)}
        />
      )}
      {isPayModalOpen && (
        <PayModal
          onClose={closePayModal}
          onSubmit={() => { }}
          price={price}
        />
      )}

      {introTxt()}
      {/* לקוח לא סגר וגם יש לו שנה לקנייה ויש לו אייטאג */}
      {/* {console.log("isTherePurchasableTrue :", isTherePurchasableTrue)} */}
      {!info.characterization_confirmed.isSelected && !(!isTherePurchasableTrue && hasAllItag) && (
        <>

          <div className="criteria-list flex">
            {activeCriteria.map((crit) => (
              <button
                className="criteria-preview flex align-center"
                onClick={() => setSelectedCrit(crit.key)}
                key={crit.key}
              >
                {/* check if has data if has show the check mark  */}
                {hasData(crit.key) && (
                  <span className="check-mark">
                    <CheckMark />
                  </span>
                )}
                <div className="img-container">{crit.icon}</div>
                <p className="bold">
                  {crit.title} <span className="subtitle">{crit.subtitle}</span>
                </p>
              </button>
            ))}
          </div>

          <button
            className="add-criteria-btn flex align-center bold pointer"
            onClick={toggelCollapse}
          >
            <span>הוספת תנאי זכאות</span>
            <span className="flex align-center">
              <ArrowDropDown
                className={`dropdown-transition ${isCollapsed ? "rotate-90" : ""
                  }`}
              />
            </span>
          </button>

          <div
            className={`criteria-list-container accordion`}
            style={{ maxHeight: contentHeight }}
          >
            <div className={`criteria-list inactive flex`} ref={accoContentRef}>
              {inactiveCriteria.map((crit) => {
                return (
                  <button
                    className="criteria-preview flex align-center"
                    onClick={() => setSelectedCrit(crit.key)}
                    tabIndex={isCollapsed ? -1 : 0} // when the acoordiom is colse the user can't focos this btn when tab is pressed
                    key={crit.title}
                  >
                    <div className="img-container">{crit.icon}</div>
                    <p className="bold">
                      {crit.title}{" "}
                      <span className="subtitle">{crit.subtitle}</span>
                    </p>
                  </button>
                );
              })}
            </div>
          </div>
          {isViewMode ? (
            <div
            //  className="submit flex align-center center"
            ></div>
            // <button
            //   className="submit flex align-center center"
            //   onClick={() => setIsExplanModalOpen(true)}
            // >
            //   <span>סיימתי</span>
            // </button>
          ) : (
            <button
              className="submit flex align-center center"
              onClick={openSubmitModal}
            >
              <span>סיימתי, אפשר לבצע את הבדיקה</span>
            </button>
          )}
        </>
      )}

    </section>
  );
};

const ExplanModal = ({ userName, onClose }) => {
  return (
    <ConfirmModal submitText={"הבנתי"} onClose={onClose} cancelable={false}>
      {{
        body: (
          <>
            <h2>{userName}, תודה שהשלמת את שאלון הזכאות להחזר מס</h2>
            <br />
            <p>
              בימים הקרובים נעדכן כי משכנו את הפרטים שלך מרשות המיסים והמוסד
              לביטוח לאומי, ונאפשר לך להעלות למערכת את כל המסמכים הרלוונטיים
              לביצוע בדיקת הזכאות להחזר מס.
            </p>
            <br />
            <p>
              <b>לידיעתך: </b>ניתן לבצע שינויים בעמוד תנאי הזכאות גם בהמשך, עד
              לשליחת השנה לבדיקה על ידך.
            </p>
            <br />
            <p>
              באפשרותך לצאת כעת מהמערכת, על ידי סגירת החלון או לחיצה על "יציאה"
              בסרגל הימני.
            </p>
            <br />
            <p className="bold">לשירותך,</p>
            <p className="bold">צוות Tax:on</p>
          </>
        ),
      }}
    </ConfirmModal>
  );
};

const PayModal = ({ onClose, onSubmit, price }) => {
  const dispatch = useDispatch();
  const applyPay = async (ccData) => {
    dispatch(setIsLoading(true))
    try {
      await accountService.auditPurchase(ccData);

    } catch (err) {

    } finally {
      dispatch(setIsLoading(false));
    }

  }
  return (<ConfirmModal onClose={onClose} onSubmit={onSubmit}>
    {{
      body:
        <>
          <div> לתשלום שלנו
          </div>
          <div style={{ width: "305px", margin: "0 auto" }}>
            <CcCart price={price} doPay={applyPay} />
          </div>
        </>
    }}
  </ConfirmModal>)
}
const SubmitModal = ({ userName, onClose, onSubmit }) => {
  return (
    <ConfirmModal onClose={onClose} onSubmit={onSubmit} >
      {{
        body:
          //  <>{isDone ? <p>טקטס ללא משלמים</p> : <p>טקסט למשלימים</p>}</>,
          <>
            <h2>{userName}, בטוח שלא שכחת כלום?</h2>
            <br />
            <p>
              עליך לאשר כי העלית למערכת את כל המסמכים הרלוונטיים לבדיקת זכאותך להחזר מס, כי המידע האישי שמוצג תחת הקריטריונים "טפסי 106 ו"ביטוח לאומי" שלם ומדויק, וכי בדיקת זכאותך להחזר מס תבוצע על בסיס מידע זה.
            </p>
            <br />
            <p>
              לאחר לחיצה על "אישור", מערכת Tax:on תבצע עבורך את בדיקת הזכאות להחזר מס.
            </p>
            <br />
            <p>
              <b>לידיעתך: </b>
              מערכת Tax:on מבצעת פעם אחת בחינם את בדיקת הזכאות. מחירה של בדיקה חוזרת הוא 50 ש"ח ליחיד, או 100 ש"ח לזוג.
            </p>
          </>
      }}
    </ConfirmModal>
  );
};

const GiveUpModal = ({ userName, onClose }) => {
  return (
    <ConfirmModal submitText={"הבנתי"} onClose={onClose} cancelable={false}>
      {{
        body: (
          <>
            <h2>{userName}, לא ניתן עדיין להעביר את התיק לבדיקה. </h2>
            <br />
            <p>
              מערכת Tax:on מסמנת ב-V ירוק כל קריטריון שבחרת, והמסמכים הנדרשים
              עבורו נקלטו בהצלחה.
            </p>
            <br />
            <p>
              רק כשבכל הקריטריונים שבחרת יופיע V ירוק, נוכל לבצע עבורך את בדיקת
              הזכאות להחזר מס.
            </p>
            <br />
            <p>
              אין לך את המסמכים? לחצת בטעות? אפשר ללחוץ על הקריטריון ולסמן "אין
              לי".
            </p>
          </>
        ),
      }}
    </ConfirmModal>
  );
};
