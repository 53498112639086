import React from 'react'
import { ProductList } from './ProductList'
import { QuestionIcon } from '../../../assets/icons'

export const ProductTable = ({ tableTitles, rowsData, openZugi, className, onShowDetails }) => {

    return (
        <div className={className ? "product-list " + className : "product-list "}>
            <ProductList
                openZugi={openZugi}
                tableTitles={tableTitles}
                rowsData={rowsData}
                onShowDetails={onShowDetails}
            >
                {/* render the table titles */}
                <div className="product-preview-titles bold">
                    {tableTitles.map((title, idx) =>
                        <div key={idx} className="flex column align-center col" onClick={() => openZugi(title.zugiKey)}>
                            {!!title.zugiKey && <div>
                                <QuestionIcon />
                            </div>}
                            <span>
                                {title.txt}
                            </span>
                        </div>
                    )}
                </div>
            </ProductList>
            
        </div>
    )
};