import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

export const ConfirmModal = ({
  children,
  onClose,
  onSubmit,
  onCancel,
  cancelText,
  submitText,
  submitable = true,
  cancelable = true,
  autoClose = true,
  
}) => {
  const titleRef = useRef();
  const modalRef = useRef();
  const [titleHeight, setTitleHeight] = useState(0);
  const [modalHeight, setModalHeight] = useState(0);
  const bodyMaxHeight = modalHeight - titleHeight;
  const userDetails = useSelector(state => state.signUpModule);

  useEffect(() => {
    setHeight();
    document.body.style.overflow = "hidden";
    window.addEventListener("resize", setHeight);
    return () => {
      document.body.style.overflow = "auto";
      window.removeEventListener("resize", setHeight);
    };
  }, []);

  const submit = async () => {
    try {
      if (onSubmit) {
        await onSubmit()
      }
      onClose();
    } catch (err) {
      toast.error(err.message, {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
        autoClose: 3000,
      });
      throw err
    }
  };

  const cancel = async () => {
    try {

      if (onCancel) await onCancel();
      if (autoClose) {
        onClose();
      }
    } catch (err) {
      toast.error(err.message, {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
        autoClose: 3000,
      });
      throw err
    }
  };
  
  const setHeight = () => {
    setModalHeight(getComputedStyle(modalRef.current).height)
    if (!titleRef.current) return;
    setTitleHeight(getComputedStyle(titleRef.current).height)
  };

  return (
    <section className='modal-warpper'>
      <div className="modal-container">
        <div className="modal" ref={modalRef}>
          {!!children.title && <div className="modal-title" ref={titleRef}>{children.title}</div>}
          <div className="modal-body" style={{ maxHeight: `calc(70vh - ${titleHeight} - 10px)` }}>
            {children.body}
          </div>
        </div>
        <div className='actions-btn flex space-between'>
          {cancelable && <button className='cancel' onClick={cancel}>{cancelText ? cancelText : 'ביטול'}</button>}
          {submitable && <button className='confirm' onClick={submit}>{submitText ? submitText : 'אישור'}</button>}
        </div>
      </div>
    </section>
  )
};
