import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Chevron } from "../../../assets/icons";
import { RoadProgressBar } from "./RoadProgressBar";
import { addToCart } from "../../../store/actions/accountActions";
import { Accordion } from "../../comon/Accordion";
import { useEffectUpdate } from "../../../hooks/useEffectUpdate";




export const ProductPreview = ({ rowData, tableTitles, openZugi, onShowDetails }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    setMobile()
    window.addEventListener("resize", setMobile);
    return () => {
      window.removeEventListener("resize", setMobile);
    };
  }, [])

  const addCart = (ev) => {
    ev.stopPropagation()
    const { product } = rowData
    if (product.added_to_cart) {
      navigate("/dashboard/cart");
    } else {
      dispatch(addToCart([product.productId], [product.productName]));
    }
  };
  const setMobile = () => {
    if (window.innerWidth < 650) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  };

  return isMobile
    ? <Mobile rowData={rowData} addToCart={addCart} tableTitles={tableTitles} openZugi={openZugi} onShowDetails={onShowDetails} />
    : <Dasktop rowData={rowData} addToCart={addCart} onShowDetails={onShowDetails} />

};


const Mobile = ({ rowData, tableTitles, openZugi, addToCart, onShowDetails }) => {
  const [chvaronCalss, setChvaronClass] = useState('')
  const cellsText = rowData.data
  const { product } = rowData

  const handleCollapsed = (isCollapsed) => {
    setChvaronClass(isCollapsed ? '' : 'rotate-180')
    onShowDetails()
  }

  return (
    <div className={"product-preview-container"}>
      <Accordion onCollapsed={handleCollapsed} disableToggel={Object.keys(cellsText).length <= 1}>
        {{
          summary: <div className="flex space-between align-center">
            <div>
              <p className="bold">שנת {rowData.year}</p>
              <p>{rowData.subtitleForMobile}</p>
            </div>
            {Object.keys(cellsText).length > 1 &&
              <Chevron className={"chevaron " + chvaronCalss} />}
          </div>,
          details:
            <section className="product-preview-wrapper" >
              <div className="product-preview-data">
                {Object.keys(cellsText).map((cellData, idx) => <React.Fragment key={idx}>
                  <div className="col quastion-mark" onClick={() => openZugi(tableTitles[idx].zugiKey)}>?</div>
                  <div className="col">
                    <span className="bold">{tableTitles[idx].txt}: </span>
                    <span>
                      {cellsText[cellData]}
                    </span>
                  </div>
                </React.Fragment>)}
              </div>
              {/* any chage here should do on desktop too */}
              {(product.isPurchasable && product.proStatus.id > 3 && product.proStatus.id < 7 && product.expectedReturn !== 0)
                &&
                <>
                  <button className="add-to-cart-btn pointer" onClick={addToCart}>
                    {product.added_to_cart ?
                      <span className="bold"> </span>
                      :
                      <span className="bold"> </span>
                    }
                  </button>
                </>
              }
              {/* any chage here should do on desktop too */}
              {product.proStatus.id > 6 && product.proStatus.id < 12 &&
                <RoadProgressBar statusHistory={product.bicycleHistory} cuurentStatusId={product.proStatus.id} productId={product.productId} productName={product.productName} />
              }
            </section>
        }}
      </Accordion>
    </div >
  )
};


const Dasktop = ({ rowData, addToCart, onShowDetails }) => {
  const [isOpen, setIsOpen] = useState(rowData.autoOpenProggresbar || false)
  useEffectUpdate(() => {
    // console.log('changed');
    onShowDetails()
  }, [isOpen])
  const { product } = rowData
  return (
    <div className="product-preview-wrapper">
      <span className="year">{rowData.year}</span>
      <div className="product-preview-container">
        <section className="product-preview">
          {Object.keys(rowData.data).map((cellData, idx) =>
            <div className="col" key={idx}>
              <span>
                {rowData.data[cellData]}
              </span>
            </div>)}
          {/* any chage here should do on mobile too */}
          {product.isPurchasable && product.proStatus.id > 3 && product.proStatus.id < 7
            ? <>
              {product.expectedReturn == 0
                ?
                <>
                  <div className="col has-no-return"></div>
                </>
                :
                <div className="col">
                  <button className="add-to-cart-btn pointer" onClick={addToCart}>
                    {product.added_to_cart ?
                      <span className="bold">ממתין לתשלום...</span>
                      :
                      <span className="bold">לרכישת דוח שנתי</span>
                    }
                  </button>
                </div>
              }
            </>
            : <>{!!product.purchaseDate
              && <div className="col read-more pointer" onClick={() => setIsOpen(!isOpen)}>
                <p>{product.proStatus.status}</p>
                <Chevron className={!isOpen && "rotate-90"} /> </div>
            }
            </>
          }
        </section>
        {/* any chage here should do on mobile too */}
        {
          isOpen && <RoadProgressBar statusHistory={product.bicycleHistory} cuurentStatusId={product.proStatus.id} productId={product.productId} productName={product.productName} />
        }
      </div>
    </div >
  )
}