import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ImgIcon } from '../assets/icons';
import { Uploaded } from '../assets/svg-img';
import { ZugiV } from '../assets/svg-img/zugi';
import Head from '../components/comon/header'
import { externalService } from '../services/external.service';
import { utilService } from '../services/util.service';
import { setIsLoading } from '../store/reducers/siteReducer';

export const Completion = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const jwt = searchParams.get("token")
    const userId = searchParams.get("userId")
    const text = searchParams.get("text")
    if (!jwt || !userId) navigate('/error')

    useEffect(() => {
        validateToken()
    }, [])

    const validateToken = async () => {
        try {
            dispatch(setIsLoading(true))

            await externalService.fileUpdateGet({ jwt, userId })
        } catch (err) {
            navigate('/error')
            toast.error(err.response.data, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                autoClose: 3000,
            });
            throw err
        } finally {
            dispatch(setIsLoading(false))
        }
    }

    const [openThankPage, setopenThankPage] = useState(false);

    const preventDefault = (event) => {
        event.preventDefault();
    };

    const resizeFile = async (file) => {
        let newFile;
        try {
            if (file.type !== 'application/pdf' && file.size > 2000000) {
                newFile = await utilService.resizeFile(file)
            }
            else if (file.size > 5000000) {
                throw new Error('קובץ גדול מדי')
            }
            else {
                newFile = await utilService.toBase64(file)
            }
            return newFile;
        } catch (err) {
            throw err
        }
    }

    const handleFileChange = async (e) => {
        e.preventDefault();
        let file;
        if (e.type === "change") file = e.target.files[0];
        else if (e.type === "drop") file = e.dataTransfer.files[0];
        if (file.type !== "image/jpeg" && file.type !== "application/pdf" &&
            file.type !== "image/png") {
            return toast.error('פורמט לא נתמך', {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                autoClose: 3000,
            });
        }

        try {
            dispatch(setIsLoading(true))
            const resizedFile = await resizeFile(file);
            await externalService.fileUpdate({ jwt, userId, file: resizedFile })
            setopenThankPage(true);
        } catch (err) {
            if (err.name !== "AxiosError") {
                toast.error(err.message, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    autoClose: 3000,
                });
            } else {
                toast.error('משהו השתבש...', {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    autoClose: 3000,
                });
            }
        } finally {
            dispatch(setIsLoading(false))
        }
    };


    if (openThankPage) return <Thanks />

    return (
        <>
            <div></div>
            <div>
                <Head />
                <div className='completion-page'>
                    {!!text &&
                        <>
                            <p>{text}</p>
                            <br />
                        </>
                    }
                    <div
                        className="upload-container"
                        onDragOver={preventDefault}
                        onDrop={handleFileChange}
                    >

                        <ImgIcon />


                        <h2>עליך פשוט ללחוץ על הכפתור "העלאת קובץ" ולבחור בצילום מהתיקייה
                            במחשב, או פשוט לגרור את הקובץ לכאן.</h2>

                        <label className="upload-btn">
                            העלאת קובץ
                            <input
                                type="file"
                                name="file"
                                accept=".jpeg, .jpg, .png, .pdf"
                                onChange={handleFileChange}
                                hidden
                            ></input>
                        </label>
                        <br />

                        <p className="import-b">
                            הפרטים ישמשו למטרת השירות בלבד
                            <br />
                            לעולם לא נמסור אותם לגורם חיצוני
                            ללא הסכמתך המפורשת
                        </p>

                    </div>
                </div>
            </div>
        </>
    )
}


const Thanks = () => {
    return (<>
        <div></div>
        <section className="boarding-page page">
            <Head>
            </Head>
            <section className="main-boarding-layout signatures-thank-page">
                <ZugiV />
                <h1>הקובץ עלה בהצלחה!</h1>
            </section>
        </section>
    </>
    )
}