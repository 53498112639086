import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { accountReducer } from "./reducers/accountReducer.js";
import { signUpReducer } from "./reducers/signUpReducer.js";
import { siteReducer } from "./reducers/siteReducer";
import { configureStore } from '@reduxjs/toolkit'


export const store = configureStore({
    reducer: {
        accountModule: accountReducer,
        signUpModule: signUpReducer,
        siteModule: siteReducer
    },
});