import React from 'react'
import { useSelector } from 'react-redux';

export const Spinner = ({ autoLoading = false }) => {
  const isLoading = useSelector((state) => state.siteModule.isLoading);
  if (!isLoading && !autoLoading) return <div></div>

  return (
    <section className="spinner">
      <div className="spinner-container">
        <div className="loading-spinner"></div>
        <br />
        <p className='bold' style={{ fontSize: '1.5em' }}>עוד כמה רגעים...</p>
      </div>
    </section>)
}
