import React from 'react'
import { DateInputMask } from '../../comon/DateInputMask';
import { TextBox } from '../../comon/TextBox';
import { userFields, bankFields, addressFields, childrenFields } from '../../../data/inputData'
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SelectBox } from '../../comon/SelectBox';
import BaseJoi from "joi";
import JoiDate from "@joi/date";
const Joi = BaseJoi.extend(JoiDate);

export const UserDetails = ({ loggedinAccount, isViewMode }) => {
  const schema = {
    fristName: Joi.string()
      .required()
      .min(2)
      .regex(/^[אבגדהוזחטיכלמנסעפצקרשתץףןםך״׳-\s.]+$/),
    lastName: Joi.string()
      .required()
      .min(2)
      .regex(/^[אבגדהוזחטיכלמנסעפצקרשתץףןםך״׳-\s.]+$/),
    brithdate: Joi.date()
      .required()
      .format(["YYYY-MM-DD", "DD-MM-YYYY"]).utc(),
    email: Joi.string()
      .required()
      .email({ tlds: { allow: false } }),
    tel: Joi.string()
      .required()
      .regex(/^[0-9]{10}$/),
    street: Joi.string()
      // .required()
      .min(2)
      .pattern(/^[אבגדהוזחטיכלמנסעפצקרשתץףןם׳-\s.]+$/),
    houseNo: Joi.number().integer(),
    city: Joi.string().regex(/^[אבגדהוזחטיכלמנסעפצקרשתץףןםך״׳-\s.]+$/).required(),
    accountName: Joi.string().regex(/^[אבגדהוזחטיכלמנסעפצקרשתץףןםך״׳-\s.]+$/).required(),
    bankName: Joi.string().regex(/^[אבגדהוזחטיכלמנסעפצקרשתץףןםך״׳-\s.]+$/).required(),
    branchNo: Joi.number().integer().required(),
    accountNo: Joi.number().integer().required(),
  }

  const { users, address, bank, info } = loggedinAccount
  const { children } = info
  const [form, setForm] = useState({ children: [] })
  const [errors, setErrors] = useState({})
  const [fileds, setFileds] = useState([])
  const [title, setTitle] = useState('')
  const { data } = useParams();

  useEffect(() => {
    setForm({ children: [] })
    // if (data === 'bank') {
    if (false) {
      setTitle('פרטי חשבון בנק')
      setFileds(bankFields)
    } else if (data === 'partner') {
      setTitle('פרטי בן/בת זוג')
      setFileds([...userFields])
    } else if (data === 'children') {
      setTitle('פרטי הילדים')
      setFileds([...childrenFields])
    } else {
      setTitle('פרטים אישיים')
      setFileds([...userFields])
    }
// console.log(data);
    // else the console yell on me probbly something ASYNC
    setTimeout(buildForm)
    // buildForm()
    // return
  }, [data])

  const buildForm = () => {
    switch (data) {
      // case 'bank':
      //   addBankData()
      //   break
      case 'partner':
        addPartnerData()
        break
      case 'children':
        addChildrenData()
        break
      default:
        // case 'user':
        addLoggedinUserData()
        // addAddressData()
        break
    }
  }

  const addLoggedinUserData = () => {
    const loggedinUserData = userFields.reduce((acc, field) => {
      acc[field.name] = users[0][field.name] || undefined
      return acc
    }, {})
    setForm(pervForm => ({ ...pervForm, ...loggedinUserData }))
  }

  const addPartnerData = () => {
    const partnerData = userFields.reduce((acc, field) => {
      acc[field.name] = users[1][field.name] || undefined
      return acc
    }, {})
    setForm(pervForm => ({ ...pervForm, ...partnerData }))
  }

  const addBankData = () => {
    const bankData = bankFields.reduce((acc, field) => {
      acc[field.name] = users[1][field.name] || undefined
      return acc
    }, {})
    setForm(pervForm => ({ ...pervForm, ...bankData }))
  }

  // const addAddressData = () => {
  //   const addressData = addressFields.reduce((acc, field) => {
  //     acc[field.name] = users[1][field.name] || undefined
  //     return acc
  //   }, {})
  //   setForm(pervForm => ({ ...pervForm, ...addressData }))
  // }

  const addChildrenData = () => {
    // const childrenData = children.data.children.map(child => {
    //   return childrenFields.reduce((acc, filed) => {
    //     acc[filed.name] = child[filed.name]
    //     return acc
    //   }, {})
    // // })
    setForm(prevForm => ({ children: children.data.children }))
  }

  const handleChange = ({ target }) => {
    const { name, value } = target
    // validateInput(name, value)
    setForm(prevForm => ({ ...prevForm, [name]: value }))
  }

  const validateInput = ({ target }) => {
    const { name, value } = target
    const { error } = schema[name].validate(value);
    setErrors((prevError) => ({ ...prevError, [name]: error }))
  }
  const fake = () => { }

  return (
    <section className='user-details main-dashboard-layout'>
      <div className='user-details-title'>
        <h2>{title}</h2>
      </div>
      {data === "children" && form ?
        <div >
          {form.children.map((child, idx) => <ChildrenFields fields={child} handleChange={fake} idx={idx} key={idx} />)}
          {/* <ChildrenFields fields={form} handleChange={fake} idx={0} /> */}
        </div>
        : <div className='inputs-container'>
          {fileds.map(field => {
            return isViewMode ? <DynamicCmp {...field} key={field.name} value={form?.[field.name] || ''}
              onChange={handleChange} error={errors?.[field.name]} onBlur={validateInput} disabled={true} />
              : <DynamicCmp {...field} key={field.name} value={form?.[field.name] || ''}
                onChange={handleChange} error={errors?.[field.name]} onBlur={validateInput} />
          })}
        </div>}
      {data === "partner" && users[1]?.regLink && <><p>
        לינק להשלמת הרישום של {users[1].firstName}
      </p>
        <a
          target={"_blank"}
          rel="noreferrer"
          href={`https://api.whatsapp.com/send/?phone=972${users[1].mobile}&text=${users[1]?.regLink}`}
          className='bold' style={{ textDecoration: "underline" }}>
          באמצעות ה-WhatsApp
        </a>
      </>}
      {!isViewMode && <button className='submit-btn'>אישור</button>}
    </section>
  )
}

const DynamicCmp = (props) => {
  switch (props.boxType) {
    case "textBox":
      return <TextBox {...props} />;
    case "dateBox":
      return <DateInputMask {...props} />;
    case "selectBox":
      return <SelectBox {...props} />;
  }
};


const ChildrenFields = ({ fields, handleChange, idx, disabled = true }) => {
  var Joi = BaseJoi.extend(JoiDate);
  const users = useSelector(
    (state) => state.accountModule.users
  );

  const genderOpts = [{ value: '', label: 'בחר מין' },
  { value: 'male', label: 'זכר' },
  { value: 'female', label: 'נקבה' }
  ]

  const ownershipOpts = [{ value: '', label: 'ילד משותף' },
  { value: users[0].userId, label: `של ${users[0].firstName}` },
  { value: users[1]?.userId, label: `של ${users[1]?.firstName}` }
  ]

  const schema = {
    name: Joi.string()
      .required()
      .min(2)
      .regex(/^[אבגדהוזחטיכלמנסעפצקרשתץףןםך״׳-\s.]+$/),
    birthdate: Joi.date()
      .required()
      .format(["YYYY-MM-DD", "DD-MM-YYYY"]).utc(),
    gender: Joi.required()
  }

  const [errors, setErrors] = useState({})
  const maritalStatus = useSelector(
    (state) => state.accountModule.maritalStatus
  );

  const handleSingleParent = (ev) => {
    if (!ev.target.checked) return handleChange(ev, idx);
    handleChange(ev, idx);
    handleChange({ target: { name: "isAccountPossession", value: true } }, idx);
  };

  const validateInput = ({ target }) => {
    const { name, value } = target
    const { error } = schema[name].validate(value);
    setErrors((prevError) => ({ ...prevError, [name]: error }))
  }


  return (
    <div className="flex align-center children-fields">
      <TextBox
        value={fields["name"]}
        type="text"
        onChange={(ev) => {
          handleChange(ev, idx)
          validateInput(ev)
        }}
        name="name"
        label="שם"
        placeholder="שם"
        errorText={"שם לא תקין"}
        error={errors?.name}
        disabled={disabled}
      />
      <DateInputMask
        value={fields["birthdate"]}
        type="tel"
        onChange={(ev) => {
          handleChange(ev, idx)
          validateInput(ev)
        }}
        name="birthdate"
        label="שנת לידה"
        placeholder="שנת לידה"
        errorText={"שנה לא תקינה"}
        error={errors?.birthdate}
        disabled={disabled}
      />

      <SelectBox opts={genderOpts} name="gender" value={fields["gender"]}
        onChange={(ev) => handleChange(ev, idx)} disabled={disabled} />
      {maritalStatus === "married" && (
        <SelectBox opts={ownershipOpts} name="ownership" value={fields["ownership"]}
          onChange={(ev) => handleChange(ev, idx)} disabled={disabled} />
      )}

      <div className="flex align-center gap-5">

        {(maritalStatus !== "married" || fields["ownership"]) &&
          <>
            <label className="flex gap-5">
              <input
                type="checkbox"
                name="isAccountPossession"
                disabled={disabled || fields["isSingleParent"]}
                value={users[0].id}
                checked={
                  fields["isSingleParent"] || fields["isAccountPossession"]
                }
                onChange={(ev) => handleChange(ev, idx)}
              />
              <span>
                בחזקתי
              </span>
            </label>
            <label className="flex gap-5">
              <input
                type="checkbox"
                name="isSingleParent"
                checked={fields["isSingleParent"]}
                onChange={handleSingleParent}
                disabled={disabled}
              />
              <span>
                הורה יחידני
              </span>
            </label>
          </>
        }
      </div>
    </div>
  );
};
