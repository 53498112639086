import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuIcon, ShoppingCart } from "../../assets/icons";
import { Logo } from "../../assets/svg-img";
import { HeaderWarning } from "../userDashboard";
import { OpenMenuContext } from "../../context/OpenMenuContext";
import { ConfirmModal } from "../comon/ConfirmModal";

export const DashboardHeader = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const { setIsOpen } = useContext(OpenMenuContext);
  const loggedinAccount = useSelector(
    (state) => state.accountModule
  );

  const handleCartBtn = () => {
      navigate("/dashboard/cart")
   }
  

  return (<>

    <section className="dashboard-header flex  space-between align-center">
      <span
        className="menu-icon flex auto-center"
        onClick={() => {
          setIsOpen((prevVal) => !prevVal);
        }}
      >
        <MenuIcon />
      </span>

      <div></div>
      <section className="left-header flex align-center">
        {location.pathname === "/dashboard/products" &&
          <button
            className="my-cart-btn flex"
            onClick={
 
              handleCartBtn
     
            }
          >
            <ShoppingCart />
            <span>סל קניות</span>
            {loggedinAccount.cart.length ? (
              <span className="item-counter flex auto-center">
                {loggedinAccount.cart.length}
              </span>
            ) :
              <></>}
          </button>}

        <Logo />
      </section>
    </section>
  </>
  );
};
