import { useEffect } from "react";

/**
 *
 * @param {*} ref | ref of the element
 * @param {*} handler | what to do when clicked outside
 */
export const useOnClickOutside = (ref, handler) => {
  const zugi = document.getElementById("zugi");
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (
        !ref.current ||
        ref.current.contains(event.target) 
        // ||ref.current.contains(event.target) 
        // event.composedPath()?.contains(zugi)
      ) {
        return;
      }
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};
