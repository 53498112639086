import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { accountService, POA_TABLE, PRODUCT_DATA_TABLE, PRO_FIELS_TABLE } from '../../../services/account.service'
import { utilService } from '../../../services/util.service'
import { getDocUrl } from '../../../store/actions/accountActions'
import { DocsTitle } from './DocsTitle'
import { ConfirmModal } from '../../comon/ConfirmModal'
import { NavLink } from 'react-router-dom'
import { DeleteIcon } from '../../../assets/icons'
import { eventBus } from '../../../services/eventBus.service'
import { authService } from '../../../services/auth.service'


export const ArchiveDoc = ({ users, availableProducts }) => {
    const dispatch = useDispatch()
    const years = Object.keys(availableProducts)
    const isMobile = window.innerWidth < 450;
    const isMarried = users?.[0].maritalStatus === "married"
    const [data, setData] = useState({});
    const [poa, setPoa] = useState([]);
    const [displayUser, setDisplayUser] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [userFirstNames, setUserFirstNames] = useState([]);
    const [userFirstNamesDois, setUserFirstNamesDois] = useState([]);


    useEffect(() => {
        loadDocs(years[0])
        loadPoa()
        findUserFirstNames().then(names => {
            setUserFirstNames(names);
            //  console.log("userFirstNames inside  .then() :" ,userFirstNames);
        });
        // console.log("users :", users);
        // console.log("docs[0].forUserId :", docs[0].forUserId);

    }, [])
    // console.log("avalibleProducts:", availableProducts)



    async function findUserFirstNamesFromDocs(users) {
        try {
            const docs = await accountService.getPoa(); // Fetch the docs asynchronously
            // console.log("docs :", docs);

            const userFirstNames = [];

            for (const doc of docs) {
                const matchingUser = users.find(user => user.userId === doc.forUserId);
                if (matchingUser) {
                    userFirstNames.push(matchingUser.firstName);
                    doc.docName = matchingUser.firstName;
                }
            }
            // console.log("userFirstNames:", userFirstNames);
            return userFirstNames;
        } catch (error) {
            // console.error("Error fetching docs:", error);
            return [];
        }
    }
    async function download() {
        await accountService.download();
    }
    async function findUserFirstNames() {
        try {
            const userFirstNames = await findUserFirstNamesFromDocs(users);
            return userFirstNames //, console.log("userFirstNames inside findUserFirstNames:", userFirstNames), setUserFirstNames(userFirstNames) , console.log("userFirstNames inside findUserFirstNames again:", userFirstNames);
            // Use the userFirstNames array here
        } catch (error) {
            //  console.error("Error fetching userFirstNames:", error);
            return []
            // } finally{ return userFirstNames//,  console.log("userFirstNames:", userFirstNames);
        }
    }

    useEffect(() => {
        if (userFirstNames && userFirstNames.length > 0) {
            // This logic will run when userFirstNames has a value
            //  console.log("userFirstNames in second useEffect:", userFirstNames);
            setUserFirstNamesDois([userFirstNames])

        }
    }, [userFirstNames]);

    // Call the outer function
    findUserFirstNames();
    // console.log("userFirstNames baya :", userFirstNames); // its undefind


    useEffect(() => {
        eventBus.setZugi({ textKey: "archive" });// need to add archive (varName) Zugi
    }, []);

    const open1301 = (productId, productName) => {
        const { userId, jwt } = authService.getLocalAccount();
        const link = `/signatures?service=1301&ui=${userId}&tn=${jwt}&pi=${productId}&year=${productName}`
        let alink = document.createElement("a");
        alink.href = link;
        alink.target = "_blank";
        alink.click();
    }

    const loadDocs = async (year) => {
        const isPurchased = !!availableProducts[year]?.purchaseDate
        const [docsUser, docsPartner] = await accountService.getDocsByYear(users[0].accountId, year)

        const dataJSON = {
            taxRequest: {
                title: 'בקשות להחזרי מס',
                table: PRO_FIELS_TABLE,
                isPurchased: isPurchased,
                docs: [docsUser.taxRequest, docsUser.taxRequest],
                displayBtnSign: availableProducts[year].proStatus.id === 7,
                signUrl: () => open1301(availableProducts[year].productId, availableProducts[year].productName)
            },
            botax: {
                title: 'מסמכים שמשכנו עבורך מרשות המיסים והמוסד לביטוח לאומי',
                table: PRODUCT_DATA_TABLE,
                displayActions: isPurchased,
                docs: [[...docsUser.tofes106, ...docsUser.BTL], [...docsPartner.tofes106, ...docsPartner.BTL]]
            },
        }
        if (isPurchased) {
            dataJSON.relevantDocs = {
                title: 'מסמכים שהעלית ורלוונטיים להחזר המס שלך',
                table: PRODUCT_DATA_TABLE,
                docs: [docsUser.relevantDocs, docsPartner.relevantDocs]
            }
            dataJSON.irrelevantDocs = {
                title: 'מסמכים שהעלית ואינם רלוונטיים להחזר המס שלך',
                table: PRODUCT_DATA_TABLE,
                docs: [docsUser.irrelevantDocs, docsPartner.irrelevantDocs]
            }
        } else {
            dataJSON.userUpload = {
                title: 'מסמכים שהעלית באופן עצמאי',
                table: PRODUCT_DATA_TABLE,
                docs: [[...docsUser.relevantDocs, ...docsUser.irrelevantDocs], [...docsPartner.relevantDocs, ...docsPartner.irrelevantDocs]]
            }
        }

        setData(dataJSON)
    }

    const loadPoa = async () => {
        try {
            const docs = await accountService.getPoa()
            setPoa(docs)
        } catch (err) {
            // console.log(err);
        }
    }


    // console.log("userFirstNames:", userFirstNames);

    // const findUserFirstName = async( users)=> {
    //     const docs = await accountService.getPoa()
    //     for (const doc of docs) {
    //       const matchingUser = users.find(user => user.userId === doc.forUserId);
    //       if (matchingUser) {
    //         return matchingUser.firstName;
    //       }
    //     }

    //     return null; // No matching user found
    //   }

    //   const userFirstNames = findUserFirstName( users);
    //   console.log("userFirstNames :",userFirstNames); 

    // const findUserFirstName = async( users) => {
    //     const result = [];
    //     const docs = await accountService.getPoa()
    //     docs.forEach(doc => {
    //       const matchingUser = users.find(user => user.userId === doc.forUserId);
    //       if (matchingUser) {
    //         result.push(matchingUser.firstName);
    //       } else {
    //         const otherUser = users.find(user => user.userId !== doc.forUserId);
    //         if (otherUser) {
    //           result.push(otherUser.firstName);
    //         }
    //       }
    //     });

    //     return result;
    //   }
    //   const userFirstNames = findUserFirstName( users);
    //   console.log("userFirstNames :",userFirstNames); 

    const onWatch = async ({ docId, docName, userFirstNames, tableName, isPurchased = true }) => {

        if (!isPurchased) {
            setIsModalOpen(true)
        }
        try {
            const { file, mimeType } = await dispatch(getDocUrl(docId, tableName))
            utilService.openDocNewTab(file, docName, mimeType)
        } catch (error) {
            toast.error('משהו השתבש...', {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored"
            })
        }
    }

    const onDownload = async ({ docId, docName,
        // userFirstNames,
        tableName, isPurchased = true }) => {
        if (!isPurchased) {
            setIsModalOpen(true)
        }
        try {
            const { file, mimeType } = await dispatch(getDocUrl(docId, tableName))
            utilService.donwloadBase64(file, docName, mimeType)
        } catch (error) {
            toast.error('משהו השתבש...', {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored"
            })
        }
    }


    const closeModal = () => setIsModalOpen(false);



    //  console.log("data :",data);
    // console.log("userFirstNames befor xml :", userFirstNames);
    // console.log("userFirstNamesDois befor xml :", userFirstNamesDois);

    return (
        <>
            {isModalOpen && <ConfirmModal onClose={closeModal} cancelable={false} submitable={false}>
                {{
                    title: "",
                    body: <>
                        <DeleteIcon className="x-icon pointer" onClick={closeModal} />
                        <p>כדי לקבל את המסמך יש לרכוש את השנה</p>
                        <NavLink className="underline" to="/dashboard/products">
                            לחץ כאן (טבלה המציגה את השנים לרכישה)
                        </NavLink>
                    </>
                }}
            </ConfirmModal>}
            <div className='archive-doc'>
                <h1 className='green-border-bottom'>ארכיון המסמכים שלי</h1>
                <br />
                {/* <p>
                    <b>כאן משפצים בכיף</b><br/>
                    הצוות שלנו מבצע בימים אלו עבודות תחזוקה בעמוד ארכיון המסמכים באזור האישי.<br/>
                    בקרוב, נשלים את העבודות ונפתח מחדש את הארכיון שלך, לצפייה והעלאת מסמכים.<br/>
                    לשירותך,<br/>
                    צוות Tax:on
                </p> */}
                <p>בעמוד זה ריכזנו את כל המידע שמשכנו עבורך, ואת כל המסמכים שהעלית למערכת. </p>
                <p>
                    המסמכים מחולקים לפי שנים, ובחלוקה ל-4 קטגוריות:
                </p>
                <p>
                    בקשה להחזר מס (אם כבר הוגשה), מסמכים שמשכנו מהרשויות, מסמכים שהעלית ורלוונטיים להחזר המס שלך, ומסמכים
                </p>
                <p>
                    שהעלית אך אינם רלוונטיים להחזר המס.
                </p>
                <p> בנוסף, ניתן לצפות ולהוריד כאן את מסמכי הייצוג שעליהם חתמת.</p>
                <div style={{ margin: "30px auto 0", textAlign: "center" }}>
                    <button className='submit-btn' onClick={download}>להורדת המסמכים שלי</button>
                </div>
                <div className="list green-border-bottom">
                    <DocsTitle
                        titleStr={`מסמכי הייצוג שלך `}
                        // ${users[0].firstName}
                        docs={poa}
                        watch={(docData) => onWatch({ ...docData, tableName: POA_TABLE })}
                        download={(docData) => onDownload({ ...docData, tableName: POA_TABLE })}
                        users={users}
                    // consoleLog={console.log("avalibleProducts:", availableProducts)}
                    // userFirstNames={userFirstNames}
                    />
                </div>
                {/* <div>
                    <p>
                    בארכיון ניתן לצפות בכל המסמכים שלך, לפי שנים, ובחלוקה ל-3 קטגוריות:
                </p>
                <p>
                    בקשות להחזרי מס שכבר הגשנו עבורך, מסמכים שמשכנו עבורך מהרשויות, ומסמכים שהעלית  באופן עצמאי למערכת.
                </p>
                </div> */}
                <div className='select-box '>
                    <select name="year" onChange={({ target }) => loadDocs(target.value)}>
                        {years.map(y => <option key={y} value={y}>שנת {y}</option>)}
                    </select>
                    {isMobile && isMarried && <select name="user" onChange={({ target }) => setDisplayUser(target.value)}>
                        {users.map((u, idx) => <option key={u.userId} value={idx}>{`${u.firstName} ${u.lastName}`}</option>)}
                    </select>}
                </div>
                <div className="list">
                    {!isMobile && <h3 className='green-border-bottom'>{users[0].firstName} {users[0].lastName}</h3>}
                    {Object.keys(data).map(t =>
                        <DocsTitle
                            onOpenSignUrl={data[t].signUrl}
                            displayBtnSign={data[t].displayBtnSign}
                            titleStr={data[t].title}
                            docs={data[t]?.docs?.[displayUser]}
                            displayActions={data[t].isPurchased}
                            key={t}
                            watch={(docData) => onWatch({ ...docData, tableName: data[t].table })}
                            download={(docData) => onDownload({ ...docData, tableName: data[t].table })}
                            users={users} />
                    )}
                </div>
                {isMarried && !isMobile && <div className="list ">
                    <h3 className='green-border-bottom'>{users[1].firstName} {users[1].lastName}</h3>
                    {Object.keys(data).map(t =>
                        <DocsTitle
                            onOpenSignUrl={data[t].signUrl}
                            displayBtnSign={data[t].displayBtnSign}
                            titleStr={data[t].title}
                            docs={data[t]?.docs?.[1]}
                            displayActions={data[t].isPurchased} key={t}
                            watch={(docData) => onWatch({ ...docData, tableName: data[t].table })}
                            download={(docData) => onDownload({ ...docData, tableName: data[t].table })}
                            users={users}
                        />

                    )}
                </div>}
            </div>
        </>

    )
}
