export const userFields = [
  {
    name: "firstName",
    type: "text",
    label: "שם פרטי",
    errorText: "כפי שמופיע בתעודת הזהות שלך",
    boxType: "textBox"
  },
  {
    name: "lastName",
    type: "text",
    label: "שם משפחה",
    errorText: "כפי שמופיע בתעודת הזהות שלך",
    boxType: "textBox"
  },
  {
    name: "nationalId",
    type: "number",
    label: "תעודת זהות",
    errorText: "כפי שמופיע בתעודת הזהות שלך",
    disabled: true,
    boxType: "textBox"
  },
  {
    name: "birthdate",
    // type: "date",
    label: "תאריך לידה",
    errorText: "כפי שמופיע בתעודת הזהות שלך",
    boxType: "dateBox"
  },
  {
    name: "mobile",
    type: "tel",
    label: "מס' טלפון",
    errorText: "מס' הטלפון אינו תקין, נסו שוב",
    boxType: "textBox"
  },
  {
    name: "email",
    type: "text",
    label: "מייל",
    errorText: "אימייל אינו תקין, נסו שוב",
    boxType: "textBox"
  },
  {
    name: "address",
    type: "text",
    label: "כתובת",
    errorText: "",
    boxType: "textBox"
  },
];

export const addressFields = [
  {
    name: "city",
    type: "text",
    label: "עיר",
    errorText: "כפי שמופיע בתעודת הזהות שלך",
    boxType: "textBox"
  },
  {
    name: "street",
    type: "text",
    label: "רחוב",
    errorText: "כפי שמופיע בתעודת הזהות שלך",
    boxType: "textBox"
  },
  {
    name: "houseNo",
    type: "number",
    label: "מספר בית",
    errorText: "כפי שמופיע בתעודת הזהות שלך",
    boxType: "textBox"
  },
]

const banks = [
  {
    value: "",
    label: 'בחר בנק'
  },
  {
    value: "egud",
    label: "בנק אגוד לישראל בע''מ",
  },
  {
    value: "otzar-hahial",
    label: "בנק אוצר החייל בע''מ",
  },
  {
    value: "hapoalim",
    label: "בנק הפועלים בע''מ",
  },
  {
    value: "yahav",
    label: "בנק יהב לעובדי המדינה בע''מ",
  },
  {
    value: "jerusalem",
    label: "בנק ירושלים בע''מ",
  },
  {
    value: "leumi",
    label: "בנק לאומי לישראל בע''מ",
  },
  {
    value: "mischar",
    label: "בנק למסחר בע''מ",
  },
  {
    value: "pituah",
    label: "בנק לפיתוח התעשייה בישראל בע''מ",
  },
  {
    value: "mizrahi-tfahot",
    label: "בנק מזרחי-טפחות בע''מ",
  },
  {
    value: "masad",
    label: "בנק מסד בע''מ",
  },
  {
    value: "mercantil",
    label: "בנק מרכנתיל דיסקונט בע''מ",
  },
  {
    value: "arabi",
    label: "בנק ערבי ישראלי בע''מ",
  },
  {
    value: "agudat-israel",
    label: "בנק פועלי אגודת ישראל בע''מ",
  },
  {
    value: "habeinleumi",
    label: "הבנק הבינלאומי הראשון לישראל בע''מ",
  },
  {
    value: "ubank",
    label: "יובנק בע''מ",
  },
  {
    value: "dicont",
    label: "בנק דיסקונט לישראל בע''מ",
  },
];

export const bankFields = [
  {
    name: "accountName",
    type: "text",
    label: "שם בעל החשבון",
    errorText: "כפי שמופיע בתעודת הזהות שלך",
    boxType: "textBox"
  },
  {
    name: "bankName",
    label: "שם בעל החשבון",
    errorText: "לא בחרת בנק",
    boxType: "selectBox",
    opts: banks
  },
  {
    name: "branchNo",
    type: "tel",
    label: "מספר סניף",
    errorText: "מס' סניף אינו תקין, נסו שוב",
    boxType: "textBox"
  },
  {
    name: "accountNo",
    type: "tel",
    label: "מספר חשבון",
    errorText: "מספר חשבון אינו תקין, נסו שוב",
    boxType: "textBox"
  },

];

export const childrenFields = [
  {
    name: "name",
    type: "text",
    label: "שם הילד",
    errorText: "כפי שמופיע בתעודת זהות שלך",
    boxType: "textBox"
  },
  {
    name: "birthdate",
    type: "number",
    label: "שנת לידה",
    errorText: "נא לציין שנה",
    boxType: "textBox"
  },
];

export const statusList = [
  { value: "single", label: "רווק" },
  { value: "married", label: "נשוי" },
  { value: "divorced", label: "גרוש" },
  { value: "separated", label: "פרוד" },
  { value: "widower", label: "אלמן" },
];