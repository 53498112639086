import { Logo } from "../../assets/svg-img"

function Head({ title, children }) {
  return (
    <div className="head align-center flex">
      <h1 className="title">{title}</h1>
      {children}
      <div className="flex auto-center">
        <a href="https://taxon.co.il" target="_blank" rel="noreferrer">
          <Logo className="logo pointer" />
        </a>
      </div>
    </div>
  );
}

export default Head;
