import React from "react";

export const TextBox = ({
  id,
  label,
  name,
  error,
  icon,
  errorText,
  disabled,
  value,
  maxLength,
  ...rest
}) => {
  const wrapperClass = `input-wrapper ${value ? "correct" : ""} ${error ? "error" : ""} `
  return (
    <div className="text-box">
      <fieldset className={wrapperClass}>
        <legend className="box-title">
          <label htmlFor={name}>{label}</label>
        </legend>
        {icon && icon}
        <input
          maxLength={maxLength}
          id={name}
          value={value}
          name={name}
          disabled={disabled}
          {...rest}
        />
      </fieldset>
      {error ? <p className="error-taxt">{errorText}</p> : <p></p>}
    </div>
  );
};


