import { httpService } from "./http.service";

export const boardingService = {
  SendUserDetails,
  SendFullDetails,
  getAccountDetails,
  getLicenseDetails,
  signUp,
  checkCC,
  checkDupe
};

async function getLicenseDetails(file, token) {
  try {
    const data = await httpService.post("ocr", { file, token }, 1000 * 13)
    const licenseDetails = {
      firstName: data.first_name,
      lastName: data.last_name,
      id: data.id,
      birthdate: data.birthdate,
      license: data.license,
      address: data.address,
    }

    return licenseDetails;
  } catch (err) {
    throw err
  }
};

async function getAccountDetails(params) {
  try {
    const userDetails = await httpService.post("partner", params);
    const accountDetails = {
      userDetails: {
        firstName: userDetails.partner.firstName || '',
        lastName: userDetails.partner.lastName || '',
        email: userDetails.partner.email || '',
        tel: userDetails.partner.mobile || '',
        address: userDetails.user.address || ''
      },
      partnerDetails: {
        firstName: userDetails.user.firstName || '',
        lastName: userDetails.user.lastName || '',
        email: userDetails.user.email || '',
        tel: userDetails.user.mobile || '',
      },
      token: userDetails.jwt
    }
    return accountDetails;
  } catch (error) {
    throw error;
  }
};

async function signUp(userData) {
  var res = null;
  try {
    if (userData.token) {
      res = await httpService.put("partner", userData);
    } else {
      res = await httpService.post("new-user", userData);
    }
    return res
  } catch (err) {
    throw err
  }
};

async function SendUserDetails(userDetails) {
  try {
    const docs = await httpService.post("create-power-docs", { ...userDetails });
    return docs;
    // let e= new Error('test')
    // e.status = 400;
    // throw e
  } catch (error) {
    throw error;
  }
};

async function SendFullDetails(fullUser) {
  const params = {
    referrer: document.referrer,
    searchParams: new URLSearchParams(window.location.search)
  }
  try {
    const user = await httpService.post("new-user", { ...fullUser, params });
    return user;
  } catch (error) {
    throw error;
  }
};

// validate credit card
async function checkCC(ccData) {
  try {
    const isValid = await httpService.post("validate-cc", { ...ccData })
    return isValid.result
  } catch (err) {
    throw err
  }
};

async function checkDupe(userDetails) {
  try {
    const isValid = await httpService.post("dup-check", { ...userDetails })
    return isValid.dupDetected
  } catch (err) {
    throw err
  }

}