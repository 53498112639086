import React, { useEffect, useRef, useState } from "react";
import MaskInput from "react-maskinput/lib";

export const MobileInputMask = ({
    label,
    name,
    error,
    icon,
    errorText,
    value,
    onChange,
    ...rest
}) => {
    const [mask, setMask] = useState('000-000-0000');
    const [maskString, setMaskString] = useState('05_-___-____');
    const [defVal, setDefVal] = useState(undefined)
    const handleFocus = (event) => event.target.select();
    const handleChange = (e) => {
        const val = e.target.value.replaceAll('-', '');
        onChange({
            target: {
                value: val,
                name
            }
        })
    };

    useEffect(() => {
        if (value) {
            setDefVal(value)
        }
    }, [value])

    const wrapperClass = `input-wrapper ${value ? "correct" : ""} ${error ? "error" : ""} `

    return (
        <div className="text-box">
            <fieldset className={wrapperClass}>
                <legend className="box-title">
                    <label htmlFor={name}>{label}</label>
                </legend>
                {icon && icon}
                <MaskInput style={{ direction: "ltr" }}
                    type={'tel'}
                    value={defVal}
                    alwaysShowMask
                    onChange={handleChange}
                    maskString={maskString}
                    mask={mask}
                    size={10}
                    onFocus={handleFocus}
                    {...rest}
                />
            </fieldset>
            {error ? <p className="error-taxt">{errorText}</p> : <p></p>}
        </div>
    );
};