import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart, sendPurchase } from "../../../store/actions/accountActions";
import { ArrowLeft } from "../../../assets/icons";
import { ShoppingCart, DeleteIcon } from "../../../assets/icons";
import { loadCart } from "../../../store/actions/accountActions";
import { CcCart } from "./CcCart"
import { accountService } from "../../../services/account.service";
import { toast } from "react-toastify";
import { AlertModal } from "../../comon/AlertModal";
import { ConfirmModal } from "../../comon/ConfirmModal";
import { authService } from "../../../services/auth.service";
import { useNavigate } from "react-router-dom";
// import { UserDetails } from "../components/userDashboard/PersonalDetails/UserDetails";

export const UserCart = ({ loggedinAccount }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userFirstName = loggedinAccount.users[0].firstName;
  const userEmail = loggedinAccount.users[0].email;
  const { cart } = loggedinAccount;
  const [isOpenPay, setIsOpenPay] = useState(false);
  const [middlePrice, setMiddlePrice] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [discountName, setDiscountName] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [coupon, setCoupon] = useState("");
  const userDetails = useSelector(state => state.signUpModule);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteButtonClicked, setDeleteButtonClicked] = useState(false)


  // TODO take off all logs befor upload to @PRODUCTION

  // Use useCallback to create a memoized version of 'loadQuote' function
  const loadQuote = useCallback(async (coupon) => {
    const productIds = cart.map((prod) => prod.productId);
    let res;
    // console.log("productIds inside loadQuote() :", productIds);
    if (cart.length) {
      res = await accountService.getQuote(productIds, coupon);
    } else {
      res = { sum: 0, total: 0, discount: 0 };
    }
    // console.log("cart inside loadQuote() :", cart);
    setDiscount(res.discount);
    setMiddlePrice(res.sum);
    setTotalPrice(res.total);
    setDiscountName(res.discountName);
    setCoupon(coupon);
    if (res.couponStatus) {
      // setDiscountName("eupui kt")
      toast.error(res.couponStatus, {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
        autoClose: 3000,
      });
    }
  }, [cart]);

  // ... other code ...

  useEffect(() => {
    loadQuote();
    // console.log("cart inside useEffect :", cart);
    checkForNegativeUnpurchesd();
    // CartAndProductsProcessor();
    // processCartAndCheckExpectedReturn();
    setDeleteButtonClicked(false);
    checkProductsInCart(loggedinAccount);
    return () => {
      // console.log("cart inside useEffect :", cart);
    };
  }, [cart, loadQuote]); // Include 'loadQuote' as a dependency


  // console.log("loggedinAccount:", loggedinAccount);
  // const loadQuote = async (coupon) => {
  //   const productIds = cart.map(prod => prod.productId)
  //   let res;
  //   console.log("productIds inside loadQuote() :",productIds);
  //   if (cart.length) {
  //     res = await accountService.getQuote(productIds, coupon)
  //   } else res = { sum: 0, total: 0, discount: 0 }
  //   console.log("cart inside loadQuote() :",cart);
  //   setDiscount(res.discount);
  //   setMiddlePrice(res.sum);
  //   setTotalPrice(res.total)
  //   setDiscountName(res.discountName)
  //   setCoupon(coupon)
  //   if (res.couponStatus) {
  //     // setDiscountName("eupui kt")
  //     toast.error(res.couponStatus, {
  //       position: toast.POSITION.TOP_CENTER,
  //       theme: "colored",
  //       autoClose: 3000,
  //     });
  //   }

  // }

  const islFormat = (num) => {

    return new Intl.NumberFormat('he-IL', { style: 'currency', currency: 'ILS', maximumFractionDigits: 0 }).format(num)
  }
  const remove = (prodId, year) => {
    setDeleteButtonClicked(true)
    // loadQuote()
    // console.log("cart inside remove() :", cart);
    dispatch(removeFromCart([prodId], year));
    // loadQuote()
  };

  const pay = async (ccData) => {
    const productIds = cart.map(prod => prod.productId)
    const years = cart.map(({ productId, productName, serviceType }) => ({ productId, productName, serviceType }))
    const data = {
      ...ccData,
      productIds,
      codeCoupon: coupon
    }

    try {
      const res = await dispatch(sendPurchase(data))
      if (res) {
        setSuccessModalOpen(years)
        return toast.success('חיוב בוצע בהצלחה', {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          autoClose: 3000,
        })
      }
      else {
        return toast.error('לא בוצע חיוב', {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          autoClose: 3000,
        })
      }
    } catch (error) {
      return toast.error('פרטים שגויים', {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
        autoClose: 3000,
      });
    }
  }

  const getCoupon = (ev) => {
    ev.preventDefault();
    var data = new FormData(ev.target);
    let formObject = Object.fromEntries(data.entries());
    ev.target.reset()
    return formObject.codeCoupon
  }

  const removeCoupon = () => {
    setCoupon("")
    loadQuote()
  }
//gfbjklm
  const closeSuccessModal = () => {
    navigate('/dashboard/products')
  }

  // const productsValueWithNegativeReturn ={ ...loggedinAccount.availableProducts } 
  function checkProductsInCart(loggedinAccount) {
    const availableProducts = {...loggedinAccount.availableProducts};// simlefying the object 
    const cart = loggedinAccount.cart;// simlefying the object 
  
    for (const year in availableProducts) { //chacking for every year in the avalibleProducts
      const product = availableProducts[year]; 
      if (product.expectedReturn < -250) { // chacking if ther is a year with expectedReturn < -250
        console.log("manshma");
        const foundInCart = cart.some(cartProduct => cartProduct.productId === product.productId);
        if (!foundInCart) {
          return false ,setIsModalOpen(true);
        }
      }
    }
    return true,setIsModalOpen(false);
  }

  const productsWithNegativeReturn = { ...loggedinAccount.availableProducts };
  if (loggedinAccount.cart) {
    loggedinAccount.cart.forEach((item) => {
      delete productsWithNegativeReturn[item.productName];
    });
  }
  // console.log("productsWithNegativeReturn :", productsWithNegativeReturn)
  Object.keys(productsWithNegativeReturn).forEach((key) => {
    const item = productsWithNegativeReturn[key];
    if (!item.isPurchasable || item.proStatus.status == "טרם נבדק") {
      delete productsWithNegativeReturn[key];
    }
    if (item.expectedReturn < -1) {
      delete productsWithNegativeReturn[key];
    }
    // console.log("item.proStatus :",item.proStatus.status);
  });
  const unpurchasedProductsWithNegativeRetun = Object.keys(productsWithNegativeReturn).length !== 0;
  const checkForNegativeUnpurchesd = () => {
    if (unpurchasedProductsWithNegativeRetun == true 
      ) {
        console.log(" Object.keys(availableProducts) :" ,Object.keys(loggedinAccount.availableProducts));
        console.log(" Object.keys(productsWithNegativeReturn) :", Object.keys(productsWithNegativeReturn));
        console.log("unpurchasedProductsWithNegativeRetun :",unpurchasedProductsWithNegativeRetun);
        // Object.keys(loggedinAccount.availableProducts).forEach((key)=>{
          // const item = availableProducts[key];
        // })
        // console.log("somthingSomthing :",item);
        console.log("...loggedinAccount.availableProducts :",{...loggedinAccount.availableProducts});
        console.log("loggedinAccount.cart :",loggedinAccount.cart);

      // setIsModalOpen(true);
    }
  }

//   function CartAndProductsProcessor({ loggedinAccount }) {
//     const processCartAndCheckExpectedReturn = () => {
//         const updatedProducts = { ...loggedinAccount.availableProducts };

//         loggedinAccount.cart.forEach(cartItem => {
//             const productId = cartItem.productId;
//             if (updatedProducts[productId]) {
//                 updatedProducts[productId].expectedReturn -= cartItem.expectedReturn;
//             }
//             // console.log("  updatedProducts[productId].expectedReturn :",  updatedProducts[productId].expectedReturn);
//         });

//         const hasNegativeExpectedReturn = Object.values(updatedProducts)
//             .some(product => product.expectedReturn < -250);

//         return hasNegativeExpectedReturn;
//     };

//     const hasNegativeExpectedReturn = processCartAndCheckExpectedReturn();

// }

//   function processCartAndCheckExpectedReturn(availableProducts, cart) {
//     // Create a deep copy of availableProducts to avoid modifying the original object
//     const updatedAvailableProducts = JSON.parse(JSON.stringify(availableProducts));

//     // Subtract cart values from availableProducts
//     cart.forEach(cartItem => {
//         const productId = cartItem.productId;
//         if (updatedAvailableProducts[productId]) {
//             updatedAvailableProducts[productId].expectedReturn -= cartItem.expectedReturn;
//         }
//     });

//     // Check for products with expectedReturn less than -250
//     const hasNegativeExpectedReturn = Object.values(updatedAvailableProducts)
//         .some(product => product.expectedReturn < -250);

//     return hasNegativeExpectedReturn;
// }

// לקחת ...loggedinAccount.availableProducts 
// ולהחסיר ממנו את  
//loggedinAccount.cart
// ואז לבדוק אם נשאר משהו שהוא קטן מ -250

  return (
    <>
      {isModalOpen && <ConfirmModal  cancelable={false} submitText={"הבנתי ואני רוצה להמשיך"} submitable={true} onSubmit={() => navigate("/dashboard/cart")} onClose={() => setIsModalOpen(false)}  >
        {{
          body: <>
            <p>
              <b>
                {loggedinAccount.users[0].firstName},
                לתשומת ליבך:
              </b>
            </p>
            <br />
            <p>
              בדיקת הזכאות העלתה כי יתכן ובחלק מהשנים שילמת פחות מס הכנסה ממה שכנראה היה צריך לשלם.
            </p>
            <br />
            <p>
              פירוט השנים מופיע לנוחותך בעמוד "החזרי המס שלי" תחת הטבלה " שנים
              שבהן כנראה שילמת פחות מדי מס".
            </p>
            <br />
            <p>
              חשוב לדעת שרשות המיסים עשויה לדרוש ממך להגיש דוח גם עבור שנים אלו.  זה יכול לקרות גם ללא קשר להגשת בקשה להחזר מס.
            </p>
            <br />
            <p>
              אם זה יקרה, מערכת טקסאון תוכל כמובן להכין ולהגיש עבורך את הדוחות.
            </p>

            

          </>
        }}
      </ConfirmModal>}
      {successModalOpen && <SuccessModal years={successModalOpen} userFirstName={userFirstName} onClose={closeSuccessModal} userEmail={userEmail} />}
      <section className="user-cart main-dashboard-layout">
        <section className="user-cart-warrper">
          <div className="title flex align-center ">
            <ShoppingCart className="icon" />
            <h1 className="f-s-24">סל הקניות שלי</h1>
            <span className="subtitle">({cart.length} מוצרים)</span>
          </div>
          <section className="cart-list">
            <div className="title-list flex bold">
              <span className="year-col">פריט</span>
              {/* <span className="expectedReturn">סה"כ החזר צפוי </span> */}
              <span className="price-col"> סה"כ לתשלום</span>
            </div>
            {cart.map((prod, idx) => (
              <section key={idx} className="preview flex">
                <div className="year-col bold">שנת {prod.productName} &nbsp;  &nbsp;  <span> {prod.expectedReturn >= 0 ? <span>  החזר המס הצפוי :</span> : <span>  החוב המחושב :</span>}</span>
                  <span  > {<span style={{ direction: "rtl", display: "inline-block" }}>{islFormat(prod.expectedReturn)}</span>}     </span>
                </div>
                {/* <div> {prod.expectedReturn}        <span className="shekel-sign">שח</span></div> */}
                <div className="price-col">
                  <span>
                    {prod.productPrice} <span className="shekel-sign">שח</span>
                  </span>
                  <button
                    className="plus-icon-container"
                    onClick={() => remove(prod.productId, prod.productName)}
                  >
                    <DeleteIcon className="x-icon" />
                  </button>
                </div>
              </section>
            ))}
          </section>
        </section>

        <section className="summary">
          <section className="title">
            <h1>סיכום רכישה</h1>
          </section>
          <section className="receipt-container">
            {isOpenPay ? <CcCart price={islFormat(totalPrice)} doPay={pay} />
              : <>
                <div className="flex space-between preview-sum bold">
                  <span>סה"כ ביניים</span>
                  <span>
                    {islFormat(middlePrice)}       </span>
                </div>
                <form className="preview-sum coupon"
                  onSubmit={(ev) => {
                    loadQuote(getCoupon(ev))
                  }}>
                  <input type="text" name="codeCoupon" />
                  <button className="coupon-btn">הוספת קוד קופון</button>
                </form>
                {!!discountName && <div className="flex space-between preview-sum bold">
                  {!!coupon && <button className="flex auto-center"
                    onClick={removeCoupon}
                  ><DeleteIcon title="הסרת קופון" className="x-icon" /></button>}
                  {/* <span>הנחת קופון</span> */}
                  <span className="elipsis">הנחת קופון {discountName}</span>
                  <span>
                    {islFormat(discount)}       </span>
                </div>}

                <div className="sum bold flex space-between">
                  <span>סה"כ לתשלום</span>
                  <span>
                    {islFormat(totalPrice)}          </span>
                </div>
                <button className="pay-btn" onClick={() => setIsOpenPay(true)} disabled={!cart.length}>
                  המשך לתשלום
                  <span className="flex auto-center">
                    <ArrowLeft />
                  </span>
                </button>
              </>}
          </section>
        </section>
      </section>
    </>

  );
};


const SuccessModal = ({ userFirstName, years, onClose, loggedinAccount, userDetails, userEmail }) => {
  // const isDIY = years.some(year => year.serviceType !== "שירות מלא");
  const isMoreThen1Product = years.length > 1;

  // console.log(Object.keys("loggedinAccount of the object :", loggedinAccount));

  const open1301 = (product) => {
    const { userId, jwt } = authService.getLocalAccount();
    const { productId, productName } = product
    const link = `/signatures?service=1301&ui=${userId}&tn=${jwt}&pi=${productId}&year=${productName}`
    let alink = document.createElement("a");
    alink.href = link;
    alink.target = "_blank";
    alink.click();
  }

  if (isMoreThen1Product)
    return (
      <ConfirmModal cancelable={false} submitable={false} userDetails={userDetails}>
        {{
          body: <>
            <DeleteIcon className="x-icon pointer" onClick={onClose} />

            <div>
              <h2>{userFirstName},</h2>
              <p>
                התשלום התקבל בהצלחה,תודה.
              </p>
              <p>
                בדקות הקרובות ישלח אליך לכתובת המייל:
              </p>
              <p>
                {userEmail}&nbsp; דו"ח שנתי לשנים
                {years.map((year) => {
                  return (
                    <span key={year.productId}> {year.productName}  </span>);
                })}
                לחתימה מאובטחת ודיגיטלית.
              </p>
              <p>
                איך חותמים על הדו"ח :
              </p>
              <p>
                1. נכנסים לקישור במייל שנשלח לך.
              </p>
              <p>
                2. לוחצים על כפתור ומקבלים קוד זיהוי במייל נוסף.
              </p>
              <p>
                3. מזינים את הקוד שקיבלת בשדה הנדרש.
              </p>
              <p>
                4. חותמים על הדו"ח ולוחצים על כפתור אישור.
              </p>
              <br />
              <p>
                לתשומתך ליבך, עבור כל שנה שרכשת ישלח מייל נפרד לחתימה על הדוח ועימו יתקבל קוד זיהוי נפרד לכל שנה.
              </p>
              <br />
              <p>
                בברכה,
              </p>
              <p>צוות Tax:on</p>
            </div>
          </>
        }}
      </ConfirmModal>
    )

  return (
    <ConfirmModal cancelable={false} submitable={false} >
      {{
        body: <>
          <DeleteIcon className="x-icon pointer" onClick={onClose} />
          <div>
            <h2>{userFirstName},</h2>
            <p>
              התשלום התקבל בהצלחה,תודה.
            </p>
            <p>
              בדקות הקרובות ישלח אליך לכתובת המייל: &nbsp;{userEmail}&nbsp; דו"ח שנתי לשנת
              {years.map((year) => {
                return (
                  <span key={year.productId}> {year.productName}  </span>);
              })}
            </p>
            לחתימה מאובטחת ודיגיטלית.
            <p>

            </p>
            <p>
              איך חותמים על הדו"ח :
            </p>
            <p>
              1. נכנסים לקישור במייל שנשלח לך.
            </p>
            <p>
              2. לוחצים על כפתור ומקבלים קוד זיהוי במייל נוסף.
            </p>
            <p>
              3. מזינים את הקוד שקיבלת בשדה הנדרש.
            </p>
            <p>
              4. חותמים על הדו"ח ולוחצים על כפתור אישור.
            </p>

            <br />
            <p>
              בברכה,
            </p>
            <p>צוות Tax:on</p>
          </div>
        </>
      }}
    </ConfirmModal>
  )
}

