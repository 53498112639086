import React, { useState, useRef, useEffect } from "react";
import { AddCart, ArrowRotate } from "../../assets/icons";
import { MainZugi } from "../../assets/svg-img/zugi";
import { AddCartBtn } from "./AddCartBtn";
import { TextBox } from "../comon/TextBox";
import { DateInputMask } from "../comon/DateInputMask";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateUserId } from "../../store/actions/accountActions";
export const Dashboard = ({ loggedinAccount }) => {
  const dispatch = useDispatch()
  const arr = [2021, 2020, 2019, 2018, 2017, 2016]
  const [isIdPresented, setIdPresented] = useState("");
  const [sibelingId, setsibelingId] = useState("");
  const [idCreatedAt, setidCreatedAt] = useState("");
  const siblinghandler = (eve) => {
    const onlyNumbers = /^[0-9\b]+$/;
    if (eve.target.value === "" || onlyNumbers.test(eve.target.value)) {
      setsibelingId(eve.target.value);
    }
  };
  const idChanghandler = (eve) => {
    setidCreatedAt(eve.target.value);
  };


  const isprimaryUser = () => {


    if (loggedinAccount.users[0].userId === loggedinAccount.primaryUser) {
      // return <span>{loggedinAccount.users[0].firstName}</span>
      return <span className='bold'>{"שלך :"}</span>
    } else {
      return <span className='bold'> של בן/בת הזוג : {loggedinAccount.users[1].firstName}</span>
    }
  }

  const primaryUser = (userId) => loggedinAccount.users.find(user => +user.userId === +userId)

  const isNeedToUpdate = loggedinAccount.poa.shaam.isPending;

  const InnerModal = () => {
    const isMarried = loggedinAccount.maritalStatus === "married"
    if (isNeedToUpdate) {
      return <div className="inner-modal">
        <div className="modal-body">

          <p className='bold' >
            {loggedinAccount.users[0].firstName},
          </p>
          {isMarried ?
            <p>
              עדיין לא אישרתם את בקשת הייצוג מול רשות המיסים.
              <br />
              בלי אישור הייצוג, לא נוכל להתקדם בתהליך בדיקת הזכאות להחזר מס.
            </p> :
            <p>
              עדיין לא אישרת את בקשת הייצוג מול רשות המיסים.
              <br />
              בלי האישור שלך, לא נוכל להתקדם בתהליך בדיקת הזכאות להחזר מס.
            </p>

          }
          <br />
          <p >
            כדי לאשר את הייצוג ולהתקדם בתהליך לבדיקת זכאות להחזר מס, עליך לבצע את הצעדים הבאים:
          </p>
          <ol className="auto-list-style">
            <li>להיכנס לקישור <a href="https://bit.ly/tax-approve" target="_blank" rel="noopener noreferrer" className="underline">באתר רשות המיסים</a>.
            </li>
            <li> לבחור באפשרות "אישור בקשה לייצוג בתיק להחזר מס בלבד"</li>
            <li> במסך הבא, למלא את מספר הלקוח (מספר ת"ז {isMarried ? `של ${primaryUser(loggedinAccount.primaryUser).firstName}` : 'שלך'}), ואת מספר המייצג: <b>558380523</b> (המספר המזהה של משרד רואי החשבון "חולין קזצקר", המייצג את לקוחותינו).</li>
            <li> לבחור אמצעי אימות: הדרך הקלה והמומלצת היא "קוד זמני לאימות", שיישלח לטלפון הנייד שלך ואותו יש להקליד בשדה הייעודי, או "כרטיס אשראי" - מספר כרטיס אשראי פעיל על {isMarried ? `שם ${primaryUser(loggedinAccount.primaryUser).firstName}` : 'שמך'}, לצורך אימות זהות בלבד.</li>
            <li>לאחר הקלדת הפרטים, עליך ללחוץ על הכפתור "שמירה" ולהמתין לקבלת האישור.</li>
          </ol>
        </div>
        <div className="container-zugi">
          <MainZugi />
        </div>
      </div>
    } else {
      return <div className="inner-modal">
        <div className="modal-title">
          <h2>
            עכשיו תורנו לעבוד בשבילך.
          </h2>
        </div>
        <div className="modal-body">
          <p>
            עם קבלת הנתונים מרשות המיסים והמוסד לביטוח לאומי, נעדכן אותך ונתקדם לביצוע בדיקת הזכאות להחזר מס
          </p>
          <br />
          <p className="bold">
            תודה שבחרת Tax:on!
          </p>
        </div>
        <div className="container-zugi">
          <MainZugi />
        </div>
      </div>
    }
  }
  const submitTextBox = async () => {
    try {
      var formatedDate;
      if (!idCreatedAt && !sibelingId) {
        return toast.error('יש למלא את אחד מהשדות ', {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          autoClose: 3000,
        });
      }
      //formating the date to fit the way the server need to get it
      if (idCreatedAt) {
        var day = new Date(idCreatedAt).getDate();
        day = day.toString().padStart(2, `0`);
        var month = new Date(idCreatedAt).getMonth() + 1;
        month = month.toString().padStart(2, `0`);
        const year = new Date(idCreatedAt).getFullYear();
        formatedDate = `${year}-${month}-${day}`
      }
      await dispatch(updateUserId(formatedDate, sibelingId))
    } catch (err) {
      toast.error('משהו השתבש...  ', {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
        autoClose: 3000,
      });
    }
  }
  return (
    <section className="dashboard-dashboard">
      <div className="screen" >
        {InnerModal()}
      </div>
      <div className="instruction main-dashboard-layout">
        <div className="title align-center ">
          <ArrowRotate className="icon" />
          <h1 className="f-s-24">החזרי המס שלך</h1>
          {/* <span className="subtitle">({activeProdCounter} פיעלים)</span> */}
        </div>
        <br />
        <p className="bold">היי {loggedinAccount.firstName}, </p>
        {/* TODO: get the right text form the designer */}
        <p> כאן תוכל לקבל מידע על סטטוס החזרי המס שלך:</p>
        <p>צפייה, עריכה וביצוע בדיקות לשנים נוספות.</p>
        <p className="bold">
          כדי לבצע בדיקה ליותר משנה אחת, לחצו על השנים הרלוונטיות.
        </p>
      </div>
      <section className=" list-wrapper main-dashboard-layout">
        {arr.map(a =>
          <section className="product-preview preview-layout" key={a}>
            <header
              className="flex align-center preview-header"
            >
              <div className="year-col bold">{a}</div>
              <div className="status-col">
                לא פעיל
              </div>
              <div className="rest-col ellipsis">דוח שנתי</div>
              <div className="btn-wapper action-col">
                <button
                  className={
                    `add-cart-btn flex auto-center `
                  }
                >
                  <span className="bold">הוסף לסל</span>
                  <span>
                    <AddCart />
                  </span>
                </button>
              </div>
            </header>
          </section>
        )}
      </section>
    </section>
  )
};