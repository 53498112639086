import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { useSelector } from 'react-redux';
import { CheckMarkBlue, BikeRider, StepCircle, VictoryCup } from '../../../assets/svg-img';
import { authService } from '../../../services/auth.service';
import { QuestionIcon } from '../../../assets/icons';
import { eventBus } from "../../../services/eventBus.service";

export const RoadProgressBar = ({ statusHistory, cuurentStatusId, productId, productName }) => {
  const bicycle = useSelector((state) => state.accountModule.bicycle)
  const statuses = bicycle.map((s, idx) => {
    // search for the curr status in the history
    const res = statusHistory.find((sh) => s.id === sh.status_id)
    let date = null
    if (res) {
      date = new Date(res.action_date).toLocaleDateString('he', { month: 'short', day: 'numeric' });

      // date = res.action_date.split(" ")[0]
    }
    return { ...s, date }
  })

  

  const open1301 = () => {
    const { userId, jwt } = authService.getLocalAccount();
    const link = `/signatures?service=1301&ui=${userId}&tn=${jwt}&pi=${productId}&year=${productName}`
    let alink = document.createElement("a");
    alink.href = link;
    alink.target = "_blank";
    alink.click();
  }

  const cuurentStatus = statuses.findIndex(s => s.id == cuurentStatusId) + 1;
  // const cuurentStatus = 2;
  const barProg = (100 / (statuses.length - 1)) * (statuses.length - cuurentStatus);
 
//   useEffect(() => {
//     eventBus.setZugi({ textKey: "J5" });
// }, []);


  const openZugi = (zugiKey) => {
    eventBus.setZugi({ textKey: zugiKey });
  };

  


  return (
    <div className="road-progress-bar" >
      <div className='steps' >
        {statuses.map((step, idx) => (<> 
          <div  openZugi={openZugi} className={`bubble-text ${idx < cuurentStatus && 'done'}`} key={step}>
            { <div onClick={() => openZugi(step.tzugi_key)}>
            <QuestionIcon />
            &nbsp;
            &nbsp;
            </div> }
            {(cuurentStatus < 2 && idx === 1) ? <>
              <button className='pointer underline' onClick={open1301} >
                {step.status}
              </button>
            </>
              : <>
                <p>
                  {step.status}
                </p>
              </>}
          </div>
        </>)
        )}
      </div>
      < div
        className="road-bar"
        style={{ "--progress": `${barProg}%` }}
      >
        {statuses.map((step, idx) => (

          <div
            className='step-wrapper'
            key={idx}
            style={{ right: `${(100 / (statuses.length - 1)) * idx - 1}%` }}
          >
            {idx + 1 === cuurentStatus
              ? <Rider date={step.date} />
              : <Setp date={step.date} stepId={cuurentStatus} idx={idx} length={statuses.length - 1} />
            }
          </div>
        ))}
      </div>



    </div >
  )
}

const Setp = ({ stepId, idx, length, date }) => {
  return (
    <div className='step-container'>
      {/* {idx === length ? <VictoryCup className="victory-cup" /> : */}
      <>
        {stepId > idx + 1 ?
          <CheckMarkBlue className="check-mark" />
          : <StepCircle />
        }
      </>
      {/* } */}
      <p className="date" style={{ fontSize: "10px" }}>{date}</p>
    </div>
  )
}

const Rider = ({ text, date }) => {
  return (
    <div className="rider">
      {/* <div className="bubble-text">
        <div>
          {text}
        </div>
        <div className="vector"></div>
      </div> */}
      <BikeRider />
      {/* <p style={{ fontSize: "10px" }}>19 בפבר׳</p> */}
      <p style={{ fontSize: "10px" }}>{date}</p>
    </div>
  );
};
