import React from 'react'

export const SelectBox = ({ opts, name, onChange, value, error, errorText, ...rest }) => {
    const selectClass = `${value ? "correct" : ""} ${error ? "error" : ""} `

    return (
        <div className='select-box'>
            <select
                className={selectClass}
                value={value}
                name={name}
                onChange={onChange}
                {...rest}
            >
                {opts.map(opt => <option value={opt.value} key={opt.value}>
                    {opt.label}
                </option>)}
            </select>
            {error ? <p className="error-taxt">{errorText}</p> : <p></p>}
        </div>
    )
}
