import React from "react";
import { utilService } from "../../../services/util.service";
import { Paperclip } from "../../../assets/icons";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../../store/reducers/siteReducer";
import { toast } from "react-toastify";

export const UploadBox = ({ className, addFile, docUrl, criteriaKey, txt, disabled = false, multiple = true, ...inputAttr }) => {
  const disptch = useDispatch()
  const getFiles = async ({ target }) => {
    disptch(setIsLoading(true))
    try {
      for (const file of target.files) {
        let res;
        if (file.type === 'application/pdf' && 8388608 < file.size) {
          throw new Error('קובץ גדול מדי')
        }
        else if (8388608 < file.size) {
          res = await utilService.resizeFile(file)
        } else {
          res = await utilService.toBase64(file);
        }
        let docName = file.name.split('.')
        docName.pop()
        const data = {
          docName: docName.join(''),
          file: res,
          extension: file.type === "application/pdf" ? 'pdf' : ""
        };
        await addFile(data)
      }
    } catch (err) {
      if (err.cause?.code === "BigPDF") {
        toast.error(err.message, {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
        });
      } else {
        toast.error('משהו השתבש..', {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
        });
      }
    } finally {
      disptch(setIsLoading(false))
    }
  };

  return (
    <label className={className} tabIndex="0">
      <input
        type="file"
        name="file"
        accept=".doc,.docx,.pdf,image/*"
        multiple={multiple}
        onChange={getFiles}
        hidden
        disabled={disabled}
      />
      <span className="ellipsis">
        {!!txt ? txt : "הוספת קובץ"}
      </span>
      <span className="paperclip-icon">
        <Paperclip />
      </span>
    </label>
  );
};
