import { useEffect, useRef, useState } from "react";
import { utilService } from "../services/util.service";

export const useIdleTimer = (timeout = 1000 * 60, onIdle) => {
    let setIdleTimeout;
    const events = [
        'load',
        'mousemove',
        'mousedown',
        'click',
        'scroll',
        'keypress'
    ]
    // let isFirstTime = true
    const [isFirstTime, setIsFirstTime] = useState(true)
    const [isIdle, setIsIdle] = useState(false)
    const setIdle = () => {
        setIsIdle(true)
    }

    const setActive = () => {
        setIsIdle(false)
    }

    const resetTimeout = () => {
        setActive()
        clearTimeout(setIdleTimeout);
        setIdleTimeout = setTimeout(setIdle, timeout);
    };

    useEffect(() => {
        if (isFirstTime) setIsFirstTime(false)
        //TODO: add funtion onIdle onActive
        // else {
        //     console.log("i feel it!!");
        // }
    }, [isIdle])

    useEffect(() => {
        resetTimeout()
        for (let i in events) {
            window.addEventListener(events[i], resetTimeout);
        }
        return () => {
            for (let i in events) {
                window.removeEventListener(events[i], resetTimeout);
            }
        }
    }, [])

    return isIdle
}