import React, { useEffect, useState } from "react";
// import { ReactComponent as RightArrow } from "../../../media/StartIcon.svg";
import { ArrowLeft, ArrowRight } from "../../../assets/icons";
import { NO, NOT_SURE, YES } from "../../../services/account.service";
import { eventBus } from "../../../services/eventBus.service";

export const Quest = ({ quest, movePage, questNo, sendAns, ansVal, years, isViewMode }) => {
  const { question, questionOpt, answers, details, } = quest
  const [ans, setAns] = useState(null)
  const [showNextPage, setShowNextPage] = useState(false)

  useEffect(() => {
    if (ansVal !== undefined) setAns(+ansVal);
    setShowNextPage(ansVal !== undefined);
    eventBus.setZugi({ textKey: `personal_quest_${quest.name}` });

    eventBus.setZugi(
      // have ITAG or not
      isViewMode
        ? { textKey: `personal_quest_${quest.name}` }
        : { textKey: `personal_quest_represent_${quest.name}` }
    );
  }, [questNo]);

  useEffect(() => {
    if (quest.name === 'privateInsurance') {
      privateInsuranceZugi()
    }
  }, [ans])

  const handleChange = async ({ target }) => {
    setAns(+target.value)
    setShowNextPage(true)
  }

  const privateInsuranceZugi = () => {
    if (ans === NO) {
      eventBus.setZugi({ textKey: `personal_quest_privateInsurance_no` })
    }
    else if (ans === YES) {
      eventBus.setZugi(!isViewMode ? { textKey: `personal_quest_represent_privateInsurance` } : { textKey: `personal_quest_privateInsurance` })
    }
  }

  const nextPage = () => {
    sendAns(quest.name, ans);
    setAns(null);
    setShowNextPage(false);
  };



  return (
    <section className="quest">
      <div className="question flex align-center">
        <div className="quest-no">{questNo + 1}</div>
        <div>

          <p className="quest-txt">
            {question}
          </p>
          {!!details && <p className="subtxt">{details}</p>}
        </div>
      </div>
      <div className="answers flex">
        {answers.map((a) => (
          <label
            key={a.value}
            htmlFor={a.name}
            className={ans === a.value ? "selected" : ""}
          >
            <input
              type="radio"
              id={a.name}
              name={quest.name}
              value={a.value}
              onChange={handleChange}
              checked={ans === a.value}
              hidden
            />
            {a.txt}
          </label>
        ))}
      </div>
      <section className="page-btn flex space-between">
        <button onClick={() => movePage(-1)}>
          <ArrowRight className="right-arrow" />
          <span>חזור</span>
        </button>

        {showNextPage && (
          <button className="continue-btn" onClick={() => nextPage()}>
            <span>המשך</span>
            <ArrowLeft />
          </button>
        )}
      </section>
    </section >
  );
};
