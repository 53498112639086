import React from 'react'
import { useState } from 'react'
import Head from '../components/comon/header'
import { TextBox } from '../components/comon/TextBox'
import Joi from "joi";
import { toast } from "react-toastify";
import { Logo } from '../assets/svg-img';
import { WhatsappIcon, PhoneIcon, MailIcon } from '../assets/icons'
import { AppFooter } from '../components/comon/AppFooter'
import { useDispatch } from 'react-redux';
import { sendOtp } from '../store/actions/accountActions';
import { MacForm } from '../components/login/MacForm';
import { useEffect } from 'react';
import { eventBus } from '../services/eventBus.service';
import { ConfirmModal } from '../components/comon/ConfirmModal';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
  const disptch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState({ id: '', email: '', terms: true, newsletter: true })
  // const [agreements, setAgreements] = useState({ terms: false, newsletter: false });
  const [method, setMethod] = useState('')
  const [isMacOpen, setIsMacOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showEmailNotConfirmedDialog, setShowEmailNotConfirmedDialog] = useState(false)


  useEffect(() => {
    eventBus.setZugi(null)
  }, [])

  const handleChange = ({ target }) => {
    const { value, name, checked, type } = target
    const val = type === "checkbox" ? checked : value
    setForm(prevForm => ({ ...prevForm, [name]: val }))
  }

  const schema = {
    email: Joi.string()
      .required()
      .email({ tlds: { allow: false } }),
  }

  const submit = async (method) => {
    const isValidEmail = !schema["email"].validate(form.email).error
    if (!form.terms) {
      return toast.error("יש לאשר את תנאי השימוש , מדיניות הפרטיות וקבלת דיוור מידע פרסומי", {
        position: "top-center",
        theme: "colored",
        autoClose: 3000,
      });
    }
    if (!isValidEmail) {
      return toast.error("אחד מהפרטים שהכנסת לא נכונים", {
        position: "top-center",
        theme: "colored",
        autoClose: 3000,
      });
    }


    setMethod(method)
    form.method = method;
    try {
      const response = await disptch(sendOtp({ ...form, method }))

      if (!response?.email_confirmed) {
        setShowEmailNotConfirmedDialog(true)
        return;
      }

      if (response.target === "app") {
        setIsModalOpen(true)
        // this.props.navigate("https://app.taxon.co.il/userLogin.php");
      } else setIsMacOpen(true);
    } catch (err) {
      setMethod('')
     if (err.response.status === 401 || err.response.status === 400 || err.response.status === 404) {
        toast.error("אחד מהפרטים שהכנסת לא נכונים", {
          position: "top-center",
          theme: "colored",
          autoClose: 3000,
        });
      }  else if (err.response.status === 403){
        toast.error(`אזה כייף שבאת!\nאבל יש לאשר את הדוא"ל שלך לפני הכניסה הראשונה לאזור האישי.\nשלחנו לך שוב את את המייל`, {
          position: "top-center",
          theme: "colored",
          autoClose: 7000,
          style : {
            whiteSpace: 'break-spaces'
          }
        });
      }else {
        toast.error("משהו השתבש אנא נסו שוב מאוחר יותר", {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          autoClose: 2000,
        });
      }
    }
  }

  const navigateToOldLogin = () => {
    navigate("https://app.taxon.co.il/userLogin.php")
  }

  const closeConfirmEmailDialog = () => {
    setShowEmailNotConfirmedDialog(false)
  }

  return (
    <>
      {showEmailNotConfirmedDialog &&
        <ConfirmModal
          cancelText='הבנתי'
          cancelable={true}
          submitable={false}
          autoClose={false}
          onCancel={closeConfirmEmailDialog}

        >
          {{
            body: <>
              <h3 style={{ lineHeight: '25px' }}>
                היי טוב שבאת!
              </h3>
              <p>
                לפני שממשיכים לאזור האישי,
              </p>
              <p>
                עליך רק לאשר את כתובת המייל שלך.
                שלחנו לך הרגע שוב את המייל לאישור.
                <p>
                  לשירותך,
                </p>
                <p>
                  צוות Tax:on
                </p>
              </p>

            </>
          }}
        </ConfirmModal>}

      {isModalOpen && <ConfirmModal cancelText='לא' submitText='לחץ כאן'
        cancelable={false}
        submitable={false}
        onClose={navigateToOldLogin} onSubmit={navigateToOldLogin} >
        {{
          body: <>
            <div >
              <p>מערכת Tax:on עוברת בימים אלו שדרוג, ובקרוב נעלה לאוויר גרסה חדשה</p>
              <br />
              <p>
                עד אז, ניתן להיכנס לאזור האישי במערכת הקיימת על ידי
                <a className='bold'
                  style={{ textDecoration: "underline" }}
                  href='https://app.taxon.co.il/userLogin.php'>
                  לחיצה כאן
                </a>
              </p>
              <br />
              <p>
                <b>לידיעתך: </b>
                מטעמי אבטחה, יש להזין שם שוב את המייל ואת הקוד שנשלח לך.
              </p>
              <br />
              <p>
                מחכים כבר להראות לך את האזור האישי החדש... בקרוב😊
              </p>
              <br />
              <p>
                לשירותך,
              </p>
              <p>
                צוות Tax:on
              </p>
            </div>
          </>
        }}
      </ConfirmModal>}
      <section>
        {/* <Head /> */}
        <header className='login-header'>
          <div>
            {/* <a className='submit-btn'
              href="/">להרשמה</a>ּ */}
          </div>
          <span >
            <Logo />
          </span>
        </header>
        <section className='login page'>
          <aside className="img-container">
            <img src="https://app.taxon.co.il/img2/spaceBoy.png" alt="loginimg" />
          </aside>

          <div className='login-container'>
            <div className="login-title flex align-center ">
              <h1>כניסה למערכת</h1>
              <span className='logo flex align-center'>
                <Logo />
              </span>
            </div>

            <h2 className=' greet bold'>היי, טוב לראות אותך</h2>
            <p className='under-h2'>
              כדי להכנס לאזור האישי עליך למלא מספר תעודת זהות,
              כתובת מייל, ולאשר את תנאי השימוש ומדיניות הפרטיות שלנו.
            </p>

            <div className='form'>
              <TextBox type={"tel"} disabled={isMacOpen} name={'id'} label={"מספר תעודת זהות"} value={form.id} onChange={handleChange} />
              <TextBox type={"text"} disabled={isMacOpen} name={"email"} label={"כתובת מייל"} value={form.email} onChange={handleChange} />
              <label className='terms flex'>
                <input type="checkbox" name='terms' disabled={isMacOpen} checked={form.terms} onChange={handleChange} />
                <span>
                  קראתי ואישרתי את
                  <a href='https://taxon.co.il/terms-of-use/' target="_blank">תנאי  השימוש</a>
                  <a href="https://taxon.co.il/privacy-policy/" target="_blank" >ומדיניות הפרטיות</a>
                  באתר
                </span>
              </label>
              <label className='terms flex'>
                <input type="checkbox" name='newsletter' disabled={isMacOpen} checked={form.newsletter} onChange={handleChange} />
                <span>
                  אשמח לקבל עדכונים שיכולים לחסוך לי כסף
                </span>
              </label>
              <h2 className='send-code'>לאן לשלוח את קוד הכניסה?</h2>
              <div className='mathod-btns'>
                <button
                  className={method === 'sms' ? 'selected' : ''}
                  onClick={() => submit('sms')}>
                  <PhoneIcon />
                  <span>נייד</span>
                </button>

                {/* <button
                className={`whats-up ${method === 'whatsapp' ? 'selected' : ''}`}
                onClick={() => submit('whatsapp')}>
                <span className='flex auto-center'>
                  <WhatsappIcon />
                </span>
                <span>וואטסאפ</span>
              </button> */}
                <button
                  className={method === 'email' ? 'selected' : ''}
                  onClick={() => submit('email')}>
                  <MailIcon />
                  <span>מייל</span>
                </button>
              </div>
            </div>

            {isMacOpen && <MacForm userData={form} />}
          </div>
        </section>
        <AppFooter />
      </section>
    </>
  )
}
