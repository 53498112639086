import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "./Spinner";


export const LoadingToRedirect = ({ loction = 'login', delay = 10 }) => {
    const [count, setCount] = useState(delay);
    let navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setCount((currentCount) => --currentCount);
        }, 1000);
        count === 0 && navigate(`/${loction}`);
        return () => clearInterval(interval);
    }, [count]);

    return (
        <div>
            <Spinner autoLoading={true} />
            {/* <h1>Loading...</h1>
            {count} */}
        </div>
    );
}
