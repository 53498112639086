import { createSlice } from '@reduxjs/toolkit'
import { NO } from '../../services/account.service'
import { RELEVANT, RELEVANT_AND_HAS_DOC, NOT_RELEVANT } from '../../services/account.service'

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    accountId: null,
    jwt: null,
    maritalStatus: null,
    users: [],
    address: {
      street: "",
      city: "",
      houseNo: ""
    },
    bank: {
      bankName: "",
      accountNo: "",
      accountName: "",
      branchNo: ""
    },
    info: {},
    availableProducts: {},
    products: [],
    poa: {}, //Power of attorney
    cart: [],
    confirmedCharacterization: false,
    isActive: true,
    authenticated: null,
  }, 
  reducers: {
    setLoggedinAccount: (state, { payload }) => {
      Object.keys(payload).forEach(key => state[key] = payload[key])
    },
    updateInfoSelection: (state, { payload }) => {
      const { key, ans } = payload
      if (!state.info[key]) state.info[key] = {}
      state.info[key].isSelected = ans
    },
    infoAddDoc: (state, { payload }) => {
      const { key, fileData } = payload
      if (state.info[key].isSelected == 0) state.info[key].isSelected = 1
      if (!state.info[key].data) state.info[key].data = { docs: [] }
      if (state.info[key].metaData && fileData.year && fileData.forUser)
        state.info[key].metaData[fileData.year][fileData.forUser] = RELEVANT_AND_HAS_DOC;

      const docs = state.info[key].data.docs || []

      if (key === "tofes106") {
        state.availableProducts[fileData.year].isPurchasable = true
      }
      if (key === "tofes106" || key === "BTL") {
        const docIdx = docs.findIndex(doc => doc.year == fileData.year && doc.docName == fileData.docName && doc.forUser == fileData.forUser)
        state.info[key].data.docs.splice(docIdx, 1, fileData)
      } else {
        state.info[key].data.docs = [fileData, ...docs]
      }

    },
    infoRemoveDoc: (state, { payload }) => {
      const { key, docId } = payload
      const doc = state.info[key].data.docs.find(doc => doc.docId == docId)
      const idx = state.info[key].data.docs.findIndex(doc => doc.docId == docId)
      const length = state.info[key].data.docs.length

      if (length === 1 && doc.year && doc.forUser) {
        state.info[key].metaData[doc.year][doc.forUser] = RELEVANT;
      }

      if (key === 'tofes106' || key === 'BTL') {
        const docIdx = state.info[key].data.docs.findIndex(doc => doc.docId == docId)
        state.info[key].data.docs[docIdx].docId = null
        state.info[key].data.docs[docIdx].uploadedByAccount = null
      } else {
        state.info[key].data.docs.splice(idx, 1)
      }
    },
    updateCriteria: (state, { payload }) => {
      const { key, isSelected } = payload
      if (key === "characterization_confirmed") state.viewOnly = !!isSelected
      state.info[key] = isSelected === NO ? { isSelected: isSelected } : { ...state.info[key], isSelected: isSelected }
    },
    saveCriteria: (state, { payload }) => {
      const { key, data, children } = payload
      if (!state.info[key].isSelected || state.info[key].isSelected == 0) state.info[key].isSelected = 1
      if (!state.info[key].data) state.info[key].data = {}

      if (key === "children") {
        state.info[key].data.children = children
      }
      if (key === "appartmentRent") {
        state.info[key].data.years = data
      }
    },
    updateProductFieldRelevance: (state, { payload }) => {
      const { key, year, forUserId, isRelevant } = payload
      state.info[key].metaData[year][forUserId] = isRelevant ? RELEVANT : NOT_RELEVANT;
    },
    productIntent: (state, { payload }) => {
      const { year, availableProducts } = payload
      // state.availableProducts[year].client_intent = false
      // state.availableProducts[year].isPurchasable = false
      state.availableProducts[year] = availableProducts
    },
    updateValidCC: (state, { payload }) => {
      state.authenticated = true
    },
    updateCart: (state, { payload }) => {
      const { cart, years } = payload
      if (years){ years.forEach(year => {
        state.availableProducts[year].added_to_cart = !state.availableProducts[year].added_to_cart 
      });
      }
      state.cart = cart
    },

    updatePoa: (state, { payload }) => {
      state.poa.shaam.isPending = payload
    },

    logout: (state, { payload }) => {
      state = {
        accountId: null,
        jwt: null,
        maritalStatus: null,
        users: [],
        address: {
          street: "",
          city: "",
          houseNo: ""
        },
        bank: {
          bankName: "",
          accountNo: "",
          accountName: "",
          branchNo: ""
        },
        info: {},
        products: [],
        poa: {}, //Power of attorney
        cart: [],
        confirmedInfo: false,
        isActive: true
      }
    }
  },
})

export const { setLoggedinAccount, updateInfoSelection, updateInfoData, infoAddDoc, updateCriteria, saveCriteria, infoRemoveDoc,
  logout, updateProductFieldRelevance, productIntent, updateValidCC, updateCart, updatePoa } = accountSlice.actions

export const accountReducer = accountSlice.reducer