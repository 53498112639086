import { authService } from "./auth.service";
import { httpService } from "./http.service";

const BASE_URL = "dashboard/"

//USED IN QUEST.JS
export const NO = 0;
export const YES = 1;
export const NOT_SURE = 2;

// USED IN CRITERIA DETAILS
export const NOT_RELEVANT = 0;
export const RELEVANT = 1;
export const RELEVANT_AND_HAS_DOC = 2;

// USED IN CRITERIA DETAILS
export const POA_TABLE = 'poa';
export const PRO_FIELS_TABLE = 'pro_files';
export const PRODUCT_DATA_TABLE = 'product_data';

export const accountService = {
  updatePresonalQuiz,
  addDocToInfo,
  updateCrit,
  saveCrit,
  getDocUrl,
  removeDocFromInfo,
  updateProductFieldRelevance,
  clientProductIntent,
  sendCC,
  loadCart,
  addToCart,
  removeFromCart,
  getQuote,
  sendPurchase,
  get1245,
  get1301,
  getUnsignedPoa,
  signPoa,
  sign1301,
  getDocsByYear,
  getPoa,
  auditQuote,
  auditPurchase,
  userIdUpdate,
  download,
};

//UPADTES
async function updatePresonalQuiz(updateKey, updateValue) {
  try {
    var { userId, jwt } = authService.getLocalAccount();
    await httpService.patch(BASE_URL + "update-quiz", { userId, jwt, updateKey, updateValue });
  } catch (err) {
    throw err;
  }
}

async function addDocToInfo(key, data) {
  try {
    var { userId, jwt } = authService.getLocalAccount();
    const res = await httpService.post(BASE_URL + "upload-file", { userId, jwt, key, data });
    return res
  } catch (err) {
    throw err;
  }
}

async function removeDocFromInfo(docId) {
  try {
    var { userId, jwt } = authService.getLocalAccount();
    const res = await httpService.post(BASE_URL + "delete-doc", { userId, jwt, docId });
    return true
  } catch (err) {
    throw err;
  }
}

async function updateCrit(updateKey, updateValue, metaData) {
  try {
    var { userId, jwt } = authService.getLocalAccount();
    const res = await httpService.post(BASE_URL + "update-characterization", { userId, jwt, updateKey, updateValue });
    return res
  } catch (err) {
    throw err;
  }
}

async function getDocUrl(docId, reference) {
  try {
    var { userId, jwt } = authService.getLocalAccount();
    const { file, mimeType } = await httpService.post(BASE_URL + "get-doc-link", { userId, jwt, docId, reference });
    return { file, mimeType }
  } catch (err) {
    throw err
  }
}

async function saveCrit(key, data) {
  var { userId, jwt } = authService.getLocalAccount();
  let res;
  try {
    await httpService.post(BASE_URL + "update-characterization", { userId, jwt, updateKey: key, updateValue: YES });
    if (key === "children") {
      res = await httpService.post("dashboard/procreate", { userId, jwt, data });
    } else if (key === "appartmentRent") {
      res = await httpService.post("dashboard/rent", { userId, jwt, data });
    } else {
      throw "not good"
    }
    return res.offsprings

  } catch (err) {
    throw err
  }
}

async function updateProductFieldRelevance(key, year, forUserId, isRelevant) {
  var { userId, jwt } = authService.getLocalAccount();
  try {
    const res = await httpService.post(BASE_URL + "update-product-field-relevance", { userId, jwt, key, year, forUserId, isRelevant });
    return res
  } catch (err) {
    throw err
  }
}

async function clientProductIntent(productId, status = false) {
  var { userId, jwt } = authService.getLocalAccount();
  try {
    const { product } = await httpService.patch(BASE_URL + "client-product-intent", {
      userId, jwt, status, product: productId
    })

    return product;
  } catch (err) {
    throw err
  }
}

async function sendCC(ccData) {
  var { userId, jwt } = authService.getLocalAccount();
  try {
    const isValid = await httpService.post(BASE_URL + "purchase-product", { userId, jwt, ...ccData, validateCc: true })
    return isValid.result
  } catch (err) {
    throw err;
  }
}

async function loadCart() {
  var { userId, jwt } = authService.getLocalAccount();
  try {
    const newCart = await httpService.post(BASE_URL + "get-cart", { userId, jwt })
    return newCart
  } catch (err) {
    throw err
  }
}

async function addToCart(productIds) {
  var { userId, jwt } = authService.getLocalAccount();
  try {
    const newCart = await httpService.post(BASE_URL + "modify-cart", { userId, jwt, productIds })
    return newCart
  } catch (err) {
    throw err
  }
}

async function removeFromCart(productIds) {
  var { userId, jwt } = authService.getLocalAccount();
  try {
    const newCart = await httpService.delete(BASE_URL + "modify-cart", { userId, jwt, productIds })
    return newCart
  } catch (err) {
    throw err
  }
}

async function getQuote(productIds, codeCoupon) {
  var { userId, jwt } = authService.getLocalAccount();
  try {
    const res = await httpService.post(BASE_URL + "cpq", { userId, jwt, productIds, codeCoupon });
    return res.BoM
  } catch (err) {
    throw err
  }
}

async function sendPurchase(ccData) {
  var { userId, jwt } = authService.getLocalAccount();

  try {
    const isValid = await httpService.post(BASE_URL + "purchase-product", { userId, jwt, ...ccData, validateCc: false })
    return isValid.result
  } catch (err) {
    throw err
  }
}

async function get1245(idx) {
  var { userId, jwt } = authService.getLocalAccount();
  try {
    const res = await httpService.post(BASE_URL + "create1245", { userId, jwt })
    return res[idx]
  } catch (err) {
    throw err
  }
}

async function get1301(userId, jwt, productId) {
  try {
    const res = await httpService.post(BASE_URL + "get-1301", { userId, jwt, productId })
    return res
  } catch (err) {

  }
}

async function sign1301({ userId, jwt, signature, fileId }) {
  try {
    const res = await httpService.post(BASE_URL + "sign-1301", { userId, jwt, signature, fileId })
    return res
  } catch (err) {
    throw err;
  }
}

async function getUnsignedPoa(userId, jwt) {
  try {
    const res = await httpService.post("poa-get", { userId, jwt })
    return res
  } catch (err) {

  }
}
async function signPoa({ userId, jwt, signature, fileId }) {
  try {
    const res = await httpService.post("poa-sign", { userId, jwt, signature, fileId })
    return res
  } catch (err) {
    throw err;
  }
}

async function getDocsByYear(accountId, year) {
  var { userId, jwt } = authService.getLocalAccount();
  try {
    const docs = await httpService.post(BASE_URL + "docs-by-year", { userId, jwt, accountId, year })
    return _formatArchiveDocs(docs)
  } catch (err) {
    throw err
  }
}

async function getPoa() {
  var { userId, jwt } = authService.getLocalAccount();
  try {
    const docs = await httpService.post(BASE_URL + "get-poa", { userId, jwt })
    //fromating..
    return docs?.map(doc => {
      doc.docName = doc.docName === "shaam" ? "ייצוג - רשות המיסים" : "ייצוג - ביטוח לאומי"
      return doc
    })
  } catch (err) {
    throw err
  }
}

async function download() {
  var { userId, jwt } = authService.getLocalAccount();
  try {
    const response = await httpService.blob(BASE_URL + "download", { userId, jwt },null,'blob');
    const href = URL.createObjectURL(response);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'my-taxon-files.zip'); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    // const blob = 

    //fromating..
    // return docs?.map(doc => {
    //   doc.docName = doc.docName === "shaam" ? "ייצוג - רשות המיסים" : "ייצוג - ביטוח לאומי"
    //   return doc
    // })
  } catch (err) {
    throw err
  }
}

async function auditQuote() {
  var { userId, jwt } = authService.getLocalAccount();
  try {
    const res = await httpService.post(BASE_URL + "audit-quote", { userId, jwt })
    return res.quote;
  } catch (err) {
    throw err
  }
}

async function auditPurchase(ccData = {}) {
  var { userId, jwt } = authService.getLocalAccount();
  try {
    const res = await httpService.post(BASE_URL + "audit-purchase", { userId, jwt, ...ccData })
    return res.quote;
  } catch (err) {
    throw err
  }
}

async function userIdUpdate(cardIssuedDate, siblingNationalId) {
  var { userId, jwt } = authService.getLocalAccount();
  try {
    const res = await httpService.post(BASE_URL + "shaam-poa-update", { userId, jwt, cardIssuedDate, siblingNationalId })
    return res;
  } catch (err) {
    throw err
  }
};


async function updateUserUprovel(cardIssuedDate, siblingNationalId) {
  var { userId, jwt } = authService.getLocalAccount();
  try {
    const res = await httpService.post(BASE_URL + "shaam-poa-update", { userId, jwt, cardIssuedDate, siblingNationalId })
    return res;
  } catch (err) {
    throw err
  }
};





// private methods
function _formatArchiveDocs(docs) {
  var { userId } = authService.getLocalAccount();
  const formatedDocs = docs.reduce((acc, doc) => {
    // the connected user come first
    let userIdx = 0
    if (doc.forUserId && doc.forUserId !== userId) userIdx = 1
    if (doc.docType === "tofes106") acc[userIdx] = { ...acc[userIdx], tofes106: [...acc[userIdx].tofes106, doc] }
    else if (doc.docType === "BTL") acc[userIdx] = { ...acc[userIdx], BTL: [...acc[userIdx].BTL, doc] }
    else if (doc.docType === "taxRequest") acc[userIdx] = { ...acc[userIdx], taxRequest: [...acc[userIdx].taxRequest, doc] }
    else if (doc.isUsed) acc[userIdx] = { ...acc[userIdx], relevantDocs: [...acc[userIdx].relevantDocs, doc] }
    else acc[userIdx] = { ...acc[userIdx], irrelevantDocs: [...acc[userIdx].irrelevantDocs, doc] }
    return acc
  }, [
    { tofes106: [], BTL: [], relevantDocs: [], irrelevantDocs: [], taxRequest: [] },
    { tofes106: [], BTL: [], relevantDocs: [], irrelevantDocs: [], taxRequest: [] }
  ])

  return formatedDocs;
}


